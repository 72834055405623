import React,{useEffect} from "react"

import { useDispatch } from "react-redux";
import "../videopush.scss";
import { InputText } from "@empreinte/components.input-text";
import {
    FormControl,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../Redux/hooks";
// import dayjs, { Dayjs } from 'dayjs';
import { setVisible,setPreviewLink } from '../store/videopushActions';
import { Hooks } from "../utils/hooks";
import { useParams } from "react-router-dom";
const VideopushData = () => {
    const { t } = useTranslation();
    const videoData = useAppSelector((state) => state.videopushReducer.InfoVideo);
    const videoLink = useAppSelector((state)=>state.videopushReducer.previewLink)
    const {videoPushData,previewVideo} = Hooks();
    const dispatch = useDispatch()


useEffect(()=>{
if(videoData && videoData?.video[0]?.id){
    const id = videoData?.video[0]?.id
   
    previewVideo(id)
}else{
    return;
}
},[videoData])

    return (
 <div className="card_container">
            <div className="container">
             
                <iframe id="iframe-player"
                title={videoLink}
                width="600"
                height="340"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
                src={`https://${videoLink}`}></iframe>
                
 
{/*  
 { Object.values(videoData).map((data:any,index:any)=>{ */}
       {/* return( */}
         <div className='card' >
                <InputText
                    fullWidth={true}
                    value={videoData?.video[0]?.id}
                    labelInput={'ID'}
                        inputProps={{
                            readOnly: true,
                        }}
                            />
            
                <InputText
                    fullWidth={true}
                    value={videoData?.video[0]?.title}
                    labelInput={t('configuration.Videopush.title')}
                        inputProps={{
                            readOnly: true,
                        }}
                        />
            
                <InputText
                    fullWidth={true}
                    value={ new Date(videoData?.video[0]?.startDate).toLocaleDateString()}
                    labelInput={t('configuration.Videopush.startDate')}
                        inputProps={{
                            readOnly: true,
                        }}
                        />
            
                <InputText
                    fullWidth={true}
                    value={new Date(videoData?.video[0]?.endDate).toLocaleDateString()}
                    labelInput={t('configuration.Videopush.endDate')}
                        inputProps={{
                            readOnly: true,
                        }}
                        />
        </div>
       {/* ) */}
      {/* })}  */}
                 
            </div>
</div>

    )

}
export default VideopushData