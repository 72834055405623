import React, { useEffect, useState, useCallback, useRef } from "react";
import UseActionMenu from "./actionsUserTable";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../Redux/hooks";
import { DataActuality } from "../../utils/Data";
import { Hooks } from "../../utils/hooks";
import { DataGrid } from "@mui/x-data-grid";

const type = "DraggableBodyRow";
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;

function useDataTableMedia({ columns, dataSource, updateEntityPath }) {
  const { dataContent } = DataActuality();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [datasource, setDataSource] = useState(dataContent);
  const { valuesSearch, handelSearch } = Hooks();

  const onSelectChange = (selectedRowKeys, record) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const updatedColumns = [
    ...columns,
    {
      headerName: <label className="titlecolumn">Actions</label>,
      key: "action",
      renderCell: (record, action) => {
        return <UseActionMenu record={record} />;
      },
      width: 200,
      sortable: false,
    },
  ];
  const resetPagination = () => {
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  useEffect(() => {
    const data = handelSearch(valuesSearch.search, dataContent);
    setDataSource(data);
  }, [valuesSearch.search]);
  const DataTable = () => (
    <div
      style={{
        height: 700,
        width: "98%",
        marginTop: "1%",
        border: "none",
        marginLeft: "1%",
      }}
    >
      <DataGrid
        rows={datasource && datasource}
        columns={updatedColumns}
        checkboxSelection
      />
    </div>
  );

  return {
    DataTable,
    selectedRow,
    selectedRowKeys,
    currentPage,
    pageSize,
    resetPagination,
  };
}

export default useDataTableMedia;
