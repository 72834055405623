import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { DataConfiguration } from '../utils/Data';
import { useAppSelector } from "../../../../Redux/hooks";
import Hooks from "../utils/hooks";
import { useDispatch } from "react-redux";
import {InputLabel , Select , MenuItem , Card} from '@mui/material';
import {
    FormControl,
  } from "@material-ui/core";
import { SelectList } from '@empreinte/components.select-list';
import {SelectRadio} from '@empreinte/components.select-radio'
import '../configuration.scss'
import { sassFalse } from 'sass';

import { setEditConfigChannelSelectedConfigurationActions,setIdChannelSelectedConfigurationActions} from "../store/configurationActions"

const ConfigurationData = () => {
   

    const { t } = useTranslation();
    const { ListTypeadd, Optionscheckbox , ListAll } = DataConfiguration()
    const listChoices = useAppSelector((state) => state.ConfigurationReducer.ListChannelsChoices)
    const defaultPageOption = useAppSelector((state) => state.ConfigurationReducer.ConfigChannelSelected)



    const listvideos = useAppSelector((state) => state.ConfigurationReducer.Listvideos)

    const dispatch = useDispatch()
    const { getInfoConfigChannel, getInfoListVideosChannel, handleChangenbVid ,handleChange} = Hooks()
    const [selected, setSelected] = useState(defaultPageOption.pageOption.toString())
    const [data, setData] = useState('1');
    const [selectedVideo, setSelectedVideo] = useState(defaultPageOption.video);
    const [selectedPage, setSelectedPage] = useState(defaultPageOption.nbVid)


    useEffect(() => {
        setSelected(defaultPageOption.pageOption.toString())
        setSelectedPage(defaultPageOption.nbVid)
        setSelectedVideo(defaultPageOption.video)
    }, [listChoices,Optionscheckbox,listvideos, defaultPageOption,selected])
  

   
    const datalist = listChoices.map((item: any, key: any) => {
        return { value: item.value, label: item.id};
      });

    const pageList = ListAll.map((item:any)=>{
    return { value: item, label: item}
    })
    
   const videoList = listvideos.map((item:any)=>{
    return {label:item.title,value:item.id}
   }) 


    function radioChange(e: any) {
        setSelected(e.currentTarget.value)
        dispatch(setEditConfigChannelSelectedConfigurationActions({
            ListvideosChannelNameChange: "pageOption",
            ListvideosChannelValueChange: parseInt(e.currentTarget.value)
        }))
        
        
    }
    function videoChange(e: any) {
        setSelected(e.target.value)
        dispatch(setEditConfigChannelSelectedConfigurationActions({
            ListvideosChannelNameChange: "video",
            ListvideosChannelValueChange: e.target.value
        }))
        
    }

    return (
        <div className={"divCard"}>
        <Card >
        <div className={"formItem1"}>
            <div className={"InputLablDiv"}>
            <FormControl className={"formItem3"}  >
                <SelectList
                    labelText= {t('configuration.Configurationhomepage.canal')}
                    data={datalist}
                    handleChange={handleChange}
                    required={true}
                    ></SelectList>

          </FormControl>
               
            </div>
            <div >


                 <FormControl  className={"formItem3"} required={true}>
                  <div style={{ textAlign: "left" ,  marginLeft : "1%"}} >
                        <SelectRadio
                        datalist={Optionscheckbox}
                        value={selected}
                        onChange={radioChange}></SelectRadio>
                        </div>
                </FormControl>

            </div>

            <div className={"InputLablDiv"}>
            
            <FormControl  className={"formItem3"} >

                   <SelectList
                   data={pageList}
                //    value={defaultPageOption.nbVid === 0 ? ['Toutes'] : [defaultPageOption.nbVid]}
                   id='demo-simple-select-label'
                   defaultValue='Toutes'
                   value={selectedPage === 0 ? 'Toutes' : selectedPage}
                   handleChange={handleChangenbVid}
                   name={'langue'}
                   ></SelectList>
                </FormControl>
            </div>


            <div className={"InputLablDiv"}>

                {listvideos.length !== 0 ?

            <FormControl  className={"formItem3"} required={true}>

                        <SelectList
                    labelText= {t('configuration.Configurationhomepage.videos')}
                    // value={selectedVideo === null ? undefined : [selectedVideo]}
                value={selectedVideo}
                    data={videoList}
                    handleChange={videoChange}
                    required={true}
                    ></SelectList>
            </FormControl>
                    
                    : ""}

            </div>

        </div>
        </Card>
        </div>
    )
}

export default ConfigurationData
