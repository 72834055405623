
export function setPaginationPropsConfiguration(e:any){
    return{
        type:"SET_PaginationPropsConfiguration",
        payload:e,
    }
}


export function setFilterConfigurationActions(e:any){

    return{
        type:"SET_FilterConfiguration",
        payload:e,
    }

}

export function setListConfigurationActions(e:any){

    return{
        type:"SET_ListConfiguration",
        payload:e,
    }

}

export function setConfigChannelSelectedConfigurationActions(e:any){

    return{
        type:"SET_ConfigChannelSelected",
        payload:e,
    }

}

export function setErrorrMessageConfigurationActions(e:any){

    return{
        type:"SET_ErrorrMessage",
        payload:e,
    }

}

export function setListvideosChannelSelectedConfigurationActions(e:any){

    return{
        type:"SET_ListvideosChannelSelected",
        payload:e,
    }

}

export function setEditConfigChannelSelectedConfigurationActions(e:any){

    return{
        type:"SET_EditConfigChannelSelected",
        payload:e,
    }

}

export function setIdChannelSelectedConfigurationActions(e:any){

    return{
        type:"SET_IdChannelSelected",
        payload:e,
    }

}
export function setLoading(e:any){
    return{
        type:"SET_LOADING",
        payload:e,
    }
}
export function setDisabledButton(e:any){
    return{
        type:"SET_DISABLED",
        payload:e
    }
}







