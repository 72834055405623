import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import "../../client.scss";
import { useState } from "react";
import { useAppSelector } from "../../../../Redux/hooks";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  HighlightOffOutlined,
} from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Box from "@mui/material/Box";
import { Button, Tooltip, Modal } from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@mui/material/TextField";
import {GraphQLFetchData} from "../../utils/GraphFetchData";
import {ModalInputs} from "@empreinte/components.modal-inputs"
import {ModalConfirm} from "@empreinte/components.modal-confirm";
import { setDeleteLoader, setmultiDeletemodal, setRowId } from "../../store/clientActions";
import "../../client.scss"
import { Helpers } from "../../../../utils/helpers";


function useActionMenu({ record }: any) {
  
  const dispatch = useDispatch()
  const {acces_send}= Helpers()
  const [isVisible,setVisible ] = useState(false);
  const [multiDeleteIsVisible,setmultiDeleteIsVisible] = useState(false);
  const [detailsisVisible,setDetailsIsVisible] = useState(false)
  const {DeleteClient,multiDelete} = GraphQLFetchData()
  const { t } = useTranslation();
  const {
    handleShowModal,
    visible,
    handleCancel,
    Deleteselecteditem,
    getOneClientToEdit,
    handleEditClient
  } = Hooks();
  const datarecord = useAppSelector(
    (state) => state.ClientReducer.detailClient
  );
  const { param_module } = useAppSelector(
    (state) => state.ReducerUserData.modules
  );
  const isSuperAdmin = useAppSelector((state: any) => state.ReducerUserData.isSuperAdmin)

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    overflow: "scroll",
    height: 600,
    pl: 3,
    pr: 1,
    py: 1,
  };

  const converTime = (time: any) => {
    return moment(time).format("yyyy-mm-DD HH:mm:ss");
  };
  const details = t("details");
  const edit = t("edit");
  const delet = t("delete");
  const dtls = t("configuration.Client.Détail des paramétres")
  return (
    <div>
      <ModalInputs 
     
      btnstyle={{paddingTop: "0.5em"}}
          headerStyle={{color:"white"}}
          open={detailsisVisible}
          onClose={() => setDetailsIsVisible(false)}
          title={`${dtls} ${record?.row?.Cle}`}
          onCancel={() => setDetailsIsVisible(false)}
          CancelBtnName={t('close')}
            children={
              <div className="container_details" >
                <div className="keyValuePair" >
                  <span className="detailskey" >{t("configuration.Client.Identifiant")} </span>
                  <span className="detailsvalue" >{record?.row?.Id}</span>
                </div>
                <div className="keyValuePair" >
                  <span className="detailskey" >{t("configuration.Client.key")} </span>
                  <span className="detailsvalue" >{record?.row?.Cle}</span>
                </div>
                <div className="keyValuePair" >
                  <span className="detailskey" >{t("configuration.Client.Valeur")} </span>
                  <span className="detailsvalue" >{record?.row?.Value}</span>
                </div>
                <div className="keyValuePair" >
                  <span className="detailskey" >{t("configuration.Client.Date d'ajout")} </span>
                  <span className="detailsvalue" >{converTime(record?.row?.CreatedAt)}</span>
                </div>
                <div className="keyValuePair" >
                  <span className="detailskey" >{t("configuration.Client.Date d'édition")} </span>
                  <span className="detailsvalue" >{converTime(record?.row?.UpdatedAt)}</span>
                </div>

              <span className="devid" ></span>


             </div>
            }
          />

    <ModalConfirm
    open={isVisible}
    onClose={() => {setVisible(false)}}
    title={t("configuration.Client.Do you want to delete the parameter ?")}
    content={`${record.row.Cle} ${t("configuration.Client.will be deleted")}. `}
    fullWidth={true}
    CancelBtnName={t('No')}
    ConfirmBtnName={t("Yes")}
    onCancel={() => {setVisible(false)}}
    onConfirm={() => {DeleteClient({variables:{id:record.id}}); dispatch(setDeleteLoader(true));acces_send(`${param_module?.param_delete_param_action?.name}_action`)}}
  />
{/* multi delete modal :  */}
      <div className="action">
        {(param_module?.param_post_edit_action?.authorized || isSuperAdmin) && (
          <div has-permission="param_post_edit">
            <Tooltip placement="top" title={edit}>
              <EditIcon
              sx={{
                color:'rgba(0, 0, 0, 0.54)'
              }}
                className={"icon_action"}
                onClick={() => { dispatch(setRowId(record.id)); handleEditClient(record,param_module?.param_post_edit_action?.name)}}
              />
            </Tooltip>
          </div>
        )}
        {(param_module?.param_get_detail_action?.authorized || isSuperAdmin) && (
          <div has-permission="param_get_detail">
            <Tooltip placement="top" title={details}>
              <RemoveRedEyeIcon
                sx={{
                  color:'rgba(0, 0, 0, 0.54)'
                }}
                className={"icon_action"}
                onClick={() => {
                  setDetailsIsVisible(true);
                  acces_send(`${param_module?.param_get_detail_action?.name}_action`)

                }}
              />
            </Tooltip>
          </div>
        )}
        {(param_module?.param_delete_param_action?.authorized || isSuperAdmin) && (
          <div has-permission="param_delete_param">
            <Tooltip placement="top" title={delet}>
              <DeleteIcon
                sx={{
                  color:'rgba(0, 0, 0, 0.54)'
                }}
                className={"icon_action"}
                onClick={()=>setVisible(true)}
              />
            </Tooltip>
          </div>
        )}

        <Grid item md={12} lg={12} xs={12}>
          <Modal open={visible} onClose={handleCancel}>
            <Box sx={style}>
              <Typography component="h3" className="title">
                {`${t("modaledittitle")} "${datarecord.Cle}"`}
                <IconButton className="close" onClick={handleCancel}>
                  <HighlightOffOutlined />
                </IconButton>
              </Typography>
              <Typography className="inputmodal">
                <TextField
                  multiline
                  label={t("identifiant")}
                  value={datarecord.Id}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Typography>
              <Typography className="inputmodal">
                <TextField
                  multiline
                  label={t("configuration.Client.key")}
                  value={datarecord.Cle}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Typography>
              <Typography className="inputmodal">
                <TextField
                  multiline
                  label={t("value")}
                  value={datarecord.Value}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Typography>
              <Typography className="inputmodal">
                <TextField
                  multiline
                  label={t("createddate")}
                  value={converTime(datarecord.CreatedAt)}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}  
                />
              </Typography>
              <Typography className="inputmodal">
                <TextField
                  multiline
                  label={t("configuration.Client.publishdate")}
                  value={converTime(datarecord.UpdatedAt)}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                />
              </Typography>
              <Typography className="closetypo">
                <Button
                  key="back"
                  className="buttonclose"
                  style={{
                    color: "#1976D2",
                    padding: "5px",
                    borderRadius: "3px",
                  }}
                  onClick={handleCancel}
                >
                  {t("close")}
                </Button>
              </Typography>
            </Box>
          </Modal>
        </Grid>
      </div>
    </div>
  );
}

export default useActionMenu;
