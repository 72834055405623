export function setVisible(e:any) {
    return {type: "SET_VISIBLE", payload: e};
}
export function setPreviewLink(e:any) {
    return {type: "SET_PreviewLink", payload: e};
}
export function getData(e:any){
    return{ type:"GET_DATA" , payload: e}
}
export function setDisabledVideo(e:any){
    return {type: "SET_Disabled", payload: e}
}
export function setErrorMessage(e:any){
    return {type: "Set_Error",payload: e}
}
export function setLoading(e:any){
    return {type: "SET_Loading", payload:e}
}
export function setVideoPush(e:any){
    return {type: "SET_VideoPush", payload:e}
}