import { ServeurList } from "./serveurConstants";
const { showDivsConditionsServeur, AddServeurConstraintsData, paginationPropsServeur, FilterServeur, DataServeur, DetailServeur} = ServeurList()

const ServeurListINITIALSTATE = {
    showDivsConditionsServeur: showDivsConditionsServeur(),
    paginationPropsServeur: paginationPropsServeur(),
    addServeurConstraintsData: AddServeurConstraintsData(),
    filterServeur: FilterServeur(),
    dataServeur: DataServeur(),
    detailServeur: DetailServeur(),
    SearchResult: [],
    Types: [],
    loading: true,
}

export const ServeurReducer = (state = ServeurListINITIALSTATE, action: any) => {

    switch (action.type) {

        case "SET_FilterServeur":
            const { FilterServeurNameChange, FilterServeurValueChange } = action.payload
            return {
                ...state,
                loading: false,
                filterServeur: { ...state.filterServeur, [FilterServeurNameChange]: FilterServeurValueChange }
            }
        case "SET_PaginationPropsServeur":
            const { PaginationPropsNameChange, PaginationPropsValueChange } = action.payload
            return {
                ...state,
                loading: false,
                paginationPropsServeur: { ...state.paginationPropsServeur, [PaginationPropsNameChange]: PaginationPropsValueChange }
            }
        case "SET_showDivsConditionsServeur":
            const { showDivsConditionsName, showDivsConditionsValue } = action.payload
            const showDivsConditionsObj = { ...state.showDivsConditionsServeur, [showDivsConditionsName]: showDivsConditionsValue }
            return {
                ...state,
                loading: false,
                showDivsConditionsServeur: showDivsConditionsObj
            }
        case "SET_AddServeurActions":
            const { addServeurConstraintsDataNameChange, addServeurConstraintsDataValueChange } = action.payload
            return {
                ...state,
                loading: false,
                addServeurConstraintsData: { ...state.addServeurConstraintsData, [addServeurConstraintsDataNameChange]: addServeurConstraintsDataValueChange }
            }
        case "SET_DataServeurActions":
            return {
                ...state,
                loading: false,
                dataServeur: action.payload
            }
        case "SET_DetailServeurActions":
            return {
                ...state,
                loading: false,
                detailServeur: action.payload
            }
        
        case "SET_SEARCH_RESULT":
            return {
                ...state,
                SearchResult: action.payload
            }
        case "SET_LOADING_DATA":
            return {
                ...state,
                loading: action.payload
            }

        case "GET_TYPES": {
            return {
                ...state,
                Types: action.payload
            }
        }
        default: {
            return state
        }

    }

}

