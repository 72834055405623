import React, { useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import "moment/locale/zh-cn";
import {
  setPaginationPropsPreset,
} from "../../store/presetActions";
import { useAppSelector } from "../../../../Redux/hooks";
import UseActionMenu from "./actionsUserTable";
import Hooks from "../../utils/hooks";
import { useTranslation } from "react-i18next";
import { GridNorows } from '@empreinte/components.grid-norows';
import { Datagrid } from '@empreinte/components.datagrid';
import { useMediaQuery } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

moment.locale("en");
const useDataTableMedia = ({ columns}: any)=> {

  const { handelFilter, handelSearch ,useWindowSize} = Hooks();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [selectedRow] = useState<any>(null);
  const { t } = useTranslation();
  const windowSize = useWindowSize()
  const hideColumn = (e:number) => {
     return  windowSize < e
  };


  const total = useAppSelector(
    (state) => state.PresetReducer.paginationPropsPreset.totalCount
  );
  const List = useAppSelector((state) => state.PresetReducer.List);
  const values = useAppSelector((state) => state.PresetReducer.filterPreset);
  const showPhoneColumns = useMediaQuery('(min-width:757px)');
  const dispatch = useDispatch();

  const TableColumns = [
    {
      headerName: t("configuration.Preset.State"),
      flex:1,
      field: "State",
      renderCell: (record: any) => {
        return (
          <>
            {record.row.State === true ? (
              <CheckCircleIcon className={"checkedicon img_state"} />
            ) : (
              <HighlightOffIcon className={"closeicon img_state"} />
            )}
          </>
        );
      },
    },
    {
      headerName: t("configuration.Preset.Name"),
      flex:1,
      field: "Name",
      align: "center" ,
      sorter: (a: any, b: any) => {
        let fa = a.name.toLowerCase();
        let fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      width: 300,
    },
    {
      headerName: t("configuration.Preset.BitrateVideo"),
      flex:1,
      field: "BitrateVideo",
      align: "center" ,
      sorter: (a: any, b: any) => {
        let fa = parseInt(a.bitrateVideo, 10);
        let fb = parseInt(b.bitrateVideo, 10);

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      width: 300,
      hide:hideColumn(900)

    },
    {
      headerName: t("configuration.Preset.BitrateAudio"),
      flex:1,
      field: "BitrateAudio",
      align: "center" ,
      sorter: (a: any, b: any) => {
        let fa = parseInt(a.bitrateAudio, 10);
        let fb = parseInt(b.bitrateAudio, 10);

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      width: 300,
      hide:hideColumn(1000)

    },
    {
      headerName: t("configuration.Preset.SamplerateAudio"),
      flex:1,
      field: "SamplerateAudio",
      align: "center" ,
      sorter: (a: any, b: any) => {
        let fa = parseInt(a.samplerateAudio, 10);
        let fb = parseInt(b.samplerateAudio, 10);

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      width: 300,
      hide:hideColumn(1150)
    },
    {
      headerName: t("configuration.Preset.Framerate"),
      flex:1,
      field: "Framerate",
      align: "center" ,
      sorter: (a: any, b: any) => {
        let fa = parseInt(a.framerate, 10);
        let fb = parseInt(b.framerate, 10);

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      },
      sortDirections: ["descend", "ascend", "descend"],
      width: 200,
      hide:hideColumn(550)

    },
  {
    headerName: t('Serveur.actions'),
    flex:1,
    field: 'action',
    editable: false,
    sortable: false,
    width:showPhoneColumns ? 310 : 100,
    renderCell: (params: any) => {
        return (
            <UseActionMenu record={params.row} />
        )
    }
}

];
  const dataWithoutNodeKey = List.map((item:any) => item.node);

  const filteredeventsfilter = handelFilter(values.type, dataWithoutNodeKey);
  const filteredevents = handelSearch(values.search, filteredeventsfilter);

  const DataTable = ({setCurrentPage,currentPage,setPageSize,pageSize}: any) => (
    <div className="DataTable-preset">
      {List.length === 0 ? <GridNorows content={t("Aucun enregistrement correspondant trouvé")} /> :<Datagrid 
                  rows={filteredevents}
                  columns={TableColumns as any}
                  totalEntities={total}
                  selectionModel={selectedRowKeys}
                  checkboxSelect={(id:any)=>{setSelectedRowKeys(id);
                    dispatch(
                      setPaginationPropsPreset({
                        PaginationPropsNameChange: "id",
                        PaginationPropsValueChange: id
                      })
                    );
                }}
                  EntriesName={`${t("Serveur.entries")}`}
                  OfPageName={`${t("Serveur.of")}`}
                  ShowName={`${t("Serveur.show")}`}
                  height={"calc(100vh - 185px)"}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
              />}
    </div>
  );

  return {
    DataTable,
    selectedRow,
    selectedRowKeys,
 
  };

}
export default useDataTableMedia;
