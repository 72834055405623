import { SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../../../Redux/hooks";
import {
  setPaginationPropsClient,
  setshowDivsConditionsClient,
  setTrashContainer,
  setIdSet,
  setmultiDeletemodal,
  setDeleteLoader
} from "../../store/clientActions";
import UseActionMenu from "./actionUserTable";
import frFR from "antd/lib/locale/fr_FR";
import { Box, withStyles } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { DataGrid } from "@mui/x-data-grid";
import {Datagrid} from "@empreinte/components.datagrid"
import "../../client.scss"
import {useWindowSize} from '../../utils/resize'

import moment from "moment";
import "moment/locale/zh-cn";
import Hooks from "../../utils/hooks";
import { GraphQLFetchData } from "../../utils/GraphFetchData";
import {ModalConfirm} from "@empreinte/components.modal-confirm";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { GridNorows } from '@empreinte/components.grid-norows';
import { stat } from "fs";

moment.locale("en");

const DEFAULT_PAGE_NUMBER = 0;
const StyledDataGrid = withStyles({
  root: {
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell": {
      lineHeight: "unset !important",
      maxHeight: "none !important",
      whiteSpace: "normal",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "none !important",
    },
    "& .MuiDataGrid-cell MuiDataGrid-cell--textLeft": {
      maxHeight: "none !important",
    },
  },
})(DataGrid);

function useDataTableMedia({ columns, dataSource }: any) {
  const windowSize = useWindowSize()
  const hideColumn = () => {
      return  windowSize < 768
   };
  const { t } = useTranslation();
  const trashContainer = useAppSelector((state)=>state.ClientReducer.showTrash)
  const { handelSearch } = Hooks();
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [selectedRow] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [pageSize,setPageSize] = useState<any>(10);
  const [locale] = useState<any>(frFR);
  const [isMobile,setIsmobile] = useState(false);
  const valuePagination = useAppSelector(
    (state) => state.ClientReducer.paginationPropsClient
  );
  const values = useAppSelector((state) => state.ClientReducer.filterClient);
  const totalEntitie = useAppSelector((state:any)=>(state.ClientReducer.totalEn))
  const dispatch = useDispatch();

  const onSelectChange = (selectedRowKeys: any, record: any) => {
    let filter: Array<any>;
    filter = [];
    filter = dataSource
      .filter((item: any) => {
        return !selectedRowKeys.includes(item.id);
      })
      .map((ele: any) => ele.id);
    selectedRowKeys = [...valuePagination.id, ...selectedRowKeys];

    let uniqItems: Array<any>;
    let uniqItemsFilter: Array<any>;
    uniqItems = Array.from(new Set(selectedRowKeys));
    uniqItemsFilter = uniqItems.filter((item) => {
      return !filter.includes(item);
    });

    dispatch(
      setPaginationPropsClient({
        PaginationPropsNameChange: "id",
        PaginationPropsValueChange: uniqItemsFilter,
      })
    );
    dispatch(
      setshowDivsConditionsClient({
        showDivsConditionsName: "elementSelected",
        showDivsConditionsValue: uniqItemsFilter.length,
      })
    );

    setSelectedRowKeys(uniqItemsFilter);
  };
  const handleIconClick = (e:any, y:any) => {
 
  };

  const updatedColumns = [
    {
      headerName: <label className="titlecolumn">{t('configuration.Client.key')}</label>,
      width:250,
      field: 'Cle',
      editable: false,
      className:"contentcolumn",
      align: "center",
       sortable: false,
       minWidth:250,
       flex:1,
       renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t('configuration.Client.key')}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),

  },
 
  {
      headerName: <label className="titlecolumn">{t('value')}</label>,
      width:1350,
      field: 'Value',
      sortable: false,
      editable: false,
      className:"contentcolumn",
      align: "center",
      minWidth:300,
      flex:1,
      hide : hideColumn(),
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t('value')}</span>
          <div className="toghetherAllArrows">
            <ArrowDropUpIcon onClick={()=>handleIconClick(0,"asc")} style={{height:"0.8rem", cursor: 'pointer' }} />
            <ArrowDropDownIcon onClick={()=>handleIconClick(0,"desc")} style={{height:"0.8rem", cursor: 'pointer' }} />
          </div>
        </>
      ),


  },
    {
      headerName: <label className="titlecolumn">{t("actions")}</label>,
      key: "action",
      renderCell: (record: any, action: any) => {
        return <UseActionMenu record={record} />;
      },
      width: 250,
      editable: false,
      sortable: false,
      minWidth:100,
      renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t('Serveur.actions')}</span>
        </>
      ),


    },
  ];

  const searchWord=useAppSelector((state)=>state.ClientReducer.filterClient.search)
  const dataWithoutNodeKey = dataSource.map((item:any)=>item.node)
  const filteredevents = handelSearch(values.searchFake, dataWithoutNodeKey);
  const resetPagination = () => {
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };
const {GetListClients} = GraphQLFetchData();

useEffect(()=>{
  const offset = (currentPage - 1) * pageSize;
  if (!searchWord) {
    GetListClients({variables:{
      pagination: { offset: offset, limit: pageSize }   
    }});
  }
  else {
    GetListClients({variables:{
      where: searchWord ? {
        or: [
          {
            cleContains: searchWord
          }, 
          {
            valueContains: searchWord
          }
        ]
      } : { },    
      pagination: { offset: offset, limit: pageSize }
    }});
  }
  
},[GetListClients, currentPage, pageSize, searchWord]);
const {multiDelete} = GraphQLFetchData()
const isModalVisible = useAppSelector((state:any)=>state.ClientReducer.isMultiModalVisible)
const idSet = useSelector((state:any)=>state.ClientReducer.idSet);
  const DataTable = () => (
    <div
      style={{ height: "100%", width: "100%", border: "none" }}
    >
         <ModalConfirm
      
      open={isModalVisible}
      onClose={() => {dispatch(setmultiDeletemodal(false))}}
      title={ idSet.length > 1 ? t("configuration.Client.Do you want to delete the parameterM?") : t("configuration.Client.Do you want to delete the parameter ?")}
      content={t("configuration.Client.Les éléments sélectionnés seront supprimés")}
      fullWidth={true}
      CancelBtnName={t('No')}
      ConfirmBtnName={t("Yes")}
      onCancel={() => {dispatch(setmultiDeletemodal(false))}}
      onConfirm={() => {multiDelete({variables:{id:idSet,pagination:{offset:0,limit:0}}}); dispatch(setmultiDeletemodal(false));  dispatch(setDeleteLoader(true))}}
    />
      <Box height={"680px"}>
      
        {filteredevents.length === 0 ? <GridNorows content={t("Aucun enregistrement correspondant trouvé")} /> :<Datagrid 
        selectionModel={selectedRowKeys}
        checkboxSelect={(id:any)=>{
          setSelectedRowKeys(id);
          dispatch(setIdSet(id));
          if(id.length>0) {
            dispatch(setTrashContainer(true))
          }
          else  {
            dispatch(setTrashContainer(false))

          }
        }}
        rows={dataSource && filteredevents} columns={ updatedColumns as any} setPageSize={ setPageSize}  setCurrentPage={setCurrentPage}    currentPage={currentPage}  totalEntities={totalEntitie} pageSize={pageSize} ShowName={t("configuration.Client.Afficher")} EntriesName={t("configuration.Client.éléments")} OfPageName={t("configuration.Client.sur")} 
        height="calc(100vh - 150px)" />}
      </Box>
    </div>
  );

  return {
    DataTable,
    selectedRow,
    selectedRowKeys,
    currentPage,
    pageSize,
    resetPagination,
  };
}

export default useDataTableMedia;
