import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setFilterVideoshomeActions, setListVideoshomeActions, setDataVideoshomeActions,
    setChannelSelectedVideoshomeActions, setErrorrMessageVideoshomeActions,setVideoLink,setLoading, setmultiCase,showNotification,setOpenModalDel, setOpenDeleteSpace} from "../store/videoshomeActions"
import { useAppSelector } from "../../../../Redux/hooks";

import {
    GetData,
    PostData,
    DeleteData,
} from "../../../../APIcalls";
import { message, notification } from "antd";
import {useTranslation} from "react-i18next";
import {GraphQLFetchData} from "./GraghFetchData";

const Hooks = () => {

    const [visible, setVisible] = useState<any>(false)
    const dispatch = useDispatch()
    const {t} = useTranslation();

    const values = useAppSelector((state) => state.VideoshomeReducer.addVideoshomeConstraintsData)

    const channelselected = useAppSelector((state) => state.VideoshomeReducer.ChannelSelected)
    const selectedItems = useAppSelector((state)=>state.VideoshomeReducer.selectedCase)

    const [pageSize,setPageSize]=useState(10)
    const [currentPage,setCurrentPage]=useState(1)
    const {multiDelete} = GraphQLFetchData()
    const searchedWord = useAppSelector((state)=>state.VideoshomeReducer.filterVideoshome.search)
    const valueFilter = useAppSelector((state) => state.VideoshomeReducer.filterVideoshome)
    const totalRecord = useAppSelector((state)=>state.VideoshomeReducer.totalRecord)
    const statusDel = useAppSelector((state)=>state.VideoshomeReducer.statusDel)
    ////****************** function Filter *****************///////
    //*********************** search header **************///////
    const handleSearchRow = (event: any) => {
        // if (event.key === 'Enter') {
             dispatch(setFilterVideoshomeActions({
                FilterVideoshomeNameChange: "search",
                FilterVideoshomeValueChange: event
            }));
        // }
    }
    const onChangeFolder =  (e: any) => {
        if (e.target.value === "tous") {
             getListVideoshome(currentPage,pageSize,searchedWord)
            dispatch(setChannelSelectedVideoshomeActions(e.target.value))
        } else {
           
            getListVofcanal(currentPage,pageSize,e.target.value,searchedWord)
            dispatch(setChannelSelectedVideoshomeActions(e.target.value))
        }
       
    }




    /************ Search *********/
    const handelSearch = (word: string, tab: any) => {
        if (word.trim() === "") return tab;

        const res = tab.filter((obj: any) => {
             if (obj?.video?.title?.toLowerCase().includes(word?.toLowerCase()) || obj?.id || obj?.video?.themes[0]?.title.toLowerCase().includes(word.toLowerCase()) || obj?.video?.themes.flatMap((theme:any)=> theme?.channels.filter((c:any)=>c?.title.toLowerCase().includes(word.toLowerCase()))))return obj;
        });

        return res;

    };




    const handelFilter = (title: any, tab: any) => {
        function smallval(item: any) {
            if (title === "All") {
                return item
            }
            else if (title !== "") {
                return item.title === title;
            } else {
                return item
            }
        }
        const res = tab.filter(smallval);


     return res;
    };

    const getListChannel = async () => {
        try {
            const res = await GetData(`/channel/select-choices`);
    
            let globaldata: any[] = []
            let value: any = ""

            value = Object.keys(res.data.choices)
          
            value.forEach((item: any, key: any, value: any) => {
                const obj = {
                    id: item,
                    value: res.data.choices[item]
                }
                globaldata.push(obj)
            })
            if (res.status === 200) {
                dispatch(setListVideoshomeActions(globaldata));
            }
        } catch (e) {
            dispatch(setErrorrMessageVideoshomeActions("error"));
        }
    }
    
    const getListVideoshome = async (currentPage:any,pageSize:any,searchWord:any) => {
        dispatch(setLoading(true))
        dispatch({type:"STATUS_DEL",payload:false})
        try {
            const offset = (currentPage - 1) * pageSize;
            let path=''
            if(searchWord === '' && statusDel === false){
                path =`/video-api/video/home-page?columns[0][orderable]=true&order[0][column]=0&draw=${currentPage}&length=${pageSize}&order[0][column]=0&order[0][dir]=desc&start=${offset}`
            }else{
                dispatch(setLoading(false))
                path=`/video-api/video/home-page?columns[0][orderable]=true&order[0][column]=0&draw=${currentPage}&length=${pageSize}&order[0][column]=0&order[0][dir]=desc&start=${offset}&searchWord=${searchWord}`
            }
            const res = await GetData(path);
        
            if (res.status === 200) {
                dispatch(setDataVideoshomeActions(res?.data?.data));
                dispatch({type:"SET_RECORDS",payload:res.data.recordsFiltered})
                dispatch({type:"TOTAL_RECORDS",payload:res?.data?.recordsTotal})
                dispatch(setLoading(false))
            
            }else{
                dispatch(setErrorrMessageVideoshomeActions("error"));
            }
        } catch (e) {
            dispatch(setErrorrMessageVideoshomeActions("error"));
            
        }

    }
    

    const resetPage= async()=>{
        dispatch(setOpenDeleteSpace(false));
        dispatch(setLoading(false));
        const offset = (currentPage - 1) * pageSize;
        try{
            const path = channelselected === "tous"
            ? `/video-api/video/home-page?columns[0][orderable]=true&order[0][column]=0&draw=${currentPage}&length=${pageSize}&order[0][column]=0&order[0][dir]=desc&start=${offset}`
            : `/video-api/video/home-page?columns[0][orderable]=true&order[0][column]=0&draw=${currentPage}&length=${pageSize}&order[0][column]=0&order[0][dir]=desc&start=${offset}&idChannel=${channelselected}`;
            const res = await GetData(path)
            if (res.status === 200) {
                dispatch(setDataVideoshomeActions(res?.data?.data));
                dispatch({type:"SET_RECORDS",payload:res.data.recordsFiltered})
                dispatch(setLoading(false))   
            }else{
                dispatch(setErrorrMessageVideoshomeActions("error"));
            }
        } catch (e) {
            dispatch(setErrorrMessageVideoshomeActions("error"));
            
        }
    }

    const getListVofcanal = async (currentPage:any,pageSize:any,id: any,searchText:any) => {
        try {
            const offset = (currentPage - 1) * pageSize;
            let path=''
            if(searchText ==='' && statusDel === false){
                path = `/video-api/video/home-page?columns[0][orderable]=true&order[0][column]=0&draw=${currentPage}&length=${pageSize}&order[0][column]=0&order[0][dir]=desc&start=${offset}&idChannel=${id}`
            }else{
                dispatch(setLoading(false))
                path=`/video-api/video/home-page?columns[0][orderable]=true&order[0][column]=0&draw=${currentPage}&length=${pageSize}&order[0][column]=0&order[0][dir]=desc&start=${offset}&idChannel=${id}&searchWord=${searchedWord}`
            }
    
              const res = await GetData(path);   
                if (res.status === 200) {

                dispatch(setDataVideoshomeActions(res?.data?.data));
                dispatch({type:"SET_RECORDS",payload:res.data.recordsFiltered})
            }
            else{
                dispatch(setErrorrMessageVideoshomeActions("error"));
            }
        } catch (e) {
            dispatch(setErrorrMessageVideoshomeActions("error"));
            
        }
    }



    const Deleteselecteditem = async (id:any)=> {
        try {
            const res = await DeleteData(`/video/delete-homePage-video/${id}`);          
            if(res.status === 200){
                
                    getListVofcanal(currentPage,pageSize,channelselected,'')
                    message.success({
                      content: t("SimpleDelete"),
                      duration: 2,
                    }); //here
                    dispatch({type:"STATUS_DEL",payload:true})
                   resetPage();
           
            }else{
                dispatch(setErrorrMessageVideoshomeActions("error"));

                message.error({ content: t('SuppessionAvecErreurUN'), duration: 2 })
            }

          } catch (e) {
            dispatch(setErrorrMessageVideoshomeActions("error"));
            dispatch(getListVideoshome(currentPage,pageSize,''))
          }
     }

     const editOrdre = async (channel:any, listids:any ) => {
        try {
          
            const res = await GetData(`/video/videos/edit-sort-home-page-videos?data={"channel":${channel},"ids":${listids}}`);
           

            if (res.status === 200) {
           
            }else{
                dispatch(setErrorrMessageVideoshomeActions("error"));
            }
        } catch (e) {
            dispatch(setErrorrMessageVideoshomeActions("error"));
        }
    }


    const getVideoFile=async(id:any)=>{
        try{
           const res = await GetData(`/video/video/preview-link/${id}?y=bo`);
            if(res.status === 200){
                dispatch(setVideoLink(res.data.link))
            }else{
                dispatch(setErrorrMessageVideoshomeActions("error "))
            }
        }catch(e){
            dispatch(setErrorrMessageVideoshomeActions("error "))
            message.error({ content:t('videoError'), duration: 2 })
        }
    }

    const multiDel = async () => {
        try {

            dispatch(setLoading(true));
            const ids = [selectedItems.join()];
            const res = await DeleteData(`/video/multi-delete-homepage-video?id=${ids}`);      

          if (res.status === 200) {

            dispatch(setmultiCase(ids));
            dispatch(setLoading(false));
            message.success({ content: t("multiDelete"), duration: 2 }); 
            dispatch({type:"STATUS_DEL",payload:true})
            resetPage()
    
          
        }
        if(res.status === 400 || res.status === 500){
            message.error({ content: t('SuppressionAvecErreurMultiple'), duration: 2 })
          }
        } catch (error) {
          dispatch(setLoading(false));
        dispatch(getListVideoshome(currentPage,pageSize,''))

        }
      };
      
        

    return ({

        handleSearchRow,
        onChangeFolder,
        visible,
        values,
        handelSearch,
        handelFilter,
        getListChannel,
        getListVideoshome,
        getListVofcanal,
        Deleteselecteditem,
        editOrdre,
        getVideoFile,
        multiDel,
       
    })
}
export default Hooks