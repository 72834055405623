import { useEffect } from "react";
import ReferentHeader from "./components/referentHeader";
import ReferentTable from "./components/List/referentTable";
import AddEditReferent from "./components/AddEdit/addEdit";
import "./referent.scss";
import { useAppSelector } from "../../Redux/hooks";
import Hooks from "./utils/hooks";
import { Grid } from "@material-ui/core";

const Referent = () => {
  const {
    formAddReferent,
    onFinishAddReferent,
    onValueFormUserchangeReferent,
    getAllData,
  } = Hooks();
  const showDivCondition = useAppSelector(
    (state) => state.ReferentReducer.showDivsConditionsReferent
  );

  const values = useAppSelector(
    (state) => state.ReferentReducer.addReferentConstraintsData
  );

  useEffect(() => {
    getAllData();
    if (showDivCondition.editReferent) {
      formAddReferent.setFieldsValue({
        id: values.id,
        url: values.url,
        mode: values.mode,
        name: values.name,
        list: values.list,
      });
    }

    if (showDivCondition.addReferent) {
      formAddReferent.setFieldsValue({
        id: "",
        url: "",
        mode: "",
        name: "",
        list: "",
      });
    }
  }, [showDivCondition.editReferent, showDivCondition.addReferent]);
  return (
    <div className={"divGlobalTable"}>
      {!showDivCondition.addReferent && !showDivCondition.editReferent ? (
        <div>
          <ReferentHeader />
          <ReferentTable />
        </div>
      ) : (
        <div>
          <Grid
            onSubmit={onFinishAddReferent}
            onChange={onValueFormUserchangeReferent}
          >
            <ReferentHeader />
            <AddEditReferent />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Referent;
