import { ClientList } from "./clientConstants";
const { showDivsConditionsClient, AddClientConstraintsData, paginationPropsClient, FilterClient, DataClient, DetailClient } = ClientList()

const ClientListINITIALSTATE = {
    showDivsConditionsClient: showDivsConditionsClient(),
    paginationPropsClient: paginationPropsClient(),
    addClientConstraintsData: AddClientConstraintsData(),
    filterClient: FilterClient(),
    dataClient: DataClient(),
    detailClient: DetailClient(),
    isLoading:false,
    deleteModalState:false,
    showTrash:false,
    totalEn:0,
    idSet : [],
    isMultiModalVisible:false,
    rowId:null,
    rightsLoader:true,

}

export const ClientReducer = (state = ClientListINITIALSTATE, action: any) => {

    switch (action.type) {

        case "SET_FilterClient":
            const { FilterClientNameChange, FilterClientValueChange } = action.payload
            return {
                ...state,
                filterClient: { ...state.filterClient, [FilterClientNameChange]: FilterClientValueChange }
            }
        case "Set_Row_id" :
            return {
                ...state,
                rowId:action.payload,
            }
        case "Set_rights_loader":
            return {
                ...state,
                rightsLoader:action.payload,
            }
      
        case "SET_multiModal" :
            return {
                ...state,
                isMultiModalVisible:action.payload,
            }
        case "SET_IDSET" :
            return {
                ...state,
                idSet:action.payload,
            }
        case "SET_trash" :
            return {
                ...state,
                showTrash:action.payload,
            }
        case "Set_totlaEn" :
            return {
                ...state,
                totalEn:action.payload,
            }
        case "Set_Delete_Loader" :
            return {
                ...state,
                deleteModalState:action.payload,
            }
        case "SET_loader" : 
        return {
            ...state,
            isLoading:action.payload,
        }
        case "SET_PaginationPropsClient":
            const { PaginationPropsNameChange, PaginationPropsValueChange } = action.payload
            return {
                ...state,
                paginationPropsClient: { ...state.paginationPropsClient, [PaginationPropsNameChange]: PaginationPropsValueChange }
            }
        case "SET_showDivsConditionsClient":
            const { showDivsConditionsName, showDivsConditionsValue } = action.payload
            const showDivsConditionsObj = { ...state.showDivsConditionsClient, [showDivsConditionsName]: showDivsConditionsValue }
            return {
                ...state,
                showDivsConditionsClient: showDivsConditionsObj
            }
        case "SET_AddClientActions":
            const { addClientConstraintsDataNameChange, addClientConstraintsDataValueChange } = action.payload
            return {
                ...state,
                addClientConstraintsData: { ...state.addClientConstraintsData, [addClientConstraintsDataNameChange]: addClientConstraintsDataValueChange }
            }

        case "SET_DataClientActions":

            return {
                ...state,
                dataClient: action.payload
            }
        case "SET_DetailClientActions":

            return {
                ...state,
                detailClient: action.payload
            }

        default: {
            return state
        }

    }

}

