import gql from "graphql-tag";


    export const Get_All_servers = gql`
        mutation ($where:ServerWhereInput $pagination: Pagination) { 
            servers(where:$where pagination:$pagination orderBy:{field:ID direction:DESC}) {
            totalCount
                edges{
                    node{
                        Id
                        Protocol
                        Type
                        Internet
                        Record
                        IsDisplay
                        IsBuffer
                        Dir
                        UrlVideo
                        UrlMp4
                        UrlDiffusion
                        UrlVodAndroid
                        UrlLiveAndroid
                        UrlCdnAndroid
                        UrlVodIos
                        UrlLiveIos
                        UrlCdnIos
                        DirLecture
                        UrlBackup
                        UrlMobile
                        UrlServerFtp
                        LoginLive
                        Name
                        Login
                        Pwd
                        PwdLive
                        Bitrate
                        ServerName
                        ServerType
                        Port
                        SshKey
                        StreamPlaylistLive
                        Notes
                    }
                }
        }
    }`;

    export const Get_Types = gql`
    mutation ($where:ServerWhereInput $pagination: Pagination) { 
            servers(where:$where pagination:$pagination) {
            totalCount
                edges{
                    node{
                        Type
                    }
                }
            }
    }
    `

    export const Delete_Server = gql`
        mutation ($id:Int) { 
            DeleteServer(id:$id) 
        }
    `
    export const Multi_Delete = gql`
        mutation ($id: [Int]){
            DeleteServers(id:$id){code message}
    }`

    export const Get_One_Server_Info = gql`
        mutation ($where:ServerWhereInput $pagination: Pagination) 
        { servers (where: $where pagination:$pagination) {
                edges{
                    node{
                        Id
                        Protocol
                        Type
                        Internet
                        Record
                        IsDisplay
                        IsBuffer
                        Dir
                        UrlVideo
                        UrlMp4
                        UrlDiffusion
                        UrlVodAndroid
                        UrlLiveAndroid
                        UrlCdnAndroid
                        UrlVodIos
                        UrlLiveIos
                        UrlCdnIos
                        DirLecture
                        UrlBackup
                        UrlMobile
                        UrlServerFtp
                        LoginLive
                        Name
                        Login
                        Pwd
                        PwdLive
                        Bitrate
                        ServerName
                        ServerType
                        Port
                        SshKey
                        StreamPlaylistLive
                        Notes
                        }
                    }
                }
    }`

    export const Upadte_Server = gql`
    mutation ($input: UpdateServerInput!) {
        updateServer(input: $input) 
        {
        Protocol
        Type
        Internet
        Record
        IsDisplay
        IsBuffer
        Dir
        UrlVideo
        UrlMp4
        UrlDiffusion
        UrlVodAndroid
        UrlLiveAndroid
        UrlCdnAndroid
        UrlVodIos
        UrlLiveIos
        UrlCdnIos
        DirLecture
        UrlBackup
        UrlMobile
        UrlServerFtp
        LoginLive
        Name
        Login
        Pwd
        PwdLive
        Bitrate
        ServerName
        ServerType
        Port
        SshKey
        StreamPlaylistLive
        Notes
}}
    `

    export const Add_Server = gql`
    mutation ($input: CreateServerInput!) {
        CreateServer(input: $input) 
        {
        Protocol
        Type
        Internet
        Record
        IsDisplay
        IsBuffer
        Dir
        UrlVideo
        UrlMp4
        UrlDiffusion
        UrlVodAndroid
        UrlLiveAndroid
        UrlCdnAndroid
        UrlVodIos
        UrlLiveIos
        UrlCdnIos
        DirLecture
        UrlBackup
        UrlMobile
        UrlServerFtp
        LoginLive
        Name
        Login
        Pwd
        PwdLive
        Bitrate
        ServerName
        ServerType
        Port
        SshKey
        StreamPlaylistLive
        Notes
}}`
    