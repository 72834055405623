import { TYPES } from "./types";
import axios from "axios";
import { setRightsLoader } from "../MenuConfiguration/client/store/clientActions";
const search = window.location.search;
const token = new URLSearchParams(search).get("token");

const headers = {
    Authorization: `Bearer ${token}`,
}

const url = "https:" +  window.process.env.API_HOST_GLOBAL;
const videoApi = window.process.env.VIDEO_API;
let path = videoApi ? videoApi : url

export const getUserData = () => (dispatch) => {
  axios
    .get(`${path}/core/rights/get-rights-by-module`, { headers })
    .then((res) => {  if(res.status === 200) {
      dispatch(setRightsLoader(false));
    }; 
    dispatch({ type: TYPES.GET_USER_DATA, payload: res.data })
    if(res.data.userData.roles.includes("ROLE_SUPER_ADMIN")){
      dispatch({type:TYPES.IS_SUPER_ADMIN})
    }
  })
    
    .then(() => dispatch({ type: TYPES.ON_LOADING_SCREEN, payload: false }))
    .catch(()=>dispatch({type:TYPES.UNAUTHORIZED}))
};
