import {PresetList} from "../store/preset"
const {PresetTypes}=PresetList()
export function setPaginationPropsPreset(e:any){
    return{
        type:"SET_PaginationPropsPreset",
        payload:e,
    }
}

export function setshowDivsConditionsPreset(e:any){
    return {
        type : "SET_showDivsConditionsPreset",
        payload:e
    }
}

export function restartData(){
    return {
      type: "SET_Restar",
    };
}

export function setAddPresetActions(e:any){
    return{
        type:"SET_AddPresetActions",
        payload:e,
    }
}

export function setFilterPresetActions(e:any){

    return{
        type:"SET_FilterPreset",
        payload:e,
    }

}

export function setEditPresetActions(e:any){
    return{
        type:"SET_EditPresetActions",
        payload:e,
    }
}


export function setPresetDataActions(e:any){
    return{
        type:PresetTypes.SET_PRESET_DATA,
        payload:e,
    }
}
export function setDetailedPresetActions(e:any){
    return{
        type:"SET_DetailServeurActions",
        payload:e,
    }
}


