import React from 'react'
import './SkinPage.scss'
import { RegisterSkins } from './Components/RegisterSkins'
import SkinHeader from './Components/SkinHeader'

function SkinPage() {
  return (
    <div>
      <SkinHeader/>
      <RegisterSkins/>
    </div>
  )
}

export default SkinPage
