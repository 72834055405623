import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    setListConfigurationActions, setConfigChannelSelectedConfigurationActions,
    setErrorrMessageConfigurationActions, setListvideosChannelSelectedConfigurationActions,
    setEditConfigChannelSelectedConfigurationActions, setIdChannelSelectedConfigurationActions,
    setLoading,
    setDisabledButton
} from "../store/configurationActions"
import { useAppSelector } from "../../../../Redux/hooks";
import {
    GetData,
    PostData,
    DeleteData,
} from "../../../../APIcalls";

const Hooks = () => {

    const [visible, setVisible] = useState<any>(false)
    const [visibleExport, setVisibleExport] = useState<any>(false)
    const [statusSuccessMessages, setStatusSuccessMessages] = useState<any>(true)
    const [statusErrorMessages, setStatusErrorMessages] = useState<any>(true)
    const dispatch = useDispatch()

    const values = useAppSelector((state) => state.ConfigurationReducer.addConfigurationConstraintsData)
    const defaultPageOption = useAppSelector((state) => state.ConfigurationReducer.ConfigChannelSelected)
    const idchannelselected = useAppSelector((state) => state.ConfigurationReducer.idchannelselected)






    const handleChange =  (value: any) => {
        dispatch(setIdChannelSelectedConfigurationActions(value.target.value))
         getInfoConfigChannel(value.target.value)
         getInfoListVideosChannel(value.target.value)

            
    }


    const onResetConfiguration = async () => {
        
    }




    const onFinishAddConfiguration = (values: any) => {
        AddData(values)

    }
    const onFinishFailedAddConfiguration = (errorInfo: any) => {
    }
    const onValueFormUserchangeConfiguration = async (val: any) => {
        if (val.nbVid) {
            await dispatch(setEditConfigChannelSelectedConfigurationActions({
                ListvideosChannelNameChange: "nbVid",
                ListvideosChannelValueChange: defaultPageOption.nbVid
            }))
        }
        else if (val.canal) {
           
        }

    }



    const handleChangenbVid =  (value: any) => {
        
        dispatch(setEditConfigChannelSelectedConfigurationActions({
            ListvideosChannelNameChange: "nbVid",
            ListvideosChannelValueChange: value.target.value
        }))
    }

    const getListChannel = async () => {
        try {
             dispatch(setLoading(true))
            const res = await GetData(`/channel/select-choices`);


            let globaldata: any[] = []
            let value: any = ""

            value = Object.keys(res.data.choices)

            value.forEach((item: any, key: any, value: any) => {
                const obj = {
                    id: item,
                    value: res.data.choices[item]
                }
                globaldata.push(obj)
            })
            if (res.status === 200) {
                dispatch(setListConfigurationActions(globaldata));
                dispatch(setLoading(false))
            }
        } catch (e) {
            dispatch(setErrorrMessageConfigurationActions(e));
        }
    }

    const getInfoConfigChannel = async (idCanal: any) => {
        try {
            const res = await GetData(`/home_conf/confs/${idCanal}/by/canal/gethome`);
            if (res.status === 200 && res.data.code === 200) {

                await dispatch(setConfigChannelSelectedConfigurationActions(res.data.home[0]));
             
              
            }
            else if (res.status === 200 && res.data.code === 400) {

                dispatch(setErrorrMessageConfigurationActions(res.data.message));
            }
            else if (res.status === 400) {

                dispatch(setErrorrMessageConfigurationActions(res.data.message));
            }
        } catch (e) {
            dispatch(setErrorrMessageConfigurationActions(e));
        }
    }


    const getInfoListVideosChannel = async (idCanal: any) => {
        try {
            const res = await GetData(`/home_conf/homes/${idCanal}/conf/video/by/canal`);



            if (res.status === 200 && res.data.code === 200) {


                await dispatch(setListvideosChannelSelectedConfigurationActions(res.data.data.videos));
                dispatch(setDisabledButton(false))
            }
            else if (res.status === 200 && res.data.code === 400) {

                dispatch(setErrorrMessageConfigurationActions(res.data.message));
            }
            else if (res.status === 400) {

                dispatch(setErrorrMessageConfigurationActions(res.data.message));
            }
        } catch (e) {
            dispatch(setErrorrMessageConfigurationActions("error"));
        }
    }

    const AddData = async (data: any) => {
      
        try {
            dispatch(setDisabledButton(true))
            const body = {
                "empHomeConfForm": {
                    "pageOption": defaultPageOption.pageOption,
                    "nbVid": defaultPageOption.nbVid,
                    "video": defaultPageOption.video,
                    "channel": idchannelselected
                }, "channel": idchannelselected
            }
         const res = await PostData(`/home_conf/home-conf/add`, body);
            if (res.status === 200) {
                dispatch(setDisabledButton(false))
                onResetConfiguration()
             
            } 
            else if (res.status === 400) {

                dispatch(setErrorrMessageConfigurationActions(res.data.message));
            }
        } catch (e) {

            dispatch(setErrorrMessageConfigurationActions(e));
        }
    }



    return ({

        onResetConfiguration,




        visibleExport,


        onFinishAddConfiguration,
        onFinishFailedAddConfiguration,
        onValueFormUserchangeConfiguration,
        values,

        handleChangenbVid,

        handleChange, 

        getListChannel,
        getInfoConfigChannel,
        getInfoListVideosChannel,
        AddData


    })
}
export default Hooks