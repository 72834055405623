import React from "react";
import { useTranslation } from "react-i18next";
import { Card, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Hooks } from "../../utils/hooks";

const AddEditActuality = () => {
  const { t } = useTranslation();
  const { values } = Hooks();
  const [value, setValue] = React.useState(null);
  const [content, setcontent] = React.useState(values.content);
  const [newDate, setNewDate] = React.useState(values.date);

  const changeDate = (newValue: any) => {
    setValue(newValue);
    setNewDate(newValue);
  };
  const changeContent = (newContent: any) => {
    setcontent(newContent.target.value);
  };

  return (
    <div className={"divCard"}>
      <Card>
        <div>
          <div className={"InputLablDiv"}>
            <TextField
              required
              id={"title"}
              name="content"
              label={t("configuration.Actuality.ContentField")}
              placeholder={t("configuration.Actuality.ContentField")}
              className="textField"
              value={content}
              onChange={changeContent}
              multiline
            />
          </div>
          <div className={"InputLablDiv"}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {values.date ? (
                <DatePicker
                  label={t("configuration.Actuality.Date")}
                  value={newDate}
                  onChange={changeDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              ) : (
                <DatePicker
                  label={t("configuration.Actuality.Date")}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              )}
            </LocalizationProvider>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default AddEditActuality;
