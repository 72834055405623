
export const ConfigurationList = () => {






    const AddConfigurationConstraintsData = () => {
        return (
            {
                canal: {
                    id: "",
                    value: ""
                },
                idcanal: "",
                pageOption: ["1"],
                nbVid: "",

            }
        )
    }

    const ListChannelConfigurationData = () => {
        return (
            {
                data: [],


            }
        )
    }

    const ConfigChannelSelectedConfigurationData = () => {
        return (
            {
                id: "",
                pageOption: "",
                nbVid: "",
                defaultConf: "",
                video: ""


            }
        )
    }
    

    return ({

        AddConfigurationConstraintsData,
        ListChannelConfigurationData,
        ConfigChannelSelectedConfigurationData
    })
}
