import React,{useEffect, useState} from 'react'
import ConfigurationHeader from './components/configurationHeader';
//import ConfigurationTable from "./components/List/configurationTable";
//import AddEditConfiguration from "./components/AddEdit/addEdit";
import './configuration.scss'
import {useAppSelector} from "../../../Redux/hooks";
import Hooks from "./utils/hooks";
import ConfigurationData from './components/configurationData';
import DisplayAlert from './components/Alert';
import {Grid} from "@material-ui/core";
import { Loader } from '@empreinte/components.loader';


const Configuration = () => {
    const {getListChannel, onFinishAddConfiguration,onFinishFailedAddConfiguration, onValueFormUserchangeConfiguration  } = Hooks()
    const [selected, setSelected] = useState({})
    const loader = useAppSelector((state)=>state.ConfigurationReducer.isLoading)
   

   // const showDivCondition = useAppSelector((state:any) => state.ConfigurationReducer.showDivsConditionsConfiguration)
   //const values = useAppSelector((state:any) => state.ConfigurationReducer.addConfigurationConstraintsData)
    useEffect(() => {
      
        getListChannel()
        // formAddConfiguration.setFieldsValue({
        //     canal: values.canal,
        //     idcanal: undefined,
        //     pageOption: values.canal,
        //     nbVid: values.canal
        // })
       
    }, [])
  

    return (
        <div className={"divGlobalTable"}>
            <DisplayAlert/>
                    <div>
                    <Loader isOpen={loader}/>
                    <Grid
                           onSubmit={onFinishAddConfiguration}
                            onChange={onValueFormUserchangeConfiguration}

                        >
                        <ConfigurationHeader/>
                      
                      <ConfigurationData/>
                      </Grid>
                    </div>

                   
        </div>
    )
}

export default Configuration
