import { MetadonnesList } from "./metadonnesConstants";
const { showDivsConditionsMetadonnes} = MetadonnesList()

const MetadonnesListINITIALSTATE = {
    showDivsConditionsMetadonnes: showDivsConditionsMetadonnes(),

    RmBoxSelected: 3,
    ErrorMessage: ""
}

export const MetadonnesReducer = (state = MetadonnesListINITIALSTATE, action: any) => {

    switch (action.type) {

   
        case "SET_showDivsConditionsMetadonnes":
            const { showDivsConditionsName, showDivsConditionsValue } = action.payload
            const showDivsConditionsObj = { ...state.showDivsConditionsMetadonnes, [showDivsConditionsName]: showDivsConditionsValue }
            return {
                ...state,
                showDivsConditionsMetadonnes: showDivsConditionsObj
            }
        default: {
            return state
        }

    }

}

