import { VideoshomeList } from "./videoshomeConstants";
const { AddVideoshomeConstraintsData, paginationPropsVideoshome, FilterVideoshome, DataVideoshome } = VideoshomeList()

const VideoshomeListINITIALSTATE = {

    paginationPropsVideoshome: paginationPropsVideoshome(),
    addVideoshomeConstraintsData: AddVideoshomeConstraintsData(),
    filterVideoshome: FilterVideoshome(),
    dataVideoshome: DataVideoshome(),
    ListChannelsChoices: [],
    ChannelSelected: "tous",
    ErrorMessage: "",
    videoLink:"",
    modalOpen:false,
    isLoading:false,
    multDel:[],
    selectedCase:[],
    showDeleteSpace:false,
    showDelModal:false,
    notification: {
        message: null,
        notificationType: null,
      },
    totalRecord:0,
    totalDataLength:0,
    statusDel:true
}

export const VideoshomeReducer = (state = VideoshomeListINITIALSTATE, action: any) => {

    switch (action.type) {

        case "SET_FilterVideoshome":
            const { FilterVideoshomeNameChange, FilterVideoshomeValueChange } = action.payload
            return {
                ...state,
                filterVideoshome: { ...state.filterVideoshome, [FilterVideoshomeNameChange]: FilterVideoshomeValueChange }
            }
        case "SET_PaginationPropsVideoshome":
            const { PaginationPropsNameChange, PaginationPropsValueChange } = action.payload
            return {
                ...state,
                paginationPropsVideoshome: { ...state.paginationPropsVideoshome, [PaginationPropsNameChange]: PaginationPropsValueChange }
            }

        case "SET_ListVideoshome":
            return {
                ...state,
                ListChannelsChoices: action.payload
            }

        case "SET_DataVideoshomeActions":
             
            return {
                ...state,
                dataVideoshome: action.payload
            }

        case "SET_RECORDS":
            return{
                ...state,
                totalRecord:action.payload
            }   
        case "TOTAL_RECORDS":
            return{
                ...state,
                totalDataLength:action.payload
            }
        case "SET_ChannelSelectedVideoshomeActions":
          
            return {
                ...state,
                ChannelSelected: action.payload
            }    
        case "SET_ErrorrMessage":

            return {
                ...state,
                ErrorMessage: action.payload
            }

         case "SET_VIDEOLINK":
            return{
                ...state,
                videoLink:action.payload

            }   
         case "SET_OpenModal":
            return{
                ...state,
                modalOpen:action.payload
            }
        case "SET_LOADING":
            return{
                ...state,
                isLoading:action.payload
            }    
        case "SET_IDSELECTED":
            return{
                ...state,
                selectedCase:action.payload
            }
          case "SET_MULTIDEL":
            return{
                ...state,
                multDel:action.payload
            }  
         case "SET_DELETESPACE":
            return{
                ...state,
                showDeleteSpace:action.payload
            } 
         case "SET_MODALDEL":
            return{
                ...state,
                showDelModal:action.payload
            }   
        case 'SHOW_NOTIFICATION':
        return { 
            ...state, 
            notification: {
                message: action.payload.message,
                notificationType: action.payload.notificationType,
              },
        }
        case 'STATUS_DEL':
            return {
                ...state,
                statusDel:action.payload
            }    
        default: {
            return state
        }

    }

}

