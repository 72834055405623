import { useMutation } from "@apollo/client";
import { graphQL_schema } from "./graghQL";
import { useDispatch } from "react-redux";
import { setThemesSkin ,setChannelsSkin ,getLatesVideoId,setErrorVideoUpload} from "../store/skinActions";
import { message } from "antd";
import { useTranslation } from "react-i18next";

export const GraphQLFetchData=()=>{
    const dispatch= useDispatch()
    const {t} = useTranslation()
    const [GetThemes] = useMutation(graphQL_schema().getThemes,{
        fetchPolicy:"no-cache",
        variables:{},
        onCompleted:(data)=>{
            const themes = data?.Themes.edges
            const filteredThemes = themes.map((t)=>t.node)
            dispatch(setThemesSkin(filteredThemes))
        },
        onError:(error)=>{
            console.log(error)
        }
    });

    const [GetChannels] = useMutation(graphQL_schema().getChannels,{
        fetchPolicy:"no-cache",
        variables:{},
        onCompleted:(data)=>{
            const channels = data?.channels?.edges
            const filteredChannels = channels.map((t)=>t.node)
            dispatch(setChannelsSkin(filteredChannels))
        },
        onError:(error)=>{
            console.log(error)
        }
    });
    const [GetLatestVideo] = useMutation(graphQL_schema().Get_Recent_Video, {
		fetchPolicy: "no-cache",
		variables: {},
		onCompleted: (data) => {
			const newData = data?.Videos?.edges?.map(vid=>({id:vid?.video?.Id}))
			dispatch(getLatesVideoId(newData[0]?.id));
            dispatch(setErrorVideoUpload(false))

		},
		onError: (error) => {
			message.error({ content: t("Skin-Player.ErrorTranscodedVideo"), duration: 2 });
            dispatch(setErrorVideoUpload(true))
		},
	});
    return{
        GetThemes,
        GetChannels,
        GetLatestVideo
    }
}