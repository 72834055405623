
export function setPaginationPropsVideoplayer(e:any){
    return{
        type:"SET_PaginationPropsVideoplayer",
        payload:e,
    }
}

export function setshowDivsConditionsVideoplayer(e:any){
    return {
        type : "SET_showDivsConditionsVideoplayer",
        payload:e
    }
}

export function setAddVideoplayerActions(e:any){
    return{
        type:"SET_AddVideoplayerActions",
        payload:e,
    }
}

export function setFilterVideoplayerActions(e:any){

    return{
        type:"SET_FilterVideoplayer",
        payload:e,
    }

}

export function setEditVideoplayerActions(e:any){
    return{
        type:"SET_EditVideoplayerActions",
        payload:e,
    }
}

export function setDataVideoplayerActions(e:any){
    return{
        type:"SET_DataVideoplayerActions",
        payload:e,
    }
}

export function setDetailVideoplayerActions(e:any){
    return{
        type:"SET_DetailVideoplayerActions",
        payload:e,
    }
}

