import React, { useEffect, useState , useCallback, useRef} from 'react';
import UseActionMenu from "./actionUserTable";

import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { Datagrid} from '@empreinte/components.datagrid'
import { GridNorows } from '@empreinte/components.grid-norows';
import { useDispatch } from "react-redux";
import {useAppSelector} from "../../../../../Redux/hooks";
import {DataVideoshome} from "../../utils/Data";
import Hooks from "../../utils/hooks"; 
import { useTranslation } from "react-i18next";
import { VideoModal } from '../modalVideo';
import { setOpenModal ,setVideoLink,setSelected,setOpenDeleteSpace,setFilterVideoshomeActions} from '../../store/videoshomeActions';
import { useMediaQuery } from '@material-ui/core';
const type = 'DraggableBodyRow';
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 1;


const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef();
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: monitor => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: { index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));

    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{ cursor: 'move', ...style }}
            {...restProps}
        />
    );
};



function useDataTableMedia({ columns, dataSource, updateEntityPath}) {

    const { t } = useTranslation();
    const { handelFilter, handelSearch , editOrdre,  getListVideoshome,getListChannel,getListVofcanal,resetPage} = Hooks()



    const data = useAppSelector((state) => state.VideoshomeReducer.dataVideoshome)
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  
    const [selectedRow, setSelectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  
    const [datasource , setDataSource]=useState(undefined)
    const values = useAppSelector((state) => state.VideoshomeReducer.filterVideoshome)
    const channelSelected = useAppSelector((state) => state.VideoshomeReducer.ChannelSelected)
    const videoLink = useAppSelector((state) => state.VideoshomeReducer.videoLink);
    const totalLength = useAppSelector((state)=>state.VideoshomeReducer.totalRecord);
    const tabletColumn = useMediaQuery('(min-width:757px)');
    const mobileColumn = useMediaQuery('(min-width:500px)');
    const searchWord = values.search;
   const totalDataLength = useAppSelector((state)=>state.VideoshomeReducer.totalDataLength)
    const dispatch=useDispatch()
    const [isOpen,setIsOpen]=useState(true);

  const handleCloseModal = () => {
    setOpenModal(false);
   dispatch(setVideoLink(''))
  };

useEffect(()=>{},[totalLength])
useEffect(()=>{

   if(channelSelected==="tous"){

        getListVideoshome(currentPage,pageSize,searchWord)

    }else{
        getListVofcanal(currentPage,pageSize,channelSelected,searchWord)
    
return;

}
  
},[currentPage,pageSize]);

useEffect(() => {
     
    if (currentPage > 1 && dataSource.length === 0) {
      setCurrentPage(1);
    }
  }, [currentPage, dataSource]);

useEffect(()=>{
    if(searchWord){
        setCurrentPage(1)
    }
},[searchWord])


    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = dataSource[dragIndex];
            setDataSource(
                update(dataSource, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                }),
            );
        },
        [data],
    );


    const  onSelectChange = (selectedRowKeys ) => {
        setSelectedRowKeys(selectedRowKeys);
        dispatch(setSelected(selectedRowKeys));
    };


  

    const updatedColumns = [
        ...columns,
        {
            headerName: 'Actions',
            field:'actions',
            key:5,
            flex:1,
            renderCell: (record, action) =>{
                return(
                    <UseActionMenu key={record.id} record={record}  />
                )
            } ,
            width:100 ,
            sortable: false,
        },
       
    ];

    const filteredeventsfilter = handelFilter(values.type, data)
    const filteredevents = handelSearch(values.search, filteredeventsfilter)



    useEffect(() => {
        if(datasource!== undefined){
            let listids = []
            datasource.forEach((item, key) => {
                listids.push(item.id)
            })
            
            if(channelSelected === "tous"){
                 editOrdre(0, listids)
            }else{
                editOrdre(channelSelected, listids)
            }
           
        }
    },[datasource]);

  

    const resetPagination = () => {
        setCurrentPage(DEFAULT_PAGE_NUMBER);
    };

    const handleTableChange = (pagination ,  filters , sorter, extra) => {
        setCurrentPage(pagination.current - 1);
    };

    function getFilteredEvents(filteredEvents, e) {
        let tab = filteredEvents?.filter((item) => {
          return e.includes(item.id);
        });
        let newArray = Array.from(tab, (item) => {
          return item.video.id;
        });
        return newArray;
      }
  
    const DataTable = () => (
      <div style={{ height: 700, marginTop: "1%", border: "none" }}>
        <DndProvider backend={HTML5Backend}>
           {(totalDataLength === 0 || totalLength === 0 || filteredevents?.length === 0) ?
           <GridNorows content={t('configuration.Videoshome.noData')} />
           :

           <Datagrid
                columns={
                tabletColumn
                  ? updatedColumns
                  : mobileColumn
                    ?  
                      updatedColumns.filter(
                        column =>
                          column.field !== 'url' &&
                          column.field !== 'themes'
                      ):
                       updatedColumns.filter(
                        column =>
                          column.field !== 'url' &&
                          column.field !== 'themes' &&
                          column.field !== 'canals'
                      )
                 
              }
               rows={datasource || filteredevents}
               height={"calc(90vh - 115px)"}
               setCurrentPage={setCurrentPage}
               currentPage={currentPage}
               setPageSize={setPageSize}
               pageSize={pageSize}
               totalEntities={totalLength}
               ShowName={t('configuration.Videoshome.rows')}
               OfPageName={t('configuration.Videoshome.of')}
               components={components}
               onRow={(record, index) => ({
                   index,
                   moveRow,
               })}
               checkboxSelection

               selectionModel={selectedRowKeys}
               checkboxSelect={e => {
                  if (e.length > 0) {
                      setSelectedRowKeys(e)
                    dispatch(setSelected(e));
                    dispatch(setOpenDeleteSpace(true));
                  } else {
                    dispatch(setOpenDeleteSpace(false));
                    setSelectedRowKeys([]);
                    dispatch(setSelected([])); 
                  }
                   
               }}
               />
         }    

        

          {videoLink && (
            <VideoModal
              isOpen={isOpen}
              videoUrl={`https:${videoLink}`}
              onClose={handleCloseModal}
            />
          )}
        </DndProvider>
      </div>
    );

    return {
        DataTable,

        selectedRow,
        selectedRowKeys,
        currentPage,
        pageSize,
        resetPagination,
    };
}

export default useDataTableMedia;