
import { useTranslation } from "react-i18next";

export const DataConfiguration = () => {

  const { t } = useTranslation();

  const ListTypeadd = ['All', 'Live', 'Fly', 'FO', 'VOD', 'Download', 'Podcast', 'Static']
  const ListChannelsChoices = ['Canal de test', 'Canal empreinte']
  const ListAll = [5, 10, 15, 20]

  const Optionscheckbox =[
    { label: `${t('configuration.Configurationhomepage.ShufflePlayback')}`, value: '1' },
    { label: `${t('configuration.Configurationhomepage.alphabetOrder')}`, value: '2' },
    { label: `${t('configuration.Configurationhomepage.datePub')}`, value: '3' },
    { label: `${t('configuration.Configurationhomepage.DatePubVideo')}`, value: '4' },
    { label: `${t('configuration.Configurationhomepage.readingOrder')}`, value: '5' },
  ];


  return ({

    ListTypeadd,
    Optionscheckbox,
    ListChannelsChoices,
    ListAll

  })

}
