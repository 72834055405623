import React, { useState } from "react";
import {
  Button,
  Card,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  AddBoxOutlined,
  DeleteOutlineOutlined,
  SyncOutlined,
} from "@mui/icons-material";
import ConfigData from "../../utils/ConfigData";
import "../../configuration.scss";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../Redux/hooks";

const AddEditMetadonnes = () => {
  const { t } = useTranslation();

  const showDivCondition = useAppSelector(
    (state) => state.MetadonnesReducer.showDivsConditionsMetadonnes
  );

  const [items, SetItems] = useState(0);
  const [valuecheckbox, Setvaluecheckbox] = useState(false);
  const [valueSelect, SetValueSelect] = useState("");
  const [valueInput, SetValueInput] = useState("");
  const addRow = () => {
    SetItems(items + 1);
  };
  const deletRow = () => {
    if (items > 0) SetItems(items - 1);
  };

  const onChangecheckbox = (e: any) => {
    Setvaluecheckbox(e.target.checked);
  };
  const handleChangeSelect = (value: React.SetStateAction<string>) => {
    SetValueSelect(value);
  };
  const handleChangeInput = (e: any) => {
    SetValueInput(e.target.value);
  };

  {
  }
  const RowAddNewChamp = () => {
    return (
      <div>
        <div className="col_Type_champ">
          <div className="div_1_Type_champ">
            <FormControlLabel
              control={<Checkbox onChange={() => onChangecheckbox} />}
              label="Champ obligatoire"
            />

            <div className="div_btns_modifier_supprimer">
              <Button
                style={{ border: "1px solid #D8D8D8", marginLeft: "1%" }}
                className="btn_modifier"
                startIcon={<SyncOutlined style={{ color: "grey" }} />}
              >
                Modifier
              </Button>
              <Button
                style={{ border: "1px solid #D8D8D8", marginLeft: "1%" }}
                className="btn_Supprimer"
                onClick={deletRow}
                startIcon={<DeleteOutlineOutlined style={{ color: "grey" }} />}
              >
                Supprimer
              </Button>
            </div>
          </div>
          <div className="div_2_Type_champ">
            <FormControl sx={{ m: 1, minWidth: "22%" }}>
              <InputLabel
                style={{ width: "100px" }}
                id="demo-simple-select-disabled-label"
              >
                Type de champ
              </InputLabel>
              <Select
                onChange={() => handleChangeSelect}
                className="select-titre-champ"
                placeholder="Type de champ"
                label="Type de champ"
                defaultValue="Tous les médias"
              >
                <MenuItem value="jack">Jack</MenuItem>
                <MenuItem value="lucy">Lucy</MenuItem>
                <MenuItem value="tom">Tom</MenuItem>
              </Select>
            </FormControl>
            <TextField
              onChange={() => handleChangeInput}
              style={{ marginBottom: "1%" }}
              className="input-titre-champ"
              label="Titre du champs"
              placeholder="Veuillez saisir le titre de votre nouveau champ"
            />
          </div>
        </div>
      </div>
    );
  };
  const listItem = ConfigData.map((item) => (
    <div>
      <div className="col_ligne_texte">
        <div className="div_1_Type_champ">
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={item.checkbox}
                onChange={() => onChangecheckbox}
              />
            }
            label="Champ obligatoire"
          />
          <div className="div_btns_modifier_supprimer">
            <Button
              style={{ border: "1px solid #D8D8D8", marginLeft: "1%" }}
              className="btn_modifier"
              startIcon={<SyncOutlined style={{ color: "grey" }} />}
            >
              Modifier
            </Button>
            <Button
              style={{ border: "1px solid 	#D8D8D8", marginLeft: "1%" }}
              className="btn_Supprimer"
              onClick={deletRow}
              startIcon={<DeleteOutlineOutlined style={{ color: "grey" }} />}
            >
              Supprimer
            </Button>
          </div>
        </div>
        <div className="div_2_Type_champ">
          <FormControl sx={{ m: 1, minWidth: "22%" }}>
            <InputLabel
              style={{ width: "100px" }}
              id="demo-simple-select-disabled-label"
            >
              Type de champ
            </InputLabel>
            <Select
              className="select-titre-champ"
              placeholder="Type de champ"
              label="Type de champ"
            >
              <MenuItem>{item.select}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            className="input-titre-champ"
            style={{ marginBottom: "1%" }}
            placeholder="Veuillez saisir le titre de votre nouveau champ"
            value={item.input}
            label="Titre du champs"
          />
        </div>
      </div>
    </div>
  ));
  return (
    <>
      <Card style={{ marginLeft: "1%", marginRight: "1%" }}>
        <div>
          <div className="col_Type_media">
            <div className="div_Type_media">
              <FormControl sx={{ m: 1, minWidth: "100%" }}>
                <InputLabel
                  style={{ width: "100px", marginTop: "1%" }}
                  id="demo-simple-select-disabled-label"
                >
                  {t("metadonnes.type")}
                </InputLabel>
                <Select
                  className="select_div_Type_media"
                  placeholder="Tout les médias"
                  label="Tout les médias"
                >
                  <MenuItem value="jack">Jack</MenuItem>
                  <MenuItem value="lucy">Lucy</MenuItem>
                  <MenuItem value="tom">Tom</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div>
          <div className="col_nouveau_champ">
            <div className="div_nouveau_champ">
              <div className="div_span_champ">
                <span className="spn_nouveau_champ">Nouveau champ</span>
              </div>

              <div className="div_ajouter_champ">
                <div className="div_nombre_de_champ">
                  <span>Nombre de champs</span>
                  <span>:</span> <span>{items + 1}</span>
                </div>
                <Button
                  style={{
                    border: "1px solid #D8D8D8",
                    backgroundColor: "white",
                  }}
                  className="btn_ajouter"
                  startIcon={<AddBoxOutlined style={{ color: "grey" }} />}
                  onClick={addRow}
                >
                  Ajouter
                </Button>
              </div>
            </div>
          </div>
        </div>
        {[...Array(items)].map((_, i) => (
          <RowAddNewChamp key={i} />
        ))}

        {listItem}
      </Card>
    </>
  );
};

export default AddEditMetadonnes;
