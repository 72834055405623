import "../client.scss";

export const DataClient = () => {
    const columns :any= [
    ];
    return ({
        columns,
   
    })

}
