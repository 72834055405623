import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setAddServeurActions,
  setFilterServeurActions,
  setshowDivsConditionsServeur,
  setDataServeurActions,
  setPaginationPropsServeur,
  setDetailServeurActions,
  setLoading,
} from "../store/serveurActions";
import { Form, message } from "antd";
import { useAppSelector } from "../../../Redux/hooks";
import { GetData, PostData, DeleteData } from "../../../APIcalls";
import { useTranslation } from "react-i18next";
import {GraphQLFetchData} from "./GraphFetchData";
import { Delete_Server } from "./GraphQL";
import { Helpers } from "../../../utils/helpers";

const Hooks = () => {
    
  const {GetListServers} = GraphQLFetchData()
  const {acces_send} = Helpers()
  const {AddServer,GetOneServerInfo,UpdateServer,MultyDeleteServer, DeleteServer} = GraphQLFetchData()
  const { t } = useTranslation();
  const [visible, setVisible] = useState<any>(false);
  const [statusSuccessMessages, setStatusSuccessMessages] = useState<any>(true);
  const [statusErrorMessages, setStatusErrorMessages] = useState<any>(true);
  const dispatch = useDispatch();
  const [formAddServeur] = Form.useForm();

  const values = useAppSelector(
    (state) => state.ServeurReducer.addServeurConstraintsData
  );
  const listitemselected = useAppSelector(
    (state) => state.ServeurReducer.paginationPropsServeur.id
  );
  const ServerId = useAppSelector(
    (state) => state.ServeurReducer.showDivsConditionsServeur.elementSelected
  );

  useEffect(() => {
    formAddServeur.setFieldsValue({
      Protocol: values.Protocol,
      Type: values.Type,
      Internet: values.Internet,
      Record: values.Record,
      IsDisplay: values.Internet,
      IsBuffer: values.IsBuffer,
      Dir: values.Dir,
      UrlVideo: values.UrlVideo,
      UrlMp4: values.UrlMp4,
      UrlDiffusion: values.UrlDiffusion,
      UrlVodAndroid: values.UrlVodAndroid,
      UrlLiveAndroid: values.UrlLiveAndroid,
      UrlCdnAndroid: values.UrlCdnAndroid,
      UrlVodIos: values.UrlVodIos,
      UrlLiveIos: values.UrlLiveIos,
      UrlCdnIos: values.UrlCdnIos,
      DirLecture: values.DirLecture,
      UrlBackup: values.UrlBackup,
      UrlMobile: values.UrlMobile,
      UrlServerFtp: values.UrlServerFtp,
      LoginLive: values.LoginLive,
      Name: values.Name,
      Login: values.Login,
      Pwd: values.Pwd,
      PwdLive: values.PwdLive,
      Bitrate: values.Bitrate,
      ServerName: values.ServerName,
      ServerType: values.ServerType,
      Port: values.Port,
      SshKey: values.SshKey,
      StreamPlaylistLive: values.StreamPlaylistLive,
      Notes: values.Notes,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  ////*************** show Modal Function ******************/////////
  const handleShowModal = () => {
    setVisible(true)
  };
  const handleShow=()=>{
    setVisible(true)
  }
  const handleCancel = () => {
    setVisible(false);
  };

  /////////******* copy lien intégration */////////////
  const CopyUrlAuditeur = async () => {
    if (
      (document.getElementById("myUrlAuditeurServeur") as HTMLFormElement)
        .value === ""
    ) {
      await setStatusErrorMessages(false);
      return (
        statusErrorMessages &&
        message
          .error({ content: "Cet champ est Vide", duration: 2 })
          .then(async () => {
            setStatusErrorMessages(true);
          })
      );
    } else {
      (
        document.getElementById("myUrlAuditeurServeur") as HTMLFormElement
      ).select();
      document.execCommand("Copy");
      await setStatusSuccessMessages(false);
      statusSuccessMessages &&
        (await message
          .success({ content: "cet champ est copié avec succée", duration: 2 })
          .then(async () => {
            setStatusSuccessMessages(true);
          }));
    }
  };

  /////********* show add edit serveur ****************/////
  const handleAddServeurSubmit = () => {
    AddServer({variables : {input:values}})
    onResetServeur()
  }


  const handleAddServeur = (permission:string) => {
    acces_send(`${permission}_action`)
    formAddServeur.setFieldsValue({
      Protocol: "",
      Type: "",
      Internet: false,
      Record: false,
      IsDisplay: false,
      IsBuffer: false,
      Dir: "",
      UrlVideo: "",
      UrlMp4: "",
      UrlDiffusion: "",
      UrlVodAndroid: "",
      UrlLiveAndroid: "",
      UrlCdnAndroid: "",
      UrlVodIos: "",
      UrlLiveIos: "",
      UrlCdnIos: "",
      DirLecture: "",
      UrlBackup: "",
      UrlMobile: "",
      UrlServerFtp: "",
      LoginLive: "",
      Name: "",
      Login: "",
      Pwd: "",
      PwdLive: "",
      Bitrate: "",
      ServerName: "",
      ServerType: "",
      Port: 0,
      SshKey: "",
      StreamPlaylistLive: "",
      Notes: "",
    });
    dispatch(
      setshowDivsConditionsServeur({
        showDivsConditionsName: "addServeur",
        showDivsConditionsValue: true,
      })
    );


  };

  const onResetServeur = () => {
    /**edit item list */
    dispatch(
      setshowDivsConditionsServeur({
        showDivsConditionsName: "editServeur",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setshowDivsConditionsServeur({
        showDivsConditionsName: "addServeur",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "Type",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "ServerType",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "ServerName",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "Protocol",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "Internet",
        addServeurConstraintsDataValueChange: false,
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "Record",
        addServeurConstraintsDataValueChange: false,
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "Name",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "Login",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "Pwd",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "Dir",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "UrlVideo",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "UrlMp4",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "UrlDiffusion",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "UrlVodAndroid",
        addServeurConstraintsDataValueChange: "",
      })
    );
     dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "UrlLiveAndroid",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "UrlVodIos",
        addServeurConstraintsDataValueChange: "",
      })
    );

    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "UrlLiveIos",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "UrlCdnIos",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "DirLecture",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "UrlBackup",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "UrlMobile",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "UrlServerFtp",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "PwdLive",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "Bitrate",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "UrlCdnAndroid",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "LoginLive",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "Port",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "SshKey",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "StreamPlaylistLive",
        addServeurConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: "Notes",
        addServeurConstraintsDataValueChange: "",
      })
    );
  };

  ////****************** function Filter *****************///////
  //*********************** search header **************///////
  const handleSearchRow = (event: any) => {
      dispatch(
        setFilterServeurActions({
          FilterServeurNameChange: "search",
          FilterServeurValueChange: event.target.value,
        })
      );
  };
  const onChangeFolder = (e: any) => {
     dispatch(
      setFilterServeurActions({
        FilterServeurNameChange: "type",
        FilterServeurValueChange: e.target.value,
      })
    );
  };

  

  const disabledsubmitbutton = (type: any) => {
    if (type === "disabled") {
      dispatch(
        setshowDivsConditionsServeur({
          showDivsConditionsName: "disablesubmitform",
          showDivsConditionsValue: false,
        })
      );
    } else if (type === "notdisabled") {
      dispatch(
        setshowDivsConditionsServeur({
          showDivsConditionsName: "disablesubmitform",
          showDivsConditionsValue: true,
        })
      );
    }
  };
  ////////****** Edit Serveur ***************////
  const handleSubmitEditingServer =  () => {
     UpdateServer({variables : {input:{Id:ServerId , ...values}}})
      onResetServeur()
  }

  const GetOneServerInfoToEdit = (record:any) => {
        dispatch(
          setshowDivsConditionsServeur({
            showDivsConditionsName: "editServeur",
            showDivsConditionsValue: true,
          })
        );
         dispatch(
          setshowDivsConditionsServeur({
            showDivsConditionsName: "elementSelected",
            showDivsConditionsValue: record.Id,
          })
        );

         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "Type",
            addServeurConstraintsDataValueChange: record.Type,
          })
        );

         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "ServerType",
            addServeurConstraintsDataValueChange: record.ServerType,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "ServerName",
            addServeurConstraintsDataValueChange: record.ServerName,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "Protocol",
            addServeurConstraintsDataValueChange: record.Protocol,
          })
        );

         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "Internet",
            addServeurConstraintsDataValueChange: record.Internet,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "Record",
            addServeurConstraintsDataValueChange: record.Record,
          })
        );

         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "Name",
            addServeurConstraintsDataValueChange: record.Name,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "Login",
            addServeurConstraintsDataValueChange: record.Login,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "Pwd",
            addServeurConstraintsDataValueChange: record.Pwd,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "Dir",
            addServeurConstraintsDataValueChange: record.Dir,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "UrlVideo",
            addServeurConstraintsDataValueChange: record.UrlVideo,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "UrlMp4",
            addServeurConstraintsDataValueChange: record.UrlMp4,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "UrlDiffusion",
            addServeurConstraintsDataValueChange: record.UrlDiffusion,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "UrlVodAndroid",
            addServeurConstraintsDataValueChange: record.UrlVodAndroid,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "UrlLiveAndroid",
            addServeurConstraintsDataValueChange: record.UrlLiveAndroid,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "UrlCdnAndroid",
            addServeurConstraintsDataValueChange: record.UrlCdnAndroid,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "UrlVodIos",
            addServeurConstraintsDataValueChange: record.UrlVodIos,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "UrlLiveIos",
            addServeurConstraintsDataValueChange: record.UrlLiveIos,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "UrlCdnIos",
            addServeurConstraintsDataValueChange: record.UrlCdnIos,
          })
        );

         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "DirLecture",
            addServeurConstraintsDataValueChange: record.DirLecture,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "UrlBackup",
            addServeurConstraintsDataValueChange: record.UrlBackup,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "UrlMobile",
            addServeurConstraintsDataValueChange: record.UrlMobile,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "UrlServerFtp",
            addServeurConstraintsDataValueChange: record.UrlServerFtp,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "LoginLive",
            addServeurConstraintsDataValueChange: record.LoginLive,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "PwdLive",
            addServeurConstraintsDataValueChange: record.PwdLive,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "Bitrate",
            addServeurConstraintsDataValueChange: record.Bitrate,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "Port",
            addServeurConstraintsDataValueChange: record.Port,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "SshKey",
            addServeurConstraintsDataValueChange: record.SshKey,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "StreamPlaylistLive",
            addServeurConstraintsDataValueChange: record.StreamPlaylistLive,
          })
        );
         dispatch(
          setAddServeurActions({
            addServeurConstraintsDataNameChange: "Notes",
            addServeurConstraintsDataValueChange: record.Notes,
          })
        );
  };

  /********************************** */
  const handelSearch = (word: string, tab: any) => {
    if (word.trim() === "") return tab;

    // eslint-disable-next-line array-callback-return
    const res = tab.filter((obj: any) => {
      if (obj.Type.toLowerCase().includes(word.toLowerCase())) return obj;
    });

    return res;
  };
  const handelFilter = (type: any, tab: any) => {
    function smallval(item: any) {
      if (type === "All") {
        return item;
      } else if (type !== "") {
        return item.Type === type;
      } else {
        return item;
      }
    }

    const res = tab.filter(smallval);

    return res;
  };
  /*******************Crud *********************** */
  
  const Deleteselecteditem = (Id:any) => {
    DeleteServer({variables : {id:Id}})
  };
 
  const Deletemultipleselecteditem = async (array:any) => {
    MultyDeleteServer({variables : {id:array}})
  };
  

  return {
    handleAddServeurSubmit,
    handleAddServeur,
    onResetServeur,
    formAddServeur,
    handleSearchRow,
    onChangeFolder,
    handleShowModal,
    visible,
    handleCancel,
    CopyUrlAuditeur,
    GetOneServerInfoToEdit,
    handleSubmitEditingServer,
    values,
    handleShow,
    handelSearch,
    handelFilter,
    Deletemultipleselecteditem,
    disabledsubmitbutton,
    Deleteselecteditem
  };
};
export default Hooks;
