import { useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { Get_All_Clients , Get_One_Client_Info,Upadte_Client,Add_Client,Delete_Client,Search_Client_By_Type,multiDeleteClient} from "./GraphQL";
import {setDataClientActions,setDetailClientActions,setshowDivsConditionsClient,setLoader,setDeleteLoader, Set_totlaEn} from "../store/clientActions"
export const GraphQLFetchData = () => {
  
    const { t } = useTranslation();
    const dispatch = useDispatch();
  
  const [GetListClients] = useMutation(
    Get_All_Clients,
    {
      fetchPolicy: "no-cache",
      variables: {},
      onCompleted: (data) => {
      
        dispatch(
            setDataClientActions(data?.Params?.edges)
        );
        dispatch(
          Set_totlaEn(data.Params.totalCount)
        )
      },
      onError:(error)=>{
        message.error({ content: t('messageerroroperation'), duration: 2 })
      }
    }
  );
  const useGetOneClientInfoToEdit = (handle:any)=>{
    const [GetOneClientInfoToEdit] = useMutation(
      Get_One_Client_Info,
      {
        fetchPolicy: "no-cache",
        variables: {},
        onCompleted: (data) => {
          dispatch(setDetailClientActions(data?.Params?.edges[0]?.node))
          handle(data)
        },
        onError:(error)=>{
          message.error({ content: t('messageerroroperation'), duration: 2 })
        }
      }
    ) ;
    return GetOneClientInfoToEdit  
  }
    const [GetOneClientInfo] = useMutation(
      Get_One_Client_Info,
      {
        fetchPolicy: "no-cache",
        variables: {},
        onCompleted: (data) => {
          dispatch(setDetailClientActions(data?.Params?.edges[0]?.node))
        },
        onError:(error)=>{
          message.error({ content: t('messageerroroperation'), duration: 2 })
        }
      }
    );
    const [UpdateClient] = useMutation(
        Upadte_Client,
        {
          fetchPolicy: "no-cache",
          variables: {},
          onCompleted: (data) => {
            dispatch(setLoader(false))
            dispatch(
              setshowDivsConditionsClient({
                showDivsConditionsName: "editClient",
                showDivsConditionsValue: false,
              })
            );
            message.success({ content: t('successedit'), duration: 2 })  
          },
          onError:(error)=>{
            message.error({ content: t('messageerroroperation'), duration: 2 })
          }
        }
      );
      const [AddClient] = useMutation(
        Add_Client,
        {
          fetchPolicy: "no-cache",
          variables: {},
          onCompleted: (data) => {
            if(data.createParam.Id.length) {
            dispatch(setLoader(false))
            dispatch(
              setshowDivsConditionsClient({
                showDivsConditionsName: "addClient",
                showDivsConditionsValue: false,
              })
            );
          }
          else {
            dispatch(setLoader(false))
            message.success({ content: t('successadd'), duration: 2 })  

          }
          },
          onError:(error)=>{
            dispatch(setLoader(false))
            message.error({ content: t('messageerroroperation'), duration: 2 })
          }
        }
      );

      const [multiDelete]= useMutation(
        multiDeleteClient,
        {
          fetchPolicy:"no-cache",
          variables:{},
          onCompleted:(data)=>{
            if(data.DeleteParams.code == 200) {
              dispatch(setDeleteLoader(false))
              GetListClients({variables:{pagination:{offset:0,limit:0}}})
            }
          },
          onError:(error)=>{
            message.error({ content: "failed", duration: 2 })
          }
        }
      )
      const [DeleteClient] = useMutation(
        Delete_Client,
        {
          fetchPolicy: "no-cache",
          variables: {},
          onCompleted: (data) => {
            dispatch(setDeleteLoader(false))
            GetListClients({variables:{pagination:{offset:0,limit:0}}})
            message.success({ content: t("successdelete"), duration: 2 });
          },
          onError:(error)=>{
            message.error({ content: t('messageerroroperation'), duration: 2 })
          }
        }
      );
      const [SearchByKeyWordClient] = useMutation(
        Search_Client_By_Type,
        {
          fetchPolicy: "no-cache",
          variables: {},
          onCompleted: (data) => {
            dispatch(
              setDataClientActions(data?.Params?.edges)
            );
            dispatch(Set_totlaEn(data?.Params?.totalCount));
          },
          onError:(error)=>{
            message.error({ content: t('messageerroroperation'), duration: 2 })
          }
        }
      
      )

  return {
      GetListClients,
      useGetOneClientInfoToEdit,
      GetOneClientInfo,
      UpdateClient,
      AddClient,
      DeleteClient,
      multiDelete,
      SearchByKeyWordClient
    };
  }