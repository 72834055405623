
export const VideoplayerList = () => {

    const FilterVideoplayer = () => {
        return (
            {
                search: "",
                searchFake: "",
            }
        )
    }

    const paginationPropsVideoplayer = () => {
        return (
            {
                order: 'descend',
                pageSize: 10,
                columnKey: 0,
                current: 1,
                id: [],
                idLive: 0,
            }
        )
    }

    const showDivsConditionsVideoplayer = () => {
        return ({
            elementSelected: 0,
            addVideoplayer: false,
            editVideoplayer: false
        })
    }

    const AddVideoplayerConstraintsData = () => {
        return (
            {
                id: "",
                type: "",
                value: "",
             
            }
        )
    }

    const DataVideoplayer= () =>{
        return []
    }
    const DetailVideoplayer= () =>{
        return []
    }

    return ({
        paginationPropsVideoplayer,
        showDivsConditionsVideoplayer,
        AddVideoplayerConstraintsData,
        FilterVideoplayer,
        DataVideoplayer,
        DetailVideoplayer
        
    })
}
