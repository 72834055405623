import React from 'react'
import {Link ,Breadcrumbs } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../Redux/hooks";
import Hooks from "../utils/hooks";
import "../videoshome.scss";
const VideoshomeHeader = () => {
    const { t } = useTranslation();
    const { values} = Hooks()

    return (
        <div>
            <div className={"add_div"}>

            <div>
           <span className={"profile_title_conf"}>{t('configuration.homePage.Videoshome')}</span>
           </div>
           
          </div>


            
        </div>
    )
}

export default VideoshomeHeader
