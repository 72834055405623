import {ActualityList} from "./ActualityConstants";

const {paginationPropsActuality,showDivsConditionsActuality,AddActualityConstraintsData , FilterActuality}=ActualityList()

const ActualityListINITIALSTATE = {
    showDivsConditionsActuality:showDivsConditionsActuality(),
    paginationPropsActuality:paginationPropsActuality(),
    addActualityConstraintsData:AddActualityConstraintsData(),
    filterActuality:FilterActuality()
}

export const ActualityReducer = (state=ActualityListINITIALSTATE , action:any)=>{

    switch (action.type){

        case "SET_FilterActuality" :
            const {FilterActualityNameChange, FilterActualityValueChange} = action.payload
            return {
                ...state,
                filterActuality: {...state.filterActuality, [FilterActualityNameChange]: FilterActualityValueChange}
            }
        case "SET_PaginationPropsActuality" :
            const {PaginationPropsNameChange, PaginationPropsValueChange} = action.payload
            return {
                ...state,
                paginationPropsActuality: {...state.paginationPropsActuality, [PaginationPropsNameChange]: PaginationPropsValueChange}
            }
        case "SET_showDivsConditionsActuality":
            const {showDivsConditionsName,showDivsConditionsValue,record}=action.payload
            const showDivsConditionsObj = {...state.showDivsConditionsActuality,[showDivsConditionsName]: showDivsConditionsValue,elementSelected:record}
            return{
                ...state,
                showDivsConditionsActuality:showDivsConditionsObj
            }
        case "SET_AddActuality" :
            const {addActualityConstraintsDataNameChange,addActualityConstraintsDataValueChange}=action.payload
            return {
                ...state,
                addActualityConstraintsData:{...state.addActualityConstraintsData, [addActualityConstraintsDataNameChange]:addActualityConstraintsDataValueChange}
            }
        default: {
            return state
        }

    }

}

