import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import '../Actuality.scss'

export const DataActuality = () => {
  const { t } = useTranslation();


/** End sortedBy */

  const columns = [
    {
      headerName: <label className="titlecolumn">Id</label>,
      field: "id",
      key: 1,
     /* sorter: (a: any, b: any) => SortedById(a,b),
      sortDirections: ["descend", "ascend"],
      //sortOrder:paginationProps.columnKey === "2" &&  paginationProps.order,*/
      width: 200,
    },
    {
      headerName: <label className="titlecolumn">Actualité</label>,
      field: "content",
      key: 2,
      /*sorter: (a: any, b: any) => {
        const A=a.content.toLowerCase().trim()
        const B=b.content.toLowerCase().trim()
        return  A > B ? 1:-1},
        sortDirections: ["descend", "ascend", "descend"],
        //sortOrder:paginationProps.columnKey === "2" &&  paginationProps.order,*/
        width: 1000,
      },
      {
        headerName:  <span className="titlecolumn">Date</span>,
        field: "date",
        key: 3,
        /*sorter: (a: any, b: any) => SortedByDate(a,b),
        sortDirections: ["descend", "ascend", "descend"],
        //sortOrder:paginationProps.columnKey === "3" &&  paginationProps.order,*/
        width: 290,
  
     
    },
  ];

  const data = {
    totalElements: 22,
    content: [
      {
        id: 1,
        key: "1",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "42",
        canaux: "Scene",
        index: 0,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 1",
        date: "1/01/2022",
      },
      {
        id: 2,
        key: "2",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 1,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 2",
        date: "2/01/2022",
      },
      {
        id: 3,
        key: "3",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 2,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 3",
        date: "3/01/2022",
      },
      {
        id: 4,
        key: "4",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 3,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 4",
        date: "4/01/2022",
      },
      {
        id: 5,
        key: "5",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 4,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 5",
        date: "5/01/2022",
      },
      {
        id:6,
        key: "6",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 5,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 6",
        date: "6/01/2022",
      },
      {
        id: 7,
        key: "7",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 6,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 7",
        date: "7/01/2022",
      },
      {
        id: 8,
        key: "8",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 7,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 8",
        date: "8/01/2022",
      },
      {
        id: 9,
        key: "9",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 8,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 9",
        date: "9/01/2022",
      },
      {
        id: 10,
        key: "10",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 9,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 10",
        date: "10/01/2022",
      },
      {
        id: 11,
        key: "11",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 10,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 11",
        date: "11/01/2022",
      },
      {
        id: 12,
        key: "12",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 11,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 12",
        date: "12/01/2022",
      },
      {
        id: 13,
        key: "13",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 12,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 13",
        date: "13/01/2022",
      },
      {
        id: 14,
        key: "14",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 13,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 14",
        date: "14/01/2022",
      },
      {
        id: 15,
        key: "15",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 14,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 15",
        date: "15/01/2022",
      },
      {
        id: 16,
        key: "16",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 15,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "Actuality 16",
        date: "6/01/2022",
      },
      {
        id: 17,
        key: "17",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 16,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 17",
        date: "17/01/2022",
      },
      {
        id: 18,
        key: "18",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 17,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 18",
        date: "18/01/2022",
      },
      {
        id: 19,
        key: "19",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 18,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 19",
        date: "19/01/2022",
      },
      {
        id: 20,
        key: "20",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 19,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: " Actuality 20",
        date: "20/01/2022",
      },
      {
        id: 21,
        key: "21",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 20,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 21",
        date: "21/01/2022",
      },
      {
        id: 22,
        key: "22",
        aperçu:
          "https://www.akamai.com/content/dam/site/im-demo/perceptual-standard.jpg?imbypass=true",
        title: "Sunt autem nusquam hoc epicurus.",
        ActualityParent: "Windy",
        canaux: "Scene",
        index: 21,
        Identifiant: "Robert",
        alias: "McmahonJagger",
        content: "Buy fresh produce at the farmers’ market",
        //Répertoire: "Key",
        //sousRépertoire:: "Pear",
        Actualite: "new Actuality 22",
        date: "22/01/2022",
      },
    ],
  };
  const dataContent = data.content;

  return {
    columns,
    data,
    dataContent,
  };
};
