import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Tooltip, Button } from "@mui/material";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import SearchIcon from "@material-ui/icons/Search";

import { setFilterMoocActions } from "../../store/MoocActions";
import { DataMooc } from "../../utils/Data";
import useDataTableMedia from "./DataTable";
import { Hooks } from "../../utils/hooks";
import { useAppSelector } from "../../../../Redux/hooks";
import { FormControl, InputAdornment, TextField } from "@material-ui/core";

const MoocTable = () => {
  const { columns, data } = DataMooc();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const valuePagination = useAppSelector(
    (state) => state.MoocReducer.paginationPropsMooc
  );

  const { handleSearchRow, valuesSearch } = Hooks();

  const { DataTable } = useDataTableMedia({
    columns: columns,
    dataSource: data,
    updateEntityPath: "update-product",
  });

  return (
    <div>
      {/*./Filter*/}
      <div className="Filter">
        {valuePagination.id.length === 0 ? null : (
          <div className="delete_number">
            <Tooltip title="Supprimer">
              <Button
                style={{ border: "1px solid rgba(0 , 0 , 0 , 0.15)" }}
                startIcon={<DeleteOutlineOutlined className="icon" />}
              />
            </Tooltip>
            <p>
              <span>{valuePagination.id.length || ""}</span>
              <span id={"text_selection"}>{t("Profile.selectedItem")}</span>
            </p>
          </div>
        )}

        <div className={"div_rect rect"}>
          <div className="rect_filter">
            <FormControl
              className="search"
              style={{ width: "99.5%", marginLeft: "1%" }}
            >
              <TextField
                variant="outlined"
                placeholder={t("Configuration.search")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        onClick={(e) =>
                          handleSearchRow({
                            key: "clicked",
                            value: valuesSearch.searchFake,
                          })
                        }
                        style={{
                          color: "rgba(0, 0, 0, 0.25)",
                          marginLeft: "10px",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                name={"search"}
                onKeyDown={(e) => handleSearchRow(e)}
                onChange={(e) =>
                  dispatch(
                    setFilterMoocActions({
                      FilterMoocNameChange: "searchFake",
                      FilterMoocValueChange: e.target.value,
                    })
                  )
                }
                value={valuesSearch.searchFake}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className={"dataTable"}>
        <DataTable />
      </div>
    </div>
  );
};
export default MoocTable;
