import React from 'react';
import { useDispatch } from 'react-redux';
import { setOpenModal } from '../store/videoshomeActions';
import { useAppSelector } from '../../../../Redux/hooks';
import { useTranslation } from 'react-i18next';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

type VideoModalProps = {
  isOpen: boolean;
  videoUrl: string;
  onClose: () => void;
};

export const VideoModal: React.FC<VideoModalProps> = ({ isOpen, videoUrl, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleClose = () => {
    dispatch(setOpenModal(false));
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className={isOpen ? "modal":"closeModal"}>
          <div className="modal-background" onClick={handleClose}></div>
          <div className="modal-content">
            <div className="video-container">
              <iframe  id="iframe-player" src={videoUrl} allowFullScreen></iframe>
            </div>
                <CloseOutlinedIcon className="modal-close" aria-label="close" onClick={handleClose}/>

          </div>
        </div>
      )}
    </>
  );
};