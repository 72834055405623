import { TYPES } from "./types";

const initState = {
  userData: {},
  roles: [],
  unauthorizedUser: true,
  loading_screen: true,
  modules: {
    configuration_server_module: {
      configuration_server_post_add_action: { authorized: false },
    },
    configuration_presets_module: {
      configuration_presets_post_add_action: { authorized: false },
    },
    param_module: {
      param_post_add_action: { authorized: false },
    },
  },
  isSuperAdmin: false,
};

const ReducerUserData = (state = initState, action) => {
  switch (action.type) {
    case TYPES.GET_USER_DATA:
      return {
        ...state,
        userData: action.payload.userData.user,
        roles: action.payload.userData.roles,
        modules: action.payload.userData.modules,
        unauthorizedUser: false,
        loading_screen: false,
      };
    case TYPES.ON_LOADING_SCREEN:
      return {
        ...state,
        loading_screen: action.payload,
      };
    case TYPES.UNAUTHORIZED:
      return {
        ...state,
        unauthorizedUser: true,
        loading_screen: false,
        isSuperAdmin: false
      };
    case TYPES.IS_SUPER_ADMIN:
      return {
        ...state,
        isSuperAdmin: true,
      };
    default:
      return state;
  }
};
export default ReducerUserData;
