import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { useAppSelector } from "../../../../Redux/hooks";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import "../../serveur.scss";
import DataGridTable from "./DataTable";
import { SearchBar} from '@empreinte/components.search-bar';
import { SelectList } from '@empreinte/components.select-list'
import { ModalConfirm } from '@empreinte/components.modal-confirm';
import { Button } from '@empreinte/components.button'
import { ClipLoader } from '@empreinte/components.clip-loader';
import { GraphQLFetchData } from "../../utils/GraphFetchData";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";


const ServeurTable = ({setSearchText, searchText, setCurrentPage,currentPage,setPageSize,pageSize,valueFilter,setValueFilter,deleteMultiple, setDeleteMultiple}:any) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const types = useAppSelector((state) => state.ServeurReducer.Types)
  const someSelectedToDelet = useAppSelector(
    (state) => state.ServeurReducer.showDivsConditionsServeur.elementsSelected
  );
  const loadData = useAppSelector((state) => state.ServeurReducer.loading)
  const {  Deletemultipleselecteditem } = Hooks();
  const {GetTypes} = GraphQLFetchData()
  const { configuration_server_module } = useAppSelector(
    (state) => state.ReducerUserData.modules
  );
  const isSuperAdmin = useAppSelector((state: any) => state.ReducerUserData.isSuperAdmin)

    useEffect(()=>{
      setValueFilter({ label: `${t("Serveur.all-groups")}`, value:`${t("Serveur.all-groups")}` })
    },[])
 const handleSearch = (e: any) => {
  setCurrentPage(1)
  setSearchText(e.target.value)
 }
  const delet = t("Serveur.delete");


  const handleClose = () => {
    setOpen(false)
  }
  const serverTypesSet = new Set();
  const uniqueServerTypes = types.filter((edge: any) => {
    if (!serverTypesSet.has(edge.node.Type)) {
      serverTypesSet.add(edge.node.Type);
      return true;
    }
    return false;
  });

const ServerTypes = [
  { label: `${t("Serveur.all-groups")}`, value:`${t("Serveur.all-groups")}` }, 
  ...uniqueServerTypes.map((edge: any) => ({
    label: edge.node.Type,
    value: edge.node.Type
  }))
];
useEffect(() => {
  GetTypes({
    variables: {
      pagination: { limit:0, offset: 0},
    },
  })
}, []);

  const handleDeleteModal = () => {
    setCurrentPage(1)
    Deletemultipleselecteditem(someSelectedToDelet);
    setDeleteMultiple(false)
  }
  const handleSelectChange = (e: any) => {
    setCurrentPage(1)
    const filtredType = ServerTypes.filter((item:any) => e.target.value==item.value)
    if(filtredType && filtredType.length){
      setValueFilter(filtredType[0])
    }
  }
  if(loadData) {
    return (
        <Dialog
             open={open}
             onClose={handleClose}
             aria-labelledby="alert-dialog-title"
             aria-describedby="alert-dialog-description"
           >
            <DialogContent sx={{height: '50%'}}>
              <ClipLoader color='inherit' thickness={1.6} style={{marginTop: '6px'}} />
           </DialogContent>
           </Dialog>

    )
  }



  return (
    <div>
      <div className="main-page-server">
          <>
            
              <ModalConfirm 
              className="config-server-modal"
              open={deleteMultiple}
              fullWidth
              onClose={()=>setDeleteMultiple(false)}
              title={t("DeleteConfirmation")}
              content={t("DeleteConfirmationContentM")}
              CancelBtnName={t("No")}
              onCancel={()=>setDeleteMultiple(false)}
              ConfirmBtnName={t("Yes")}
              onConfirm={handleDeleteModal}
              />
            {someSelectedToDelet.length >= 1 ? (
              <>
              <Tooltip title={delet}>
                <Button 
                className="btn-server-multidelete"
                style={{display: "flex", alignItems:"center", color:"black", borderRadius: "90% 90% 90% 90%"}}
                startIcon={<DeleteIcon />}
                onClick={() => setDeleteMultiple(true)}
                />
              </Tooltip>
              </>
            ): null}
              
            
          </>
          <div className="shadowShouldBeADDED spesifiedSelectAdjust">

          <Box sx={{ minWidth: 250 }}>
              <FormControl fullWidth>
                <SelectList 
                  name="type"
                  value={valueFilter.value.length > 0 ? valueFilter.value : `${t("Serveur.all-groups")}`}
                  className="selection-list-server-main"
                  data={ServerTypes}
                  handleChange={handleSelectChange}
                />
              </FormControl>
          </Box>
          </div>
          <div className="shadowShouldBeADDED widthFullForPodcastSearch">

          <SearchBar 
          className="search-bar-server-main"
          name="search"
          placeholder={t("Serveur.search")}
          setSearchText={handleSearch}
          value={searchText}
          />
  </div>
      </div>

      {(configuration_server_module?.configuration_server_list_dtable_action
        ?.authorized || isSuperAdmin) && (
        <div
          has-permission="configuration_server_list_dtable"
          className={"dataTable"}
        >
          <DataGridTable 
          searchText={searchText}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          />
        </div>
      )}
    </div>
  );
};

export default ServeurTable;