import {
  AddBoxOutlined,
  HomeOutlined,
  Close,
  CheckCircleOutlineOutlined,
  ArrowBack,
} from "@mui/icons-material";
import { Button, Link, Breadcrumbs } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../Redux/hooks";
import Hooks from "../utils/hooks";
import "../referent.scss";

const ReferentHeader = () => {
  const { t } = useTranslation();
  const { handleAddReferent, onResetReferent } = Hooks();
  const showDivCondition = useAppSelector(
    (state) => state.ReferentReducer.showDivsConditionsReferent
  );

  return (
    <div>
      <div className={"add_div"}>
        <div>
          {!showDivCondition.addReferent && !showDivCondition.editReferent ? (
            <span className={"profile_title"}>{t("Referent.referent")}</span>
          ) : showDivCondition.addReferent ? (
            <>
              <ArrowBack className="arrowleft" onClick={onResetReferent} />
              <span className={"profile_title"}>
                {t("Referent.addreferent")}
              </span>
            </>
          ) : showDivCondition.editReferent ? (
            <>
              {" "}
              <ArrowBack className="arrowleft" onClick={onResetReferent} />
              <span className={"profile_title"}>
                {t("Referent.modifreferent")}
              </span>
            </>
          ) : (
            ""
          )}
        </div>

        <div className={"div_btn_add_cancel"}>
          {!showDivCondition.addReferent && !showDivCondition.editReferent ? (
            <Button
              className={"btnSubmit"}
              startIcon={<AddBoxOutlined style={{ color: "white" }} />}
              style={{ backgroundColor: "#1890FF" }}
              onClick={handleAddReferent}
            >
              <span style={{ color: "white" }} className={"spn_add"}>
                {t("Referent.add")}
              </span>
            </Button>
          ) : (
            <div className={"div_btn_add_cancel"}>
              <Button
                className={"btnCancel"}
                startIcon={<Close style={{ color: "#1890FF" }} />}
                name={"cancel"}
                onClick={onResetReferent}
                style={{
                  backgroundColor: "white",
                  border: "solid 2px #1976D2",
                }}
              >
                <span className={"spn_cancel"}>{t("Referent.cancel")}</span>
              </Button>

              <Button
                style={{ backgroundColor: "#1890FF" }}
                className={"btnSubmit"}
                startIcon={
                  <CheckCircleOutlineOutlined style={{ color: "white" }} />
                }
                name={"submit"}
              >
                <span style={{ color: "white" }} className={"spn_add"}>
                  {t("Referent.validate")}
                </span>
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className={"Breadcrumb"}>
        <Breadcrumbs>
          <Link underline="none" color="inherit" href="">
            <span>
              <HomeOutlined className={"home_icon"} />
            </span>
          </Link>
          <Link underline="none" color="inherit" href="">
            {t("Referent.configuration")}
          </Link>
          <Link underline="none" color="inherit" href="">
            {t("Referent.referent")}
          </Link>
          {showDivCondition.addReferent ? (
            <Link underline="none" color="inherit" href="">
              {t("Referent.addreferent")}
            </Link>
          ) : showDivCondition.editReferent ? (
            <Link underline="none" color="inherit" href="">
              {t("Referent.modifreferent")}
            </Link>
          ) : (
            ""
          )}
        </Breadcrumbs>
      </div>
    </div>
  );
};

export default ReferentHeader;
