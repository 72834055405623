
export function setPaginationPropsServeur(e:any){
    return{
        type:"SET_PaginationPropsServeur",
        payload:e,
    }
}

export function setshowDivsConditionsServeur(e:any){
    return {
        type : "SET_showDivsConditionsServeur",
        payload:e
    }
}

export function setAddServeurActions(e:any){
    return{
        type:"SET_AddServeurActions",
        payload:e,
    }
}

export function setFilterServeurActions(e:any){

    return{
        type:"SET_FilterServeur",
        payload:e,
    }

}

export function setEditServeurActions(e:any){
    return{
        type:"SET_EditServeurActions",
        payload:e,
    }
}

export function setDataServeurActions(e:any){
    return{
        type:"SET_DataServeurActions",
        payload:e,
    }
}

export function setDetailServeurActions(e:any){
    return{
        type:"SET_DetailServeurActions",
        payload:e,
    }
}

export function setSearchResults(data: any) {
    return {
        type: "SET_SEARCH_RESULT",
        payload: data
    }
}

export function setLoading(payload: any) {
    return {
        type: "SET_LOADING_DATA",
        payload
    }
}

export function getTypes(data:any){
    return {
        type: "GET_TYPES",
        payload: data
    }
}