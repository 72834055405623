import { useEffect } from "react";
import ClientHeader from "./components/clientHeader";
import ClientTable from "./components/List/clientTable";
import AddEditClient from "./components/AddEdit/addEdit";
import "./client.scss";
import "./../serveur/serveur.scss";
import { useAppSelector, useAppDispatch } from "../../Redux/hooks";
import Hooks from "./utils/hooks";
import { Grid } from "@material-ui/core";
import { getUserData } from "../../userData/actions";
import {GraphQLFetchData} from "./utils/GraphFetchData";
import {Loader} from "@empreinte/components.loader"
import LoadingScreen from "./../serveur/utils/LoadingScreen";
const Client = () => {
  const dispatch = useAppDispatch();
  const {GetListClients} = GraphQLFetchData()
  const {
    formAddClient,
  } = Hooks();
  const showDivCondition = useAppSelector(
    (state:any) => state.ClientReducer.showDivsConditionsClient
  );
  const rightsLoader = useAppSelector((state:any)=>state.ClientReducer.rightsLoader)
  const deleteModalState = useAppSelector((state:any)=>state.ClientReducer.deleteModalState)
  const values = useAppSelector(
    (state:any) => state.ClientReducer.addClientConstraintsData
  );
  useEffect(() => {
    dispatch(getUserData());
  }, []);
  useEffect(() => {
 
    if (showDivCondition.editClient) {
      formAddClient.setFieldsValue({
        Id: values.Id,
        Cle: values.Cle,
        Value: values.Value,
        CreatedAt: values.CreatedAt,
        UpdatedAt: values.UpdatedAt,
      });
    }

    if (showDivCondition.addClient) {
      formAddClient.setFieldsValue({
        Id: "",
        Cle: "",
        Value: "",
        CreatedAt: "",
        UpdatedAt: "",
      });
    }
  }, [showDivCondition.editClient, showDivCondition.addClient]);
  if(rightsLoader === true) {
    return (
      <LoadingScreen/>
    )

  }
  
  return (
    <div className={"divGlobalTable"}>
      <Loader isOpen={deleteModalState} /> 

      {!showDivCondition.addClient && !showDivCondition.editClient ? (
        <  >
          <ClientHeader />
          <ClientTable />
        </>
      ) : (
        <div>
          <Grid>
            <ClientHeader />
            <AddEditClient />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Client;
