import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Hooks from "./hooks";
import '../videoshome.scss'
const DragHandle = SortableHandle(() => <DragIndicatorIcon style={{ cursor: 'grab', color: '#999' , fontSize:"20px", fontWeight:"700" , marginLeft:'25px'}} />);

export const DataVideoshome = () => {

    const { t } = useTranslation();
    const {getVideoFile} = Hooks()

    const handleClick= (id: any) =>{
        getVideoFile(id)
    }
    const columns = [
        {
            headerName: 'ID',
            field: 'sort',
            sortable: false,
            key: 0,
            width:50,
            flex: 1,
            renderCell:(record:any)=>{
                return (
                    <span>{record.id}</span>
                )
            }
        },
        {
            headerName: t('configuration.Videoshome.url'),
            field: 'url',
            key: 1,
            width:150,
            flex: 1,
            sortable: false,
          
            renderCell: (record: any) => {
                return (
                <div  className="containerImg" onClick={() => {
                    handleClick(record?.id)}}>
                <img
                className="imgVideo"
                src={record?.row?.logo}
                alt="pic"
                style={{ width: "93px", height: "100%", cursor: 'pointer',minWidth:"70px" }}
                
                />
                <img 
                className="overlay"
                src="/overlay.png"/>
                </div>


                )
            },
          
        },
        {
            headerName: t('configuration.Videoshome.title'),
            field: 'title',
            key: 2,
            width:180,
            flex:1,
            sortable: false,
            renderCell:(record:any)=>{
                return (
                    <span>{record?.row?.title}</span>
                )
            }
        },
        {
            headerName: t('configuration.Videoshome.theme'),
            field: 'themes',
            key: 3,
            width:180,
            flex:1,
            sortable: false,
            renderCell:(record:any)=>{
                return (
                  <div className="theme_classe">
                    {record?.row?.video?.themes?.map((theme: any) => (
                      <span key={theme?.id}>{theme?.title}</span>
                    ))}
                  </div>
                );
            }
            
        },
        {
            headerName: t('configuration.Videoshome.canal'),
            field: 'canals',
            key: 4,
            width:180,
            flex:1,
            sortable: false,
            renderCell:(record:any)=>{
                return (
                    <div>
                        {record?.row?.themes?.map((theme: any) => (
                            <div className="channelClass" key={theme?.id}>
                           
                                {theme?.channels?.map((channel: any) => (
                                  <span key={channel.id}>{channel.title}</span>
                                    
                                ))}
                            </div>
                        ))}
                    </div>
                )
            }
            
        },

    ];
    const data = {
        totalElements: 22,
        content: [
            {
                id: '1',
                url: "https://www.youtube.com/watch?v=o99PjtSuGuc",
                title: "test.",
                theme: "Scene",
                canal: "Robert",


                index: 0,

            },
            {
                id: '2',
                url: "https://www.youtube.com/watch?v=o99PjtSuGuc",
                title: "PlaybackRates",
                theme: "Scene",
                canal: "Robert",
                index: 1,

            },
            {
                id: '3',
                url: "https://www.youtube.com/watch?v=o99pjtsuguc",
                title: "Social_media",
                theme: "Scene",
                canal: "Robert",
                index: 2,

            },
            {
                id: '4',
                url: "https://www.youtube.com/watch?v=o99pjtsuguc",
                title: "ShowMbrList",
                theme: "Scene",
                canal: "Robert",
                index: 3,
            },
            {
                id: '5',
                url: "https://www.youtube.com/watch?v=o99pjtsuguc",
                title: "Export_right",
                theme: "Scene",
                canal: "Robert",
                index: 4,
            }
           

        ]
    }
    const dataContent = data.content
    const ListType = ['All', 'ShowMbrList', 'social_media', 'fbAppId', 'export_right', 'stats_interval', 'mail_right']

    return ({
        columns,
        data,
        dataContent,
        ListType
    })

}


// {
//     id: '6',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "Social_media",
//     theme: "Scene",
//     canal: "Robert",
//     index: 5,
// },
// {
//     id: '7',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "FbAppId",
//     theme: "Scene",
//     canal: "Robert",
//     index: 6,
// },
// {
//     id: '8',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "Stats_interval",
//     theme: "Scene",
//     canal: "Robert",
//     index: 7,
// },
// {
//     id: '9',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "PlaybackRates",
//     theme: "Scene",
//     canal: "Robert",
//     index: 8,
// },
// {
//     id: '10',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "Show_shortcut_button",
//     theme: "Scene",
//     canal: "Robert",
//     index: 9,
// },
// {
//     id: '11',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "FbAppId",
//     theme: "Scene",
//     canal: "Robert",
//     index: 10,
// },
// {
//     id: '12',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "ShowMbrList",
//     theme: "Scene",
//     canal: "Robert",
//     index: 11,


// },
// {
//     id: '13',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "PlaybackRates",
//     theme: "Scene",
//     canal: "Robert",
//     index: 12,


// },
// {
//     id: '14',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "Social_media",
//     theme: "Scene",
//     canal: "Robert",
//     index: 13,


// },
// {
//     id: '15',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "Stats_interval",
//     theme: "Scene",
//     canal: "Robert",
//     index: 14,


// },
// {
//     id: '6',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "FbAppId",
//     theme: "Scene",
//     canal: "Robert",
//     index: 15,


// },
// {
//     id: '17',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "Export_right",
//     theme: "Scene",
//     canal: "Robert",
//     index: 16,

// },
// {
//     id: '18',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "ShowMbrList",
//     theme: "Scene",
//     canal: "Robert",
//     index: 17,


// },
// {
//     id: '19',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "Social_media",
//     theme: "Scene",
//     canal: "Robert",
//     index: 18,


// },
// {
//     id: '20',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "ShowMbrList",
//     theme: "Scene",
//     canal: "Robert",
//     index: 19,


// },
// {
//     id: '21',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "Export_right",
//     theme: "Scene",
//     canal: "Robert",
//     index: 20,


// },
// {
//     id: '22',
//     url: "https://www.youtube.com/watch?v=o99pjtsuguc",
//     title: "Stats_interval",
//     theme: "Scene",
//     canal: "Robert",
//     index: 21,


// },