import {MoocList} from "./MoocConstants";

const {paginationPropsMooc,showDivsConditionsMooc,AddMoocConstraintsData , FilterMooc}=MoocList()

const MoocListINITIALSTATE = {
    showDivsConditionsMooc:showDivsConditionsMooc(),
    paginationPropsMooc:paginationPropsMooc(),
    addMoocConstraintsData:AddMoocConstraintsData(),
    filterMooc:FilterMooc()
}

export const MoocReducer = (state=MoocListINITIALSTATE , action:any)=>{

    switch (action.type){

        case "SET_FilterMooc" :
            const {FilterMoocNameChange, FilterMoocValueChange} = action.payload
            return {
                ...state,
                filterMooc: {...state.filterMooc, [FilterMoocNameChange]: FilterMoocValueChange}
            }
        case "SET_PaginationPropsMooc" :
            const {PaginationPropsNameChange, PaginationPropsValueChange} = action.payload
            return {
                ...state,
                paginationPropsMooc: {...state.paginationPropsMooc, [PaginationPropsNameChange]: PaginationPropsValueChange}
            }
        case "SET_showDivsConditionsMooc":
            const {showDivsConditionsName,showDivsConditionsValue,record}=action.payload
            const showDivsConditionsObj = {...state.showDivsConditionsMooc,[showDivsConditionsName]: showDivsConditionsValue,recodeId:record}
            return{
                ...state,
                showDivsConditionsMooc:showDivsConditionsObj
            }
        case "SET_AddMooc" :
            const {addMoocConstraintsDataNameChange,addMoocConstraintsDataValueChange}=action.payload
            return {
                ...state,
                addMoocConstraintsData:{...state.addMoocConstraintsData, [addMoocConstraintsDataNameChange]:addMoocConstraintsDataValueChange}
            }
        default: {
            return state
        }

    }

}

