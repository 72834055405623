import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../Redux/hooks";
import Hooks from "../utils/hooks";
import { Header } from '@empreinte/components.header';
import AddIcon from '@mui/icons-material/Add';

const PresetHeader = () => {
  const { t } = useTranslation();

  const { handleAddPreset, onResetPreset ,handleAddPresetSubmit,handleSubmitEditingPreset} = Hooks();
  const showDivCondition = useAppSelector(
    (state) => state.PresetReducer.showDivsConditionsPreset
  );
  const disablesubmitform = useAppSelector(
    (state) => state.PresetReducer.showDivsConditionsPreset.disablesubmitform
  );
  const { configuration_presets_module } = useAppSelector(
    (state) => state.ReducerUserData.modules
  );
  const isSuperAdmin = useAppSelector((state: any) => state.ReducerUserData.isSuperAdmin)

  return (
    <>
    <div>
        {
            !showDivCondition.addPreset && !showDivCondition.editPreset &&
            (configuration_presets_module?.configuration_presets_post_add_action || isSuperAdmin)
            ?.authorized &&
            (<Header
                hasPermission="true"
                title={t("configuration.Preset.Preset")}
                firstBtnTitle={t("configuration.Preset.add")}
                mainPageBtnAction={handleAddPreset}
                firstBtnIcon={<AddIcon/>}
                onMainPage={true}
            />)
        }
    </div>
    <div>
        {
            showDivCondition.addPreset &&

            <Header
                hasPermission="true"
                title={t("configuration.Preset.AddPreset")}
                secondBtnTitle={t("configuration.Preset.cancel")}
                thirdBtnTitle={t("configuration.Preset.register")}
                arrowBackAction={onResetPreset}
                CancelBtnAction={onResetPreset}
                addBtnAction={handleAddPresetSubmit}
                isDisabled={disablesubmitform}
                onMainPage={false}
            />
        }
    </div>
    <div>
        {showDivCondition.editPreset &&
            <Header
                hasPermission="true"
                title={t("configuration.Preset.EditPreset")}
                onMainPage={false}
                secondBtnTitle={t("configuration.Preset.cancel")}
                thirdBtnTitle={t("configuration.Preset.register")}
                arrowBackAction={onResetPreset}
                CancelBtnAction={onResetPreset}
                isDisabled={disablesubmitform}
                addBtnAction={handleSubmitEditingPreset}
            />
        }
    </div>
</>
 
  );
};

export default PresetHeader;
