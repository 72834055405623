
import { useTranslation } from "react-i18next";

export const DataVideoplayer = () => {

    const { t } = useTranslation();

    const columns = [
        {
          headerName: t('Videoplayer.type'),
          field: 'type',
           /* sorter: (a: any, b: any) => {
                let fa = a.type.toLowerCase();
                let fb = b.type.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            sortDirections: ['descend', 'ascend'],*/
            width:800 , 
        },
        {
          headerName: t('Videoplayer.value'),
          field: 'value',
        /*    sorter: (a: any, b: any) => {
                let fa = a.value.toLowerCase();
                let fb = b.value.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
            },
            sortDirections: ['descend', 'ascend',],*/
            width:800 ,
        },
     

    ];
    
    const data = {
        totalElements: 22,
        content: [
            {   id:1,
                type: "moderator1",
                value: "[0.25,0.5,1,1.5,2,5]",
               
            },
            {
              id:2,
              type: "information",
                value: "1980855782163905",
             
              },
              {
                id:3,
                type: "link_moderator1",
                value: "1",
                
              },
              {
                id:4,
                type: "link_moderator1",
                value: "Live",
             
              },
              {
                id:5,
                type: "link_moderator1",
                value: "Live",
              
              },
              {
                id:6,
                type: "link_moderator1",
                value: "[0.25,0.5,1,1.5,2,5]",
                
              },
              {
                id:7,
                type: "link_moderator1",
                value: "Live",
                
              },
              {
                id:8,
                type: "link_moderator1",
                value: "Live",
               
              },
              {
                id:9,
                type: "link_moderator1",
                value: "Live",
                
              },
              {
                id:10,
                type: "link_moderator1",
                value: "Live",
               
              },
              {
                id:11,
                type: "link_moderator1",
                value: "Live",
                
              },
              {
                id:12,
                type: "link_moderator1",
                value: "Live",
                
              },
              {
                id:13,
                type: "link_moderator1",
                value: "Live",
                
              },
              {
                id:14,
                type: "link_moderator1",
                value: "Live",
                
              },
              {
                id:15,
                type: "link_moderator1",
                value: "Live",
                
              },
              {
                id:16,
                type: "link_moderator1",
                value: "Live",
                
              },
              {
                id:17,
                type: "link_moderator1",
                value: "Live",
               
              },
              {
                id:18,
                type: "link_moderator1",
                value: "Live",
               
              },
              {
                id:19,
                type: "link_moderator1",
                value: "Live",
               
              },
              {
                id:20,
                type: "link_moderator1",
                value: "Live",
            
              },
              {
                id:21,
                type: "link_moderator1",
                value: "Live",
               
              },
              {
                id:22,
                type: "link_moderator1",
                value: "Live",
               
              },

        ]
    }
   
    return ({
        columns,
        data
    })

}
