
export const MoocList = () =>{


    const paginationPropsMooc=()=>{
        return(
            {
                order:'descend',
                pageSize:10,
                columnKey:0,
                current:1,
                id:[],
                idLive:0,
            }
        )
    }

    const showDivsConditionsMooc = () =>{
        return({
            elementSelected: 0,
            addMooc: false,
            recodeId:null
        })
    }

    const AddMoocConstraintsData = () => {
        return (
            {
                id:"",
                type:"",
                value:"",
            }
        )
    }
    const FilterMooc = () => {
        return (
            {
                search:"",
                searchFake:"",
            }
        )
    }

    return({
        paginationPropsMooc,
        showDivsConditionsMooc,
        AddMoocConstraintsData,
        FilterMooc
    })
}