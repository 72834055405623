// import { ConstantVideo } from "./videoushConstants";

// const AddVideoData = ConstantVideo();
const initalState = {
    InfoVideo:[],
    previewLink: "",
    visible:false,
    isPush:false,
    errorMessage:"",
    videoId:"",
    isLoading:false
}


export const videopushReducer = (state = initalState, action: any) => {

    switch (action.type) {
        case "SET_VISIBLE":
            return {
                ...state,
                visible: action.payload
            }
        case "GET_DATA":
            return{
                ...state,
                InfoVideo:action.payload.data
            }    
        case "SET_PreviewLink":
            return {
                ...state,
                previewLink: action.payload
            }
            case "SET_Disabled":
            return{
                ...state,
                isPush:action.payload

            }
            case "Set_Error":
                return{
                    ...state,
                    errorMessage:action.payload
                }

           case "SET_Loading":
            return{
                ...state,
                isLoading:action.payload
            }     
            case "SET_VideoPush":
                return{
                    ...state,
                    isPush:action.payload
                }

        default: {
            return state
        }
    }

}
