export const skinConstant=()=>{
    const AddMediaConstraintsData = () => {
        return {
            fileTitle: "",
            title: "",
            medias: [
                {}
            ],
            insertDate: "",
            startDate: "",
            endDate: "",
            updated_at: "",
            duration: "",
            themes: [],
            secure: false,
            hashSource: "",
            allow_share: false,
            downloadable: false,
            externalLinkVideo: "",
            offline: false,
            subscriptionGroups: [],
            description: "",
            transcription: "",
            logoIncrusted: false,
            contributor: "",
            author: "",
            copyright: window.process.env.copyright_default_value ?? "",
            keyWords: "",
            isSecuredOnInternet: false,
            isVisibleOnInternet: true,
            passwordInternet: "",
            isPrivate: false,
            logo: "",
            mainPage: false,
            metaData: "",
            filesVideo: [],
            skin_id:""
        };
    };
    const addSkin=()=>{
        return{
            name:"",
            color_control_bar:"rgba(0, 0, 0, 1)",
            color_primary: "rgba(230, 68, 68, 1)",
            color_secondary: "rgba(255, 255, 255, 1)",
            color_background: "rgba(0, 0, 0, 1)",
            control_play_pause_btn: true,
            control_stop_btn: true,
            control_progress_bar: false,
            control_volume: true,
            control_subtitles: true,
            control_transcript: true,
            control_quality: true,
            control_time: true,
            control_speed: true,
            control_audio_tracks: true,
            control_audio_description: true,
            control_chapters: true,
            control_pip: true,
            control_fullscreen: true,
            control_share: true,
            info_title: true,
            info_desc: true,
            info_date: true,
            info_views: true,
            info_duration: true,
            info_thumbnail: true,
            info_files: true,
            info_chapters: true,
            info_copyright: true,
            info_logo: true,
            info_empreinte:true,
            info_shortcuts:true,
            info_privacy_url: true,
            info_stats: true,
            action_rate: true,
            action_download_video: true,
            action_download_files: true,
            action_download_transcript: true,
            action_loop: true,
            is_editable: true
        }
    }
    const paginationProps = () => {
        return {
          skinTotal: 0,
          pageSize: 10,
          current: 1,
        
        };
      };
    const AddSkinContraints=()=>{
        return {
            isEdit: false,
            isAdd : false,
            isSearch : ""
        }
    }
    
    return {AddMediaConstraintsData, addSkin,AddSkinContraints,paginationProps};
}