import useDataTableMedia from "./DataTable";
import { useAppSelector } from "../../../../Redux/hooks";
import { setmultiDeletemodal } from "../../store/clientActions";
import { useDispatch } from "react-redux";
import { DataClient } from "../../utils/Data";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import "../../client.scss";
import { FormControl } from "@material-ui/core";
import {SearchBar} from "@empreinte/components.search-bar"
import DeleteIcon from '@mui/icons-material/Delete';

const ClientTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // use Selector redux
  const { columns } = DataClient();
  const data = useAppSelector((state) => state.ClientReducer.dataClient);
  const trashContainer = useAppSelector((state)=>state.ClientReducer.showTrash)
  const { handleSearchRow, Deletemultipleselecteditem } = Hooks();
  const { param_module } = useAppSelector(
    (state) => state.ReducerUserData.modules
  );
  const isSuperAdmin = useAppSelector((state: any) => state.ReducerUserData.isSuperAdmin)
  const { DataTable } = useDataTableMedia({
    columns: columns,
    dataSource: data,
    updateEntityPath: "update-product",
  });


  const delet = t("Client.delete");

  return (
    <div className="intgo" >
      <div className="FilterClient">
    { trashContainer && 
    <span className="t_c" onClick={()=>dispatch(setmultiDeletemodal(true))} >
      <DeleteIcon  className="trash"  />
      </span>
      }
        <div className="Filter">
          <div className={ !trashContainer ?  "div_rect rect" :"div_rect_trash"}>
            <div className="rect_filter">
              <FormControl className="search_client">
                <SearchBar         
                  placeholder={t("search")}
                  name={"search"}
                  setSearchText={(e)=>handleSearchRow(e)}
                /> 
              </FormControl>
            </div>
          </div>
        </div>
      </div>

      {(param_module?.param_list_dtable_params_action?.authorized || isSuperAdmin) && (
        <div has-permission="param_list_dtable_params" className={"dataTable"}>
          <DataTable />
        </div>
      )}
    </div>
  );
};

export default ClientTable;
