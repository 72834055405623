import {
  EditOutlined,
  DeleteOutlineOutlined,
  HighlightOffOutlined,
} from "@mui/icons-material";
import {
  Button,
  Tooltip,
  Modal,
  TextField,
  Box,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import Hooks from "../../utils/hooks";
import "../../videoplayer.scss";
function useActionMenu({ record }: any) {
  const { t } = useTranslation();
  const { visible, handleCancel, handleEditVideoplayer, Deleteselecteditem } =
    Hooks();
  const edit = t("Videoplayer.edit");
  const delet = t("Videoplayer.delete");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    overflow: "scroll",
    height: 600,
    pl: 3,
    pr: 1,
    py: 1,
  };
  return (
    <div>
      <div className="action">
        <div>
          <Tooltip placement="top" title={edit}>
            <EditOutlined
              className={"icon_action"}
              onClick={() => handleEditVideoplayer(record)}
            />
          </Tooltip>
        </div>
        <div>
          <Tooltip placement="top" title={delet}>
            <DeleteOutlineOutlined
              className={"icon_action"}
              onClick={() => Deleteselecteditem(record.id)}
            />
          </Tooltip>
        </div>
        <Modal
          open={visible}
          onClose={handleCancel}
        >
          <Box sx={style}>
            <Typography component="h3" className="title">
              {`${t("Videoplayer.modaledittitle")} "${record.type}"`}
              <IconButton className="close" onClick={handleCancel}>
                <HighlightOffOutlined />
              </IconButton>
            </Typography>
            <Typography className="inputmodal">
              <TextField
                multiline
                label={t("Videoplayer.type")}
                defaultValue={record.type}
              />
            </Typography>
            <Typography className="inputmodal">
              <TextField
                multiline
                label={t("Videoplayer.value")}
                defaultValue={record.value}
              />
            </Typography>
            <Typography>
              <Button
                key="back"
                onClick={handleCancel}
                style={{
                  border: "1px solid 	#D8D8D8",
                  marginLeft: "1%",
                  float: "right",
                  marginRight: "32px",
                  marginTop: "50px",
                }}
              >
                {t("Videoplayer.close")}
              </Button>
            </Typography>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default useActionMenu;
