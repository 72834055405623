
export const ActualityList = () =>{


    const paginationPropsActuality=()=>{
        return(
            {
                order:'descend',
                pageSize:10,
                columnKey:0,
                current:1,
                id:[],
                idLive:0,
            }
        )
    }

    const showDivsConditionsActuality = () =>{
        return({
            elementSelected: 0,
            addActuality: false,
        })
    }

    const AddActualityConstraintsData = () => {
        return (
            {
                id:"",
                content:"",
                date:"",
            }
        )
    }
    const FilterActuality = () => {
        return (
            {
                search:"",
                searchFake:"",
            }
        )
    }

    return({
        paginationPropsActuality,
        showDivsConditionsActuality,
        AddActualityConstraintsData,
        FilterActuality
    })
}