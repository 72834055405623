import { useDispatch } from "react-redux";
import { setshowDivsConditionsMetadonnes } from "../store/metadonnesActions";

const Hooks = () => {

    const dispatch = useDispatch()
    /////********* show add edit Metadonnées ****************/////
    const handleAddMetadonnes = () => {

        dispatch(
            setshowDivsConditionsMetadonnes({
                showDivsConditionsName: "addMetadonnes",
                showDivsConditionsValue: true,
            })
        );

    }

    const handleEditMetadonnes = async () => {
        /**edit item list */
        dispatch(
            setshowDivsConditionsMetadonnes({
                showDivsConditionsName: "editMetadonnes",
                showDivsConditionsValue: true,
            })
        );

    }
    const onResetMetadonnes = async () => {
        /**add item list */
        dispatch(
            setshowDivsConditionsMetadonnes({
                showDivsConditionsName: "addMetadonnes",
                showDivsConditionsValue: false,
            })
        );
        dispatch(
            setshowDivsConditionsMetadonnes({
                showDivsConditionsName: "addMetadonnes",
                showDivsConditionsValue: false,
            })
        );

    }
    return ({

        handleAddMetadonnes,
        onResetMetadonnes,
        handleEditMetadonnes

    })
}
export default Hooks


