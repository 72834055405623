
import {useTranslation} from "react-i18next";
import {Tooltip} from '@mui/material';
import {EditOutlined , DeleteOutlineOutlined} from '@mui/icons-material';
import {Hooks} from "../../utils/hooks";


function useActionMenu({record}:any) {
    const {t} = useTranslation();
    const { handleEditMooc }=Hooks()
    const edit = t("Profile.edit")
    const delet = t("Profile.delete")

    return(
        <div>
            <div className="action">
                <div><Tooltip placement="top" title={edit}><EditOutlined className={"icon_action"} onClick={()=>handleEditMooc(record)}/></Tooltip></div>
                <div><Tooltip placement="top" title={delet}><DeleteOutlineOutlined className={"icon_action"}/></Tooltip></div>
            </div>
        </div>
    );
}

export default useActionMenu;