import React from "react";
import { EditOutlined, DeleteOutlineOutlined } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

import "../../configuration.scss";

function MetadonnesActions({ record }: any) {
  const { t } = useTranslation();
  const edit = t("Intervention.edit");
  const delet = t("Intervention.delete");

  return (
    <div>
      <div className="action">
        <div>
          <Tooltip placement="top" title={edit}>
            <EditOutlined className={"icon_action"} />
          </Tooltip>
        </div>
        <div>
          <Tooltip placement="top" title={delet}>
            <DeleteOutlineOutlined className={"icon_action"} />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default MetadonnesActions;
