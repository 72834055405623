import { ConfigurationList } from "./configurationConstants";
const { AddConfigurationConstraintsData, ListChannelConfigurationData, ConfigChannelSelectedConfigurationData } = ConfigurationList()

const ConfigurationListINITIALSTATE = {

    addConfigurationConstraintsData: AddConfigurationConstraintsData(),
    ListChannelsChoices: [],
    ConfigChannelSelected: ConfigChannelSelectedConfigurationData(),
    Listvideos: [],
    idchannelselected:"",
    ErrorMessage: "",
    isLoading:false,
    isButtonDisabled:true
}

export const ConfigurationReducer = (state = ConfigurationListINITIALSTATE, action: any) => {

    switch (action.type) {

        case "SET_ListConfiguration":
            return {
                ...state,
                ListChannelsChoices: action.payload
            }

        case "SET_ConfigChannelSelected":
            return {
                ...state,
                ConfigChannelSelected: action.payload
            }

        case "SET_EditConfigChannelSelected":
            const { ListvideosChannelNameChange, ListvideosChannelValueChange } = action.payload
            return {
                ...state,
                ConfigChannelSelected: { ...state.ConfigChannelSelected, [ListvideosChannelNameChange]: ListvideosChannelValueChange }
            }
        case "SET_ListvideosChannelSelected":

            return {
                ...state,
                Listvideos: action.payload
            }
            case "SET_IdChannelSelected":

                return {
                    ...state,
                    idchannelselected: action.payload
                }

        case "SET_ErrorrMessage":
            return {
                ...state,
                ErrorMessage: action.payload
            }
        case "SET_LOADING":
            return{
                ...state,
                isLoading:action.payload
            }

        
        case "SET_DISABLED":
            return{
                ...state,
                isButtonDisabled:action.payload
            }

        default: {
            return state
        }

    }

}

