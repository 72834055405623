import { ReferentList } from "./referentConstants";
const { showDivsConditionsReferent, AddReferentConstraintsData, paginationPropsReferent, FilterReferent, DataReferent, DetailReferent } = ReferentList()

const ReferentListINITIALSTATE = {
    showDivsConditionsReferent: showDivsConditionsReferent(),
    paginationPropsReferent: paginationPropsReferent(),
    addReferentConstraintsData: AddReferentConstraintsData(),
    filterReferent: FilterReferent(),
    dataReferent: DataReferent(),
    detailReferent: DetailReferent()

}

export const ReferentReducer = (state = ReferentListINITIALSTATE, action: any) => {

    switch (action.type) {

        case "SET_FilterReferent":
            const { FilterReferentNameChange, FilterReferentValueChange } = action.payload
            return {
                ...state,
                filterReferent: { ...state.filterReferent, [FilterReferentNameChange]: FilterReferentValueChange }
            }
        case "SET_PaginationPropsReferent":
            const { PaginationPropsNameChange, PaginationPropsValueChange } = action.payload
            return {
                ...state,
                paginationPropsReferent: { ...state.paginationPropsReferent, [PaginationPropsNameChange]: PaginationPropsValueChange }
            }
        case "SET_showDivsConditionsReferent":
            const { showDivsConditionsName, showDivsConditionsValue } = action.payload
            const showDivsConditionsObj = { ...state.showDivsConditionsReferent, [showDivsConditionsName]: showDivsConditionsValue }
            return {
                ...state,
                showDivsConditionsReferent: showDivsConditionsObj
            }
        case "SET_AddReferentActions":
            const { addReferentConstraintsDataNameChange, addReferentConstraintsDataValueChange } = action.payload
            return {
                ...state,
                addReferentConstraintsData: { ...state.addReferentConstraintsData, [addReferentConstraintsDataNameChange]: addReferentConstraintsDataValueChange }
            }

        case "SET_DataReferentActions":
            
            return {
                ...state,
                dataReferent: action.payload
            }
        case "SET_DetailReferentActions":
           
            return {
                ...state,
                detailReferent: action.payload
            }

        default: {
            return state
        }

    }

}

