import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Link, Breadcrumbs } from "@mui/material";
import { AddBoxOutlined, HomeOutlined } from "@mui/icons-material";

import { useAppSelector } from "../../../Redux/hooks";
import { Hooks } from "../utils/hooks";

const ActualityHeader = () => {
  const { handleAddActuality, onResetActuality } = Hooks();
  const { t } = useTranslation();

  const showDivCondition = useAppSelector(
    (state) => state.ActualityReducer.showDivsConditionsActuality
  );

  return (
    <div>
      <div className={"add_div"}>
        <div>
          {showDivCondition.addActuality ? (
            showDivCondition.elementSelected ? (
              <span className={"profile_title"}>
                {t("Configuration.Actuality.EditActuality")}
              </span>
            ) : (
              <span className={"profile_title"}>
                {t("Configuration.Actuality.AddActuality")}
              </span>
            )
          ) : (
            <span className={"profile_title"}>
              {t("Configuration.Actuality.name")}
            </span>
          )}
        </div>
        <div className={"div_btn_add_cancel"}>
          {showDivCondition.addActuality ? (
            <div className={"div_btn_add_cancel"}>
              <Button
                style={{
                  backgroundColor: "white",
                  border: "solid 2px #1976D2",
                }}
                className={"btnCancel"}
                onClick={onResetActuality}
              >
                <span style={{ color: "#1890FF" }} className={"spn_cancel"}>
                  {t("Profile.cancel")}
                </span>
              </Button>

              <Button
                style={{ backgroundColor: "#1890FF" }}
                className={"btnSubmit"}
                name={"submit"}
              >
                <span style={{ color: "white" }} className={"spn_add"}>
                  {t("Configuration.ValidateBtn")}
                </span>
              </Button>
            </div>
          ) : (
            <Button
              startIcon={<AddBoxOutlined className="icon" />}
              style={{ backgroundColor: "#1890FF" }}
              className={"btnSubmit"}
              onClick={handleAddActuality}
            >
              <span style={{ color: "white" }} className={"spn_add"}>
                {t("Profile.add")}
              </span>
            </Button>
          )}
        </div>
      </div>
      <div className={"Breadcrumb"}>
        <Breadcrumbs>
          <Link underline="none" color="inherit" href="">
            <HomeOutlined className={"home_icon"} />
          </Link>
          <Link underline="none" color="inherit" href="">
            {t("Configuration.breadcrumb")}
          </Link>
          {showDivCondition.addActuality ? (
            showDivCondition.elementSelected ? (
              <Link underline="none" color="inherit" href="">
                {t("Configuration.Actuality.EditActuality")}
              </Link>
            ) : (
              <Link underline="none" color="inherit" href="">
                {t("Configuration.Actuality.AddActuality")}
              </Link>
            )
          ) : (
            <Link underline="none" color="inherit" href="">
              {t("Configuration.Actuality.name")}{" "}
            </Link>
          )}
        </Breadcrumbs>
      </div>
    </div>
  );
};
export default ActualityHeader;
