import { useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import { setDataServeurActions } from "../store/serveurActions";
import { Get_All_servers,Add_Server,Delete_Server,Get_One_Server_Info,Upadte_Server,Multi_Delete, Get_Types } from "./GraphQL";
import { setDetailServeurActions ,setshowDivsConditionsServeur,setPaginationPropsServeur, getTypes} from "../store/serveurActions";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { useAppSelector } from "../../../Redux/hooks";

export const GraphQLFetchData = () => {
  const actionTrace = useAppSelector(
    (state) => state.ServeurReducer.paginationPropsServeur.deleteActionsTrace
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
const [GetListServers] = useMutation(
  Get_All_servers,
  {
    fetchPolicy: "no-cache",
    variables: {},
    onCompleted: (data) => {
      dispatch(setDataServeurActions(data?.servers?.edges)); 
      dispatch(setPaginationPropsServeur({PaginationPropsNameChange:"totalCount",PaginationPropsValueChange:data?.servers?.totalCount})); 
      dispatch({ type: "SET_LOADING_DATA", payload: false})
    },
    onError:(error)=>{
      message.error({ content: t('messageerroroperation'), duration: 2 })
    }
  }
);
const [AddServer] = useMutation(
  Add_Server,
  {
    fetchPolicy: "no-cache",
    variables: {},
    onCompleted: (data) => {
      dispatch(
        setshowDivsConditionsServeur({
          showDivsConditionsName: "addServeur",
          showDivsConditionsValue: false,
        })
      );
      GetListServers({variables:{pagination:{limit:0,offset:0}}})
      message.success({ content: t('successadd'), duration: 2 })  
    },
    onError:(error)=>{
      message.error({ content: t('messageerroroperation'), duration: 2 })
    }
  }
);
const [DeleteServer] = useMutation(
  Delete_Server,
  {
    fetchPolicy: "no-cache",
    variables: {},
    onCompleted: (data) => {
      dispatch(setPaginationPropsServeur({PaginationPropsNameChange:"deleteActionsTrace",PaginationPropsValueChange:!actionTrace})); 
      message.success({ content: t("successdelete"), duration: 2 });
    },
    onError:(error)=>{
      message.error({ content: t('messageerroroperation'), duration: 2 })
    }
  }
);
const [MultyDeleteServer] = useMutation(
  Multi_Delete,
  {
    fetchPolicy: "no-cache",
    variables: {},
    onCompleted: (data) => {
      dispatch(setPaginationPropsServeur({PaginationPropsNameChange:"deleteActionsTrace",PaginationPropsValueChange:!actionTrace})); 
      message.success({ content: t("successdelete"), duration: 2 });
    },
    onError:(error)=>{
      message.error({ content: t('messageerroroperation'), duration: 2 })
    }
  }
);

  const [GetOneServerInfo] = useMutation(
    Get_One_Server_Info,
    {
      fetchPolicy: "no-cache",
      variables: {},
      onCompleted: (data) => {
        dispatch(setDetailServeurActions(data?.servers?.edges[0]?.node))
      },
      onError:(error)=>{
        message.error({ content: t('messageerroroperation'), duration: 2 })
      }
    }
  );
  const [UpdateServer] = useMutation(
    Upadte_Server,
    {
      fetchPolicy: "no-cache",
      variables: {},
      onCompleted: (data) => {
        dispatch(
          setshowDivsConditionsServeur({
            showDivsConditionsName: "editServeur",
            showDivsConditionsValue: false,
          })
          );
        GetListServers({variables:{pagination:{limit:0,offset:0}}})
        message.success({ content: t("Serveur.successedit"), duration: 2 });
      },
      onError:(error)=>{
        message.error({ content: t('messageerroroperation'), duration: 2 })
      }
    }
  );
 const [GetTypes] = useMutation(
  Get_Types,
  {
    fetchPolicy: "no-cache",
    variables: {},
    onCompleted: (data)=> {
      dispatch(
        getTypes(data?.servers?.edges)
      )
    },
    onError:(error)=>{
      message.error({ content: t('messageerroroperation'), duration: 2 })
    }
  }
 )
return {
    GetListServers,
    AddServer,
    DeleteServer,
    GetOneServerInfo,
    UpdateServer,
    MultyDeleteServer,
    GetTypes
  };
}
