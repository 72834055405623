import React from 'react'
import {  
  getSkins,
  setTotalRecord,
  setSelectedSkin,
  setSkin,
  skinId,
  setSkinShowCondition,
  getUrlPlayer,
  setLoaderBoxes,
  setEditMode,
  setLoadingSkins,
  setResetSkinPage,
  setselectedTheme,
  setPaginationSkin,
  setThemeSelectedSkin,
  setChannelSelectedSkin,
  setVideoChannels } from '../store/skinActions'
  import { useAppSelector } from '../../../Redux/hooks'
  import axios from 'axios'
  import { useTranslation } from 'react-i18next'
  // import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
  import { message } from 'antd';
  import { GetData,PostData } from '../../../APIcalls'
  
  export const SkinHooks = ()=> {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  // const history = useHistory();

  const dataSkin = useAppSelector(
    (state) => state.skinReducer.addSkin)
	


    const colorStyle = (color, format) => {
  
      if (typeof color === 'string' && color.startsWith('rgba') && format === "change") {
        const rgbaValues = color.match(/[\d.]+/g);
        if (rgbaValues && rgbaValues.length === 4) {
            const [r, g, b, a] = rgbaValues.map(val => val.toString());
            return { r, g, b, a };
        }
    }
      const { r, g, b, a } = color;
      const hexColor = `#${Number(r)?.toString(16)?.padStart(2, "0")}${Number(g)
        .toString(16)
        .padStart(2, "0")}${Number(b)?.toString(16)?.padStart(2, "0")}`;
      if (format === "hex") {
        return hexColor;
      } else if (format === "rgba") {
        return `rgba(${r},${g},${b},${a})`;
      } else {
        return hexColor;
      }
    };
  
    //////////////////////// Reset to Default skin /////////////////
  
    const resetSkin=()=>{
      dispatch(setSkin({
        addSkinDataNameChange: "color_primary",
        addSkinDataValueChange: "rgba(230, 68, 68, 1)"
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "color_secondary",
        addSkinDataValueChange: "rgba(255, 255, 255, 1)"
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "color_control_bar",
        addSkinDataValueChange: "rgba(0, 0, 0, 1)"
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "color_background",
        addSkinDataValueChange: "rgba(0, 0, 0, 1)"
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_play_pause_btn",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_stop_btn",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_progress_bar",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_volume",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_subtitles",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_transcript",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_quality",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_time",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_speed",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_audio_tracks",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_audio_description",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_chapters",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_pip",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_fullscreen",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "control_share",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "info_title",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "info_desc",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "info_date",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "info_views",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "info_duration",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "info_thumbnail",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "info_files",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "info_chapters",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "info_copyright",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "info_logo",
        addSkinDataValueChange: true
      })); 
      dispatch(setSkin({
        addSkinDataNameChange: "info_privacy_url",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "info_stats",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "action_rate",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "action_download_video",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "action_download_files",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "action_download_transcript",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange: "action_loop",
        addSkinDataValueChange: true
      }));
      dispatch(setSkin({
        addSkinDataNameChange:"is_editable",
        addSkinDataValueChange:true
      }))
      dispatch(setSkinShowCondition({
        showSkinConditionsName: "isEdit",
        showSkinConditionsValue: false
      }));
      dispatch(setSkinShowCondition({
        showSkinConditionsName: "isAdd",
        showSkinConditionsValue: false
      }));
      dispatch(setSkinShowCondition({
        showSkinConditionsName: "isSearch",
        showSkinConditionsValue: ""
      }));
    }
  
    ///////////////// Display Registered Skins //////////////
  
    const skinsDisplay = async(page,nbPage,searchWord) =>{
      await GetData(`/video-api/video/skin/list?page=${page}&nbParPage=${nbPage}${searchWord ? `&searchWord=${searchWord}` : ''}`)
      .then((res)=>{
        if(res?.status === 200){
          dispatch(setLoadingSkins(false))
          dispatch(setLoaderBoxes(false))
          dispatch(getSkins(res?.data?.data))
          dispatch(setTotalRecord(res?.data?.nombre_total))
          dispatch(
            setPaginationSkin({
              PaginationSkinNameChange: "skinTotal",
              PaginationSkinValueChange: res?.data?.nombre_total,
            })
          );
        }else{
          message.error(t('Skin-Player.error-display'));
        }
      })
      .catch((err)=>{
        console.log("error",err)
      })
    }
  
    /////////////////   Create Skin    //////////////////
  
    const createSkin = async()=>{
      await PostData(`/video-api/video/skin`,dataSkin)
      .then((res)=>{
        if(res.status === 200){
          message.success(t('Skin-Player.sucess-add-skin'));
          resetSkin();
          dispatch(setResetSkinPage(true))
  
          dispatch(setLoadingSkins(true))
          // skinsDisplay(1,5,'')
          
        }else{
          message.error(t('Skin-Player.error-add-skin'));
  
        }
      }).catch((err)=>{
        message.error(t('Skin-Player.error-add-skin'));
      })
    }
  
  
    ///////////////////// Add Skin //////////////////
  
    const addSkin = async () =>{
      dispatch(setSkinShowCondition({
        showSkinConditionsName: "isEdit",
        showSkinConditionsValue: false
      }));
      dispatch(setSkinShowCondition({
        showSkinConditionsName: "isAdd",
        showSkinConditionsValue: true
      }));
      dispatch(setSelectedSkin(dataSkin))
      dispatch(setEditMode(true));
    }
    ///////////////////// Edit skin //////////////////
  
    const editSkin = async (id) =>{
      dispatch(setSkinShowCondition({
        showSkinConditionsName: "isEdit",
        showSkinConditionsValue: true
      }));
      dispatch(setSkinShowCondition({
        showSkinConditionsName: "isAdd",
        showSkinConditionsValue: false
      }));
      dispatch(skinId(id));
      dispatch(setEditMode(true));
  
    }
    ///////////////////// Update Skin //////////////////
  
    const updateSkin= async(id,dataSkin) =>{
      await PostData(`/video-api/video/skin/update/${id}`,dataSkin)
      .then((res)=>{
        if(res.status === 200){
          message.success(t('Skin-Player.success-update'))
          dispatch(setLoadingSkins(true));
          dispatch(setResetSkinPage(true));
          resetSkin();
          dispatch(
        setPaginationSkin({
          PaginationSkinNameChange: "current",
          PaginationSkinValueChange: 1,
        })
      );
          // skinsDisplay(1,5,'') 
  
        }else if(res.code === 404 ){
          message.error(t("Skin-Player.error-update"))
        }
    
      }).catch((err)=>{
        message.error(t("Skin-Player.error-update"))
      })
    }
  
    //////////////////////////// Delete Skin ///////////////////////
  
    const deleteSkin = async (id) =>{
      await PostData(`/video-api/video/skin/delete/${id}`)
      .then((res)=>{
        if(res?.data?.status === 200 && res?.data.success === true){
          message.success(t("Skin-Player.success-delete"))
          dispatch(setLoaderBoxes(true))
          // skinsDisplay(1,5,'') 
          setPaginationSkin({
            PaginationSkinNameChange: "current",
            PaginationSkinValueChange: 1,
          })
          resetSkin();
        }
  
        else{
          message.error(t("Skin-Player.error-delete"))
        }
      }).catch((err)=>{
        message.error(t("Skin-Player.error-delete"))
  
      })
    }
  
  
    ///////////////////////// Get 1 Skin List ////////////////////////
  
    const getListSkin = async(id)=>{
      await GetData(`/video-api/video/skin/${id}`)
      .then((res)=>{
        if(res.status === 200){
          dispatch(setThemeSelectedSkin(res.data.data.themes))
          dispatch(setChannelSelectedSkin(res.data.data.channels))
          dispatch(setSelectedSkin(res.data.data))
          dispatch(setLoaderBoxes(false))
        }else{
          console.log("not found skin");
        }
      }).catch((err)=>err)
    }
  
  
    ///////////////// get external link /////////////////
  
    const getSkinLink = async(id)=>{
      await GetData(`/video/video/preview-link/${id}?y=bo`)
      .then((res)=>{
        dispatch(getUrlPlayer(res?.data?.link))
  
      }).catch((err)=>console.log(err))
    }
  
    ////////////// applySKin ///////////////////
    
    const applySkin=async(themeId,channelId,skinId)=>{
      const body={
        "theme_id":themeId ? themeId : 0,
        "channel_id":channelId ? channelId : 0 ,
        "skin_id":skinId
      }
      await PostData(`video-api/video/skin/apply-skin`,body).then((res)=>{
      if(res?.status === 200){
        message.success(t("Skin-Player.successSkinApplied"))
      }else{
        message.error(t("Skin-Player.errorSkinApplied"))
      }
      }
      ).catch((err)=>message.error(t("Skin-Player.errorSkinApplied")))
    }
  
  
    /////////////// getChannels video ///////////////////////
  
    const getChannelVideos = async(id)=>{
      await GetData(`/video-api/video/all?columns[0][orderable]=true&draw=1&length=10&order[0][column]=0&order[0][dir]=desc&start=0&idChannel=${id}`)
      .then((res)=>{
        dispatch(setVideoChannels(res?.data?.recordsFiltered))
      })
      .catch((err)=>console.log(err))
    }
  return{
    colorStyle,
    skinsDisplay,
    getListSkin,
    deleteSkin,
    createSkin,
    updateSkin,
    editSkin,
    resetSkin,
    addSkin,
    getSkinLink,
    applySkin,
    getChannelVideos
  }
}


export default SkinHooks