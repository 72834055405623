import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../Redux/hooks";
import UseActionMenu from "./actionsUserTable";
import moment from "moment";
import "moment/locale/zh-cn";
import Hooks from "../../utils/hooks";
import '../../serveur.scss'
import { useDispatch } from "react-redux";
import { GridNorows } from '@empreinte/components.grid-norows';
import { Datagrid } from '@empreinte/components.datagrid';
import { useTranslation } from "react-i18next";
import { setshowDivsConditionsServeur } from "../../store/serveurActions";
import { useMediaQuery } from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
moment.locale("en");

export default function DataGridTable ({setCurrentPage,currentPage,setPageSize,pageSize}: any)  {
  const dataSource = useAppSelector((state) => state.ServeurReducer.dataServeur)
  const { handelFilter, handelSearch } = Hooks();
  const { t } = useTranslation();
  const [selectedRow] = useState<any>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const values = useAppSelector((state) => state.ServeurReducer.filterServeur);
  const showPhoneColumns = useMediaQuery('(min-width:757px)');
  const dispatch = useDispatch();
  const total = useAppSelector(
    (state) => state.ServeurReducer.paginationPropsServeur.totalCount
  );
  const selectedRows = useAppSelector(
    (state) => state.ServeurReducer.showDivsConditionsServeur.elementsSelected
  );
  const handleIconClick = (e:any, y:any) => {
    // dispatch(changeOrder(e));
    // dispatch(changeDir(y));
  };
  const TableColumns = [
    {
      headerName: t('Serveur.type'),
      field: 'Type',
      width:showPhoneColumns ? 200 : 100,
      flex: 1,
      sortable: true
   
  },
  {
      headerName: t('Serveur.access'),
      field: 'Internet',
      renderCell:(params :any) =>{
       return <>
       {params?.row?.Internet === true ? <CheckIcon className={'vertServeur'}/>:<CancelIcon className={'rougeServeur'}/>}
       </>
    },
    width:showPhoneColumns ? 200 : 100,
    flex: 1,
  

  },
  {
      headerName: t('Serveur.identify'),
      field: 'Name',
      width:showPhoneColumns ? 300 : 100,
      flex: 1,
      sortable: true,
     
  },
  {
      headerName: t('Serveur.url'),
      field: 'UrlDiffusion',
      width:showPhoneColumns ? 400 : 200,
      flex: 1,
      sortable: true,
      
  },
  {
      headerName: t('Serveur.urlMp4'),
      field: 'UrlMp4',
      width:showPhoneColumns ? 400 : 200,
      flex: 1,
      sortable: true,
     
  },
  {
    headerName: t('Serveur.actions'),
    field: 'action',
    editable: false,
    sortable: false,
    width:showPhoneColumns ? 310 : 100,
    renderCell: (params: any) => {
        return (
            <UseActionMenu record={params.row} />
        )
    },
    renderHeader: () => (
        <>
          <span className="colomunHeaderUnique">{t('Serveur.actions')}</span>
        </>
      ),
}

];
const onSelectChange = (selectedRowKeys: any, record: any) => {
      
  let filter: Array<any>
  filter = [];
  filter = dataSource
      .filter((item: any) => {
          return !selectedRowKeys.includes(item.id);
      })
      .map((ele: any) => ele.id);

  let uniqItems: Array<any>
  let uniqItemsFilter: Array<any>
  uniqItems = Array.from(new Set(selectedRowKeys))
  uniqItemsFilter = uniqItems.filter((item) => {
      return !filter.includes(item);
  });

  dispatch(
      setshowDivsConditionsServeur({
          showDivsConditionsName: "elementSelected",
          showDivsConditionsValue: uniqItemsFilter.length,
      })
  );
  dispatch(
    setshowDivsConditionsServeur({
        showDivsConditionsName: "elementsSelected",
        showDivsConditionsValue: uniqItemsFilter,
    })
);

  setSelectedRowKeys(uniqItemsFilter);
};

    const rowSelection = {
          
      getCheckboxProps: (record: any) => ({
          disabled: record.id === false,
      }),
      onChange: onSelectChange,
    };
    
  const dataWithoutNodeKey = dataSource.map((item:any) => item.node);

  const filteredeventsfilter = handelFilter(values.type, dataWithoutNodeKey);
  const filteredevents = handelSearch(values.searchFake, filteredeventsfilter);
  return (
    <div className="DataTable">
      {dataSource.length === 0 ? <GridNorows content={t("Aucun enregistrement correspondant trouvé")} /> :<Datagrid 
                  rows={filteredevents}
                  columns={showPhoneColumns ? TableColumns : TableColumns.filter(el=> el.field!=="UrlMp4" && el.field!=="UrlDiffusion" )}
                  totalEntities={total}
                  checkboxSelect={onSelectChange}
                  selectionModel={selectedRows}
                  EntriesName={`${t("Serveur.entries")}`}
                  OfPageName={`${t("Serveur.of")}`}
                  ShowName={`${t("Serveur.show")}`}
                  height={"calc(100vh - 185px)"}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setPageSize={setPageSize}
                  pageSize={pageSize}
                  localeText={{columnHeaderSortIconLabel:  `${t("Serveur.sort")}` }}
              />}
    </div>
  )
}

