
export const ClientList = () => {

    const FilterClient = () => {
        return (
            {
                search: "",
                searchFake: "",
            }
        )
    }

    const paginationPropsClient = () => {
        return (
            {
                order: 'descend',
                pageSize: 10,
                columnKey: 0,
                current: 1,
                id: [],
                idLive: 0,
            }
        )
    }

    const showDivsConditionsClient = () => {
        return ({
            elementSelected: 0,
            addClient: false,
            editClient: false,
            disablesubmitform: true,

        })
    }

    const AddClientConstraintsData = () => {
        return (
            {
                Id: "",
                Cle:"",
                Value: "",
                CreatedAt: "",
                UpdatedAt: "",
            }
        )
    }

    const DataClient= () =>{
        return []
    }
    const DetailClient= () =>{
        return []
    }



    return ({
        paginationPropsClient,
        showDivsConditionsClient,
        AddClientConstraintsData,
        FilterClient,
        DataClient,
        DetailClient
    })
}
