import gql from "graphql-tag";


    export const Get_All_Presets = gql`
        mutation ($where:PresetWhereInput $pagination: Pagination){ 
          presets(where:$where pagination:$pagination orderBy:{field:ID direction:DESC}) {
            totalCount
                edges{
                    node{
                        Id
                        BitrateVideo
                        BitrateAudio
                        SamplerateAudio
                        Framerate
                        State
                        CreatedAt
                        UpdatedAt
                        Width
                        Height
                        SortBitrate
                        Name
                        }
                    }
                }
    }`
    


    export const Add_Preset = gql`
        mutation ($input: CreatePresetInput!) {
            createPreset(input: $input) 
            {
                Id
                BitrateVideo
                BitrateAudio
                SamplerateAudio
                Framerate
                State
                CreatedAt
                UpdatedAt
                Width
                Height
                SortBitrate
                Name
    }}`

    export const Upadte_Preset = gql`
        mutation ($input: UpdatePresetInput!) {
            updatePreset(input: $input) 
            {
                Id
                BitrateVideo
                BitrateAudio
                SamplerateAudio
                Framerate
                State
                CreatedAt
                UpdatedAt
                Width
                Height
                SortBitrate
                Name
    }}`

    export const Delete_Preset = gql`
        mutation ($ids:[Int]) { 
            deletePreset(ids:$ids) 
                {
                    code
                    message
                }
        }
    `
    export const Search_presets_By_Type = gql`
    mutation ($where: PresetWhereInput) 
        { presets (where: $where) {
            totalCount
                edges{
                    node{
                        Id
                        BitrateVideo
                        BitrateAudio
                        SamplerateAudio
                        Framerate
                        State
                        CreatedAt
                        UpdatedAt
                        Width
                        Height
                        SortBitrate
                        Name
                        }
                    }
                }
        }
`


    
