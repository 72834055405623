
import React, { useState } from 'react';
import {DeleteOutlineOutlined} from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import '../../videoshome.scss'
import { ModalConfirm } from '@empreinte/components.modal-confirm';
import { setOpenModalDel } from '../../store/videoshomeActions';
import { useDispatch } from 'react-redux';

function useActionMenu({ record }: any) {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { visible , Deleteselecteditem} = Hooks()
    const delet = t("delete");
    const [open,setOpen]=useState(false)

   
    return (
        <div>
            <div className="action">
                <div><Tooltip placement="top" title={delet}><DeleteOutlineOutlined className={"icon_action"} onClick={() =>{
                    setOpenModalDel(true);
                    setOpen(true)

                }} /></Tooltip></div>


                {open && (    
                    <ModalConfirm
                        open={open}
                        onClose={()=>setOpen(false)}
                        title={t('DeleteMulti')}
                        content={t('DeleteConfirmationOneDelete')}
                        fullWidth={true}
                        CancelBtnName={t('No')}
                        ConfirmBtnName={t('Yes')}
                        onCancel={()=>setOpen(false)}
                        onConfirm={() => 
                        {
                            Deleteselecteditem(record?.row?.id)
                            dispatch(setOpenModalDel(false))
                            setOpen(false)
                        }}
                    />)}
            </div>
        </div>
    );
}

export default useActionMenu;
