
export const ReferentList = () => {

    const FilterReferent = () => {
        return (
            {
                search: "",
                searchFake: "",
            }
        )
    }

    const paginationPropsReferent = () => {
        return (
            {
                order: 'descend',
                pageSize: 10,
                columnKey: 0,
                current: 1,
                id: [],
                idLive: 0,
            }
        )
    }

    const showDivsConditionsReferent = () => {
        return ({
            elementSelected: 0,
            addReferent: false,
            editReferent: false
        })
    }

    const AddReferentConstraintsData = () => {
        return (
            {
                id: "",
                url: "",
                mode: false,
                name: "",
                list: "",
            }
        )
    }

    const DataReferent= () =>{
        return []
    }
    const DetailReferent= () =>{
        return []
    }

    return ({
        paginationPropsReferent,
        showDivsConditionsReferent,
        AddReferentConstraintsData,
        FilterReferent,
        DataReferent,
        DetailReferent

    })
}
