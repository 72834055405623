import React from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../../Redux/hooks'
import {SkinHooks} from '../utils/Hooks'
import { Header } from '@empreinte/components.header'
import AddIcon from '@mui/icons-material/Add';


function SkinHeader() {
    const {resetSkin} = SkinHooks();
    const {t} = useTranslation()
    const showSkinCondition = useAppSelector((state) => state.skinReducer.AddSkinContraints)
  return (
    <div>
    <Header
    //  hasPermission='true' 
    //  hasIcon={true}
      title={
        !showSkinCondition.isAdd && !showSkinCondition.isEdit ? (
         <span className={"Client-TitleHeader"}>{t('Skin-Player.Skin')}</span>
       ) : showSkinCondition.isAdd ? (
         <>
           <span className={"Client-TitleHeader"}>{t('Skin-Player.Add_skin')}</span>
         </>
       ) : showSkinCondition.isEdit ? (
         <>
           <span className={"Client-TitleHeader"}>{t('Skin-Player.Edit_skin')}</span>
         </>
       ) : (
         ""
       )
       } 
       
     
     
        arrowBackAction={resetSkin}
        isDisabled={'disablesubmitform'}
        // CancelBtnAction={showSkinCondition.isAdd || showSkinCondition.isEdit ? resetSkin : null}
       onMainPage={!showSkinCondition.isAdd && !showSkinCondition.isEdit } />
    </div>
  )
}

export default SkinHeader
