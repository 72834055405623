import { useState } from "react";
import UseActionMenu from "./metadonnesActions";
import frFR from "antd/lib/locale/fr_FR";
import { DataGrid } from "@mui/x-data-grid";

import { useTranslation } from "react-i18next";

import moment from "moment";
import "moment/locale/zh-cn";

moment.locale("en");

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;

function useDataTableMedia({ columns, dataSource, updateEntityPath }: any) {
  const { t } = useTranslation();

  const [selectedRowKeys] = useState<any>([]);

  const [selectedRow] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<any>(DEFAULT_PAGE_NUMBER);
  const [pageSize] = useState<any>(DEFAULT_PAGE_SIZE);

  const resetPagination = () => {
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const updatedColumns = [
    ...columns,
    {
      headerName: t("actions"),
      key: "action",
      renderCell: (record: any, action: any) => {
        return <UseActionMenu record={record} />;
      },
      width: 310,
      sortable: false,
    },
  ];

  const DataTable = () => (
    <div
      style={{
        height: 700,
        width: "95%",
        marginTop: "2%",
        border: "none",
        marginLeft: "2%",
      }}
    >
      <DataGrid
        rows={dataSource}
        columns={updatedColumns}
      />
    </div>
  );

  return {
    DataTable,

    selectedRow,
    selectedRowKeys,
    currentPage,
    pageSize,
    resetPagination,
  };
}

export default useDataTableMedia;
