import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import {Button , Link ,Breadcrumbs } from '@mui/material';
import {AddBoxOutlined , HomeOutlined , Close , CheckCircleOutlineOutlined , ArrowBack} from '@mui/icons-material';
import { useAppSelector } from "../../../Redux/hooks";
import { Hooks } from "../utils/hooks";

const MoocHeader = () => {
  const { handleAddMooc, onResetMooc,values } = Hooks();
  const { t } = useTranslation();

  // use Selector redux
  const showDivCondition = useAppSelector(
    (state) => state.MoocReducer.showDivsConditionsMooc
  );

  return (
    <div>
        <div className={"add_div"}>
        <div>
          {showDivCondition.addMooc ? (
            showDivCondition.recodeId ? (
              <>
                <span className={"profile_title"}>
                <ArrowBack />
                  {t("Configuration.Mooc.EditMooc")}:
                </span>
                <span className={"title"}>
                {values.type}
                </span>
              </>
            ) : (
              <span className={"profile_title"}>
                {t("Configuration.Mooc.AddMooc")}
              </span>
            )
          ) : (
            <span className={"profile_title"}>
              {t("Configuration.Mooc.name")}
            </span>
          )}
        </div>
        {/*DIVTITLEHEADER*/}

        <div className={"div_btn_add_cancel"}>
          {showDivCondition.addMooc ? (
            <div className={"div_btn_add_cancel"}>
              <Button
               style={{backgroundColor: 'white' , border : 'solid 2px #1976D2' }}
                className={"btnCancel"}
                startIcon={<Close style={{color: '#1890FF'}} className="icon" />}
                name={"cancel"}
                onClick={onResetMooc}
              >
                <span style={{color: '#1890FF'}} className={"spn_cancel"}>{t("Profile.cancel")}</span>
              </Button>

              <Button
               style={{backgroundColor: '#1890FF'}}
                className={"btnSubmit"}
                startIcon={<CheckCircleOutlineOutlined className="icon" />}
                name={"submit"}
              >
                <span style={{color: 'white'}} className={"spn_add"}>{t("Configuration.ValidateBtn")}</span>
              </Button>
            </div>
          ) : (
            <Button
            style={{backgroundColor: '#1890FF'}}
              className={"btnSubmit"}
              onClick={handleAddMooc}
              startIcon={<AddBoxOutlined className="icon" />}
            >
              <span style={{color: 'white'}} className={"spn_add"}>{t("Profile.add")}</span>
            </Button>
          )}
        </div>
      </div>
      <div className={"Breadcrumb"}>
        <Breadcrumbs>
        <Link  underline="none" color="inherit" href=""  onClick={() => {
              <Navigate to="/dashboard" />;
            }}>
              <span>
              <HomeOutlined className={"home_icon"} />
            </span>
            </Link>
            <Link  underline="none" color="inherit" href="">{t("Configuration.breadcrumb")}</Link>
          {showDivCondition.addMooc ? (
            showDivCondition.recodeId ? (
              <Link  underline="none" color="inherit" href="">
                {t("Configuration.Mooc.EditMooc")}
                </Link>
            ) : (
              <Link  underline="none" color="inherit" href="">
                {t("Configuration.Mooc.AddMooc")}
                </Link>
            )
          ) : (
            <Link  underline="none" color="inherit" href="">{t("Configuration.Mooc.name")}</Link>
          )}
        </Breadcrumbs>
      </div>
      {/*Breadcrumb*/}

    
      {/*add_div*/}
    </div>
  );
};
export default MoocHeader;
