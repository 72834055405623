import { useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { Delete_Video } from "./GraghQL";
import { setSelected ,setmultiCase,setLoading,setOpenModalDel} from "../store/videoshomeActions";



export const GraphQLFetchData = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [multiDelete]= useMutation(
        Delete_Video,
        {
          fetchPolicy:"no-cache",
          variables:{},
          onCompleted:(data)=>{
            if(data.deleteHomeConf.code == 200) {
              dispatch(setLoading(false))
              message.success({content:'delete done',duration:2})
            }
          },
          onError:(error)=>{
            message.error({ content: "failed", duration: 2 })
            dispatch(setLoading(false))

          }
    
        }
      )


      return {
        multiDelete
      };  
}