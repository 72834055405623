import { useEffect } from 'react'
import VideoplayerHeader from './components/videoplayerHeader';
import VideoplayerTable from "./components/List/videoplayerTable";
import AddEditVideoplayer from "./components/AddEdit/addEdit";
import './videoplayer.scss'
import { useAppSelector } from "../../Redux/hooks";
import Hooks from "./utils/hooks";
import {Grid} from "@material-ui/core";
const Videoplayer = () => {
    const { formAddVideoplayer, onFinishAddVideoplayer, onValueFormUserchangeVideoplayer, getAllData } = Hooks()
    const showDivCondition = useAppSelector((state) => state.VideoplayerReducer.showDivsConditionsVideoplayer)
    const values = useAppSelector((state) => state.VideoplayerReducer.addVideoplayerConstraintsData)

    useEffect(() => {
        getAllData()
        if (showDivCondition.editVideoplayer) {
            formAddVideoplayer.setFieldsValue({
                id: values.id,
                type: values.type,
                value: values.value,
            })
        }
        if (showDivCondition.addVideoplayer) {
            formAddVideoplayer.setFieldsValue({
                id: "",
                type: "",
                value: "",
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDivCondition.editVideoplayer, showDivCondition.addVideoplayer])
    return (
        <div className={"divGlobalTable"}>
            {
                !showDivCondition.addVideoplayer && !showDivCondition.editVideoplayer
                    ?
                    <div>
                        <VideoplayerHeader />
                        <VideoplayerTable />
                    </div>
                    :
                    <div>
                          <Grid
                            onSubmit={onFinishAddVideoplayer}
                            onChange={onValueFormUserchangeVideoplayer}
                            //form={formAddVideoplayer}
                        >
                            <VideoplayerHeader />
                            <AddEditVideoplayer />
                        </Grid>
                    </div>
            }

        </div>
    )
}

export default Videoplayer
