import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../Redux/hooks";
import { useDispatch } from 'react-redux';
import Hooks from "../utils/hooks";
import { Header } from '@empreinte/components.header';
import AddIcon from '@mui/icons-material/Add';
import { ClipLoader } from '@empreinte/components.clip-loader';
import "../serveur.scss";
import { Dialog, DialogContent } from "@mui/material";
import { useState } from "react";
const ServeurHeader = () => {
    const loadData = useAppSelector((state) => state.ServeurReducer.loading)
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { handleAddServeur, onResetServeur, handleAddServeurSubmit,handleSubmitEditingServer } = Hooks();
    const [open, setOpen] = useState(true);

  const showDivCondition = useAppSelector(
    (state) => state.ServeurReducer.showDivsConditionsServeur
  );
  const disablesubmitform = useAppSelector(
    (state) => state.ServeurReducer.showDivsConditionsServeur.disablesubmitform
  );
  const { configuration_server_module } = useAppSelector(
    (state) => state.ReducerUserData.modules
  );
  const isSuperAdmin = useAppSelector((state: any) => state.ReducerUserData.isSuperAdmin)

  const handleEditServer = () => {
    dispatch(handleSubmitEditingServer)
    dispatch({ type: "SET_LOADING_DATA", payload: true})
  }

  const handleAddServer = () => {
    dispatch(handleAddServeurSubmit)
    dispatch({ type: "SET_LOADING_DATA", payload: true})
  }

  const handleClose = () => {
    setOpen(false)
  }
  
  if(loadData) {
    return (
        <Dialog
             open={open}
             onClose={handleClose}
             aria-labelledby="alert-dialog-title"
             aria-describedby="alert-dialog-description"
           >
             <DialogContent sx={{height: '50%'}}>
           <ClipLoader color='inherit' thickness={1.6} style={{marginTop: '6px'}} />
           </DialogContent>
           </Dialog>

    )
  }

    return (
        <>
            <div>
                {
                    !showDivCondition.addServeur && !showDivCondition.editServeur &&
                     (configuration_server_module?.configuration_server_post_add_action?.authorized || isSuperAdmin) &&
                    (<Header
                        hasPermission="true"
                        title={t('Serveur.serveur')}
                        firstBtnTitle={t('Serveur.addserveur')}
                        mainPageBtnAction={()=>{handleAddServeur(configuration_server_module?.configuration_server_post_add_action?.name)}}
                        firstBtnIcon={<AddIcon/>}
                        onMainPage={true}
                    />)
                }
            </div>
            <div>
                {
                    showDivCondition.addServeur &&

                    <Header
                        hasPermission="true"
                        title={t('Serveur.addServer')}
                        secondBtnTitle={t('Serveur.cancel')}
                        thirdBtnTitle={t('Serveur.add')}
                        arrowBackAction={onResetServeur}
                        CancelBtnAction={onResetServeur}
                        addBtnAction={handleAddServer}
                        isDisabled={disablesubmitform}
                        onMainPage={false}
                    />
                }
            </div>
            <div>
                {showDivCondition.editServeur &&
                    <Header
                        hasPermission="true"
                        title={t('Serveur.modifserveur')}
                        onMainPage={false}
                        secondBtnTitle={t('Serveur.cancel')}
                        thirdBtnTitle={t('Serveur.validate')}
                        arrowBackAction={onResetServeur}
                        CancelBtnAction={onResetServeur}
                        isDisabled={disablesubmitform}
                        addBtnAction={handleEditServer}
                    />
                }
            </div>
        </>
    )
};

export default ServeurHeader;
