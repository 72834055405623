import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    setAddClientActions, setFilterClientActions, setshowDivsConditionsClient,
    setDataClientActions, setDetailClientActions, setPaginationPropsClient
} from "../store/clientActions"
import { Form, message } from "antd";
import { useAppSelector } from "../../../Redux/hooks";
import {setLoader} from "../store/clientActions"
import {
    GetData,
    PostData,

    DeleteData,
} from "../../../APIcalls";
import { useTranslation } from "react-i18next";
import {GraphQLFetchData} from "./GraphFetchData"
import { Helpers } from "../../../utils/helpers";

const Hooks = () => {
    const { t } = useTranslation();
    const {acces_send}= Helpers()
    const [visible, setVisible] = useState<any>(false)
    const [visibleExport] = useState<any>(false)
    const [statusSuccessMessages, setStatusSuccessMessages] = useState<any>(true)
    const [statusErrorMessages, setStatusErrorMessages] = useState<any>(true)
    const dispatch = useDispatch()
    const [formAddClient] = Form.useForm()
    const {GetOneClientInfo,useGetOneClientInfoToEdit, UpdateClient,AddClient,SearchByKeyWordClient} = GraphQLFetchData()

    // use Selector redux
    const values = useAppSelector((state) => state.ClientReducer.addClientConstraintsData)
    const listitemselected = useAppSelector((state) => state.ClientReducer.paginationPropsClient.id)
    const Clientcondition = useAppSelector((state) => state.ClientReducer.showDivsConditionsClient)
    const ServerId = useAppSelector(
        (state) => state.ClientReducer.showDivsConditionsClient.elementSelected
      );


    useEffect(() => {
        formAddClient.setFieldsValue({
            Id: values.Id,
            Cle: values.Cle,
            Value: values.Value,
            CreatedAt: values.CreatedAt,
            UpdatedAt: values.UpdatedAt,
        })
 // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const handleSubmitEditingClient = async () => {
        dispatch(setLoader(true));
        await UpdateClient({variables : {input:{
          Id:ServerId,
          Cle: values.Cle,
          Value: values.Value,
        }}})
        onResetClient()
      }


    ////*************** show Modal Function ******************/////////
    const handleShowModal = (id: any) => {
        GetOneClientInfo({variables:{where:{id:id}}})
        setVisible(true)
    }
    const handleCancel = () => {
        setVisible(false)
    }

    /////////******* copy lien intégration */////////////
    const CopyUrlAuditeur = async () => {
        if ((document.getElementById("myUrlAuditeurClient") as HTMLFormElement).value === "") {
            await setStatusErrorMessages(false)
            return (
                statusErrorMessages && message.error({ content: "Cet champ est Vide", duration: 2 }).then(
                    async () => {
                        setStatusErrorMessages(true)
                    }
                )
            )
        } else {

            (document.getElementById("myUrlAuditeurClient") as HTMLFormElement).select();
            document.execCommand("Copy");
            await setStatusSuccessMessages(false)
            statusSuccessMessages && await message.success({ content: "cet champ est copié avec succée", duration: 2 }).then(
                async () => {
                    setStatusSuccessMessages(true)
                }
            );
        }
    }

    /////********* show add edit client ****************/////
    
    const handleSubmitAddClient= ()=>{
        dispatch(setLoader(true));
        AddClient({variables:{input:{
            Cle: values.Cle,
            Value: values.Value,
        }}})
       onResetClient()
    }


   const handleAddClient = () => {
    acces_send("param_post_add_action")
        formAddClient.setFieldsValue({
            Id: "",
            Cle: "",
            Value: "",
            CreatedAt: "",
            UpdatedAt: "",

        })

        dispatch(
            setshowDivsConditionsClient({
                showDivsConditionsName: "addClient",
                showDivsConditionsValue: true,
            })
        );

    }



    const onResetClient = async () => {
        /**edit item list */
        dispatch(
            setshowDivsConditionsClient({
                showDivsConditionsName: "editClient",
                showDivsConditionsValue: false,
            })
        );
        dispatch(
            setshowDivsConditionsClient({
                showDivsConditionsName: "addClient",
                showDivsConditionsValue: false,
            })
        );
        await dispatch(setAddClientActions({
            addClientConstraintsDataNameChange: "Cle",
            addClientConstraintsDataValueChange: ""
        }));
        await dispatch(setAddClientActions({
            addClientConstraintsDataNameChange: "Value",
            addClientConstraintsDataValueChange: ""
        }));
        await dispatch(setAddClientActions({
            addClientConstraintsDataNameChange: "Id",
            addClientConstraintsDataValueChange: ""
        }));
        await dispatch(setAddClientActions({
            addClientConstraintsDataNameChange: "CreatedAt",
            addClientConstraintsDataValueChange: ""
        }));
        await dispatch(setAddClientActions({
            addClientConstraintsDataNameChange: "UpdatedAt",
            addClientConstraintsDataValueChange: ""
        }));




    }

    ////****************** function Filter *****************///////
    //*********************** search header **************///////
    const  handleSearchRow = async (event: any) => {
        SearchByKeyWordClient({variables:{where:{or: [{cleContains:event.target.value},{valueContains:event.target.value}]},pagination:{offset:0,limit:10}}})
        dispatch((setFilterClientActions({
            FilterClientNameChange: "search",
            FilterClientValueChange: event?.target?.value
        })))

    };
    const onChangeFolder = async (e: any) => {
     
        await dispatch(setFilterClientActions({
            FilterClientNameChange: "type",
            FilterClientValueChange: e.target.value
        }));
    }


  

    const disabledsubmitbutton= async(type:any) =>{
      if(type === "disabled"){
            await dispatch(setshowDivsConditionsClient({
                showDivsConditionsName: "disablesubmitform",
                showDivsConditionsValue: false
            }));
        }else if(type === "notdisabled"){
            await dispatch(setshowDivsConditionsClient({
                showDivsConditionsName: "disablesubmitform",
                showDivsConditionsValue: true
            }));
        }
        
    }
    ////////****** Edit Client ***************////
    const handleEditClient = async (data: any,permission:string) => {
        acces_send(`${permission}_action`)
        formAddClient.setFieldsValue({
            Id: data?.id,
            Cle:data?.row?.Cle,
            Value: data?.row?.Value,
            CreatedAt: data?.row?.CreatedAt,
            UpdatedAt: data?.row?.UpdatedAt,
        })
        dispatch(
            setshowDivsConditionsClient({
                showDivsConditionsName: "editClient",
                showDivsConditionsValue: true,
            })
        );
       
                dispatch(setshowDivsConditionsClient({
                    showDivsConditionsName: "elementSelected",
                    showDivsConditionsValue: data?.id
                }));

                dispatch(setAddClientActions({
                    addClientConstraintsDataNameChange: "Id",
                    addClientConstraintsDataValueChange: data?.id
                }));

                dispatch(setAddClientActions({
                    addClientConstraintsDataNameChange: "Cle",
                    addClientConstraintsDataValueChange: data?.row?.Cle
                }));
                dispatch(setAddClientActions({
                    addClientConstraintsDataNameChange: "Value",
                    addClientConstraintsDataValueChange: data?.row?.Value
                }));
                dispatch(setAddClientActions({
                    addClientConstraintsDataNameChange: "CreatedAt",
                    addClientConstraintsDataValueChange: data?.row?.CreatedAt
                }));
                dispatch(setAddClientActions({
                    addClientConstraintsDataNameChange: "UpdatedAt",
                    addClientConstraintsDataValueChange: data?.row?.UpdatedAt
                }));
    }
    const getOneClientToEdit = useGetOneClientInfoToEdit(handleEditClient)
    /********************************** */
    const handelSearch = (word: string, tab: any) => {
        if (word.trim() === "") return tab;

        // eslint-disable-next-line array-callback-return
        const res = tab.filter((obj: any) => {
            if(typeof word === "string"){
               
                if (
                    obj.Cle.toLowerCase().includes(word.toLowerCase()) ||
                obj.Value === word 
                
                ) return obj;
            }
           
        });

        return res;
    };
    /*******************Crud *********************** */
    const Deleteselecteditem = async (id: any) => {
        try {
            const res = await DeleteData(`/param/delete/${id}`);
           
            if (res.status === 200 && res.data.code === 200) {
                message.success({ content: t('successdelete'), duration: 2 })
            }else if(res.status === 200 && res.data.code !== 200){
                message.error({ content: t('messageerroroperation'), duration: 2 })
            }

        } catch (e) {
            message.error({ content: t('messageerroroperation'), duration: 2 })
        }
    }

    const Deletemultipleselecteditem = async () => {

        try {
            const listid = listitemselected.join()
           

            const res = await DeleteData(`/multiDelete/params/${listid}`);
           
            if (res.status === 200  && res.data.code === 200) {

                dispatch(
                    setPaginationPropsClient({
                        PaginationPropsNameChange: "id",
                        PaginationPropsValueChange: [],
                    })
                );
                message.success({ content: t('successdelete'), duration: 2 })
            }else if(res.status === 200 && res.data.code !== 200){
                message.error({ content: t('messageerroroperation'), duration: 2 })
            }

        } catch (e) {
            message.error({ content: t('messageerroroperation'), duration: 2 })
        }
    }

    return ({
        handleAddClient,
        onResetClient,
        formAddClient,
        handleSearchRow,
        onChangeFolder,
        handleShowModal,
        visible,
        handleCancel,

        visibleExport,

        CopyUrlAuditeur,
        handleEditClient,
        values,

        handelSearch,
        Deleteselecteditem,
        Deletemultipleselecteditem,
        
        disabledsubmitbutton,
        getOneClientToEdit,
        handleSubmitEditingClient,
        handleSubmitAddClient
    })
}
export default Hooks