const ConfigData = [
    {
        id:1,
        checkbox : true,
        select : "taha",
        input : "Mot clé",
    },
    // {
    //     id:2,
    //     checkbox :false,
    //     select : "Mahmoud",
    //     input : "Lieu",
    // },
    // {
    //     id:3,
    //     checkbox :false,
    //     select :["Taha"],
    //     input : "Lieu",
    // },
    // {
    //     id:4,
    //     checkbox : true,
    //     select : "taha",
    //     input : "Licence",
    // },

]
export default ConfigData;