
export function setPaginationPropsReferent(e:any){
    return{
        type:"SET_PaginationPropsReferent",
        payload:e,
    }
}

export function setshowDivsConditionsReferent(e:any){
    return {
        type : "SET_showDivsConditionsReferent",
        payload:e
    }
}

export function setAddReferentActions(e:any){
    return{
        type:"SET_AddReferentActions",
        payload:e,
    }
}

export function setFilterReferentActions(e:any){

    return{
        type:"SET_FilterReferent",
        payload:e,
    }

}

export function setEditReferentActions(e:any){
    return{
        type:"SET_EditReferentActions",
        payload:e,
    }
}

export function setDataReferentActions(e:any){
    return{
        type:"SET_DataReferentActions",
        payload:e,
    }
}

export function setDetailReferentActions(e:any){
    return{
        type:"SET_DetailReferentActions",
        payload:e,
    }
}

