import React from "react";
import { useEffect,useState } from "react";
import VideopushHeader from "./components/videopushHeader";
import VideopushData from "./components/videopushData";
import { useTranslation } from "react-i18next";
import {Grid} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import { Hooks } from "./utils/hooks";
import { useAppSelector } from "../../../Redux/hooks";
import { getUserData } from "../../../userData/actions";
import { NoVideo } from "./noVideo";
 import { useDispatch } from "react-redux";
import { Loader } from "@empreinte/components.loader";
const Videopush=()=>{
    const videoData = useAppSelector((state) => state.videopushReducer.InfoVideo);
    // const id = useAppSelector((state)=> state.videopushReducer.InfoVideo.video.id)
    const loader = useAppSelector((state:any) => state.videopushReducer.isLoading)
    const isPush = useAppSelector((state)=>state.videopushReducer.isPush)

    const { t } = useTranslation();
    const navigate = useNavigate();
    const {sendVideoBackward,videoPushData}= Hooks()
    const dispatch= useDispatch()
    useEffect(() => {
      dispatch(getUserData())
      videoPushData()
  }, [])

    const handleClick = () => {
        if (videoData && videoData?.video && videoData?.video[0].id) {
          const id = videoData?.video[0].id;
          sendVideoBackward(id);
        }
      };


  
    return(

        <div className="videoPush">
        {isPush && !videoData.video.length ? ( 
             <NoVideo/>
           ):(
            
                <>
            <Loader isOpen={loader}/>
                 <Grid>
              <VideopushHeader onButtonClick={handleClick}/>
              <VideopushData/>
              </Grid>
              
       
              </>
             
            )
            }
        </div>
       
    )
}

export default Videopush