import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAddVideoplayerActions, setFilterVideoplayerActions, 
    setshowDivsConditionsVideoplayer, setDataVideoplayerActions, setPaginationPropsVideoplayer} from "../store/videoplayerActions"
import { Form, message } from "antd";
import { useAppSelector } from "../../../Redux/hooks";
import {
    GetData,
    PostData,
  
    DeleteData,
} from "../../../APIcalls";

const Hooks = () => {

    const [visible, setVisible] = useState<any>(false)
    const [visibleExport] = useState<any>(false)
    const [statusSuccessMessages, setStatusSuccessMessages] = useState<any>(true)
    const [statusErrorMessages, setStatusErrorMessages] = useState<any>(true)
    const dispatch = useDispatch()
    const [formAddVideoplayer] = Form.useForm()

    // use Selector redux
    const values = useAppSelector((state) => state.VideoplayerReducer.addVideoplayerConstraintsData)

    const listitemselected = useAppSelector((state) => state.VideoplayerReducer.paginationPropsVideoplayer.id)

    const Videoplayercondition = useAppSelector((state) => state.VideoplayerReducer.showDivsConditionsVideoplayer)

    useEffect(() => {
        formAddVideoplayer.setFieldsValue({
            id: values.id,
            type: values.type,
            value: values.value,
            
        })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    ////*************** show Modal Function ******************/////////
    const handleShowModal = () => {
        setVisible(true)
    }
    const handleCancel = () => {
        setVisible(false)
    }
  
    /////////******* copy lien intégration */////////////
    const CopyUrlAuditeur = async () => {
        if ((document.getElementById("myUrlAuditeurVideoplayer") as HTMLFormElement).value === "") {
            await setStatusErrorMessages(false)
            return (
                statusErrorMessages && message.error({ content: "Cet champ est Vide", duration: 2 }).then(
                    async () => {
                        setStatusErrorMessages(true)
                    }
                )
            )
        } else {

            (document.getElementById("myUrlAuditeurVideoplayer") as HTMLFormElement).select();
            document.execCommand("Copy");
            await setStatusSuccessMessages(false)
            statusSuccessMessages && await message.success({ content: "cet champ est copié avec succée", duration: 2 }).then(
                async () => {
                    setStatusSuccessMessages(true)
                }
            );
        }
    }

    /////********* show add edit videoplayer ****************/////
    const handleAddVideoplayer = () => {
        formAddVideoplayer.setFieldsValue({
            id:"",
            type: "",
            value: "",
         
        })


        dispatch(
            setshowDivsConditionsVideoplayer({
                showDivsConditionsName: "addVideoplayer",
                showDivsConditionsValue: true,
            })
        );

    }



    const onResetVideoplayer = async () => {
        /**edit item list */
        dispatch(
            setshowDivsConditionsVideoplayer({
                showDivsConditionsName: "editVideoplayer",
                showDivsConditionsValue: false,
            })
        );
        dispatch(
            setshowDivsConditionsVideoplayer({
                showDivsConditionsName: "addVideoplayer",
                showDivsConditionsValue: false,
            })
        );
        await dispatch(setAddVideoplayerActions({
            addVideoplayerConstraintsDataNameChange: "id",
            addVideoplayerConstraintsDataValueChange: ""
        }));
        await dispatch(setAddVideoplayerActions({
            addVideoplayerConstraintsDataNameChange: "type",
            addVideoplayerConstraintsDataValueChange: ""
        }));
        await dispatch(setAddVideoplayerActions({
            addVideoplayerConstraintsDataNameChange: "value",
            addVideoplayerConstraintsDataValueChange: ""
        }));
      
      




    }

    ////****************** function Filter *****************///////
    //*********************** search header **************///////
    const handleSearchRow = async (event: any) => {
        if (event.key === 'Enter') {
            await dispatch(setFilterVideoplayerActions({
                FilterVideoplayerNameChange: "search",
                FilterVideoplayerValueChange: event.target.value
            }));
        }
    }
    const onChangeFolder = async (e: any) => {
        await dispatch(setFilterVideoplayerActions({
            FilterVideoplayerNameChange: "type",
            FilterVideoplayerValueChange: e
        }));
    }

    /////**************** Form Add Videoplayer *******************/////////
    const onFinishAddVideoplayer = (values: any) => {
       
        if (Videoplayercondition.addVideoplayer === true) {
            const obj={
                "cle":values['type'],
                "value":values['value'],
                "type":"internet"
            }
           AddData(obj)

       } else if (Videoplayercondition.editVideoplayer === true) {
        const obj={
           "key":values['type'],

            "value":values['value'],
            "id":Videoplayercondition.elementSelected
        }
           ModifData(obj, Videoplayercondition.elementSelected)
       }
    }
    const onFinishFailedAddVideoplayer = (errorInfo: any) => {
    }
    const onValueFormUserchangeVideoplayer = async (val: any) => {
        if (val.title) {
            await dispatch(setAddVideoplayerActions({
                addVideoplayerConstraintsDataNameChange: "key",
                addVideoplayerConstraintsDataValueChange: val.key
            }));
        } else if (val.descVideoplayer) {
            await dispatch(setAddVideoplayerActions({
                addVideoplayerConstraintsDataNameChange: "value",
                addVideoplayerConstraintsDataValueChange: val.value
            }));
        } 

    }
    ////////****** Edit Videoplayer ***************////
    const handleEditVideoplayer = async (record: any) => {
      

        await dispatch(setshowDivsConditionsVideoplayer({
            showDivsConditionsName: "elementSelected",
            showDivsConditionsValue: record.id
        }));


        await dispatch(setAddVideoplayerActions({
            addVideoplayerConstraintsDataNameChange: "id",
            addVideoplayerConstraintsDataValueChange: record.id
        }));

        await dispatch(setAddVideoplayerActions({
            addVideoplayerConstraintsDataNameChange: "type",
            addVideoplayerConstraintsDataValueChange: record.type
        }));
        await dispatch(setAddVideoplayerActions({
            addVideoplayerConstraintsDataNameChange: "value",
            addVideoplayerConstraintsDataValueChange: record.value
        }));
     
        formAddVideoplayer.setFieldsValue({
          
            id: record.id,
            type: record.type,
            value: record.value,
           
        })
        await dispatch(
            setshowDivsConditionsVideoplayer({
                showDivsConditionsName: "editVideoplayer",
                showDivsConditionsValue: true,
            })
        );

    }
    /********************************** */
    const handelSearch = (word: string, tab: any) => {
        if (word.trim() === "") return tab;

        // eslint-disable-next-line array-callback-return
        const res = tab.filter((obj: any) => {
            if (obj.type.toLowerCase().includes(word.toLowerCase())) return obj;
        });
        
        return res;
    };


/*******************Crud *********************** */
const getAllData = async () => {
    try {
        const res = await GetData(`/player-parameters/internet-parameters`);

        let globaldata: any[] = []
        res.data.playerParameters.forEach((item:any, key:any, value:any )=>{
            
         
           value = Object.keys(item).find(element => element !== 'id')
           
           const obj={
            id: item['id'],
            type: value ,
            value: item[value] 
            }
            
            globaldata.push(obj)
        })
       
        if (res.status === 200) {
            dispatch(setDataVideoplayerActions(globaldata));
        }

    } catch (e) {
     
    }
}
const AddData = async (data:any)=> {
  
    try {
        const body={"empPlayerParametersForm":data }
        const res = await PostData(`/player-parameters/add`, body);
        if(res.status === 200){
            await dispatch(
                setshowDivsConditionsVideoplayer({
                    showDivsConditionsName: "addVideoplayer",
                    showDivsConditionsValue: false,
                })
            );
            
        }
      } catch (e) {
      
      }
 }
const ModifData = async (data: any, id: any) => {
    try {
       
        
        const res = await PostData(`/player-parameters/update-parameter`, data);
       
        if (res.status === 200) {
            await dispatch(
                setshowDivsConditionsVideoplayer({
                    showDivsConditionsName: "editVideoplayer",
                    showDivsConditionsValue: false,
                })
            );
            //getAllData() 
        } else {
            await dispatch(
                setshowDivsConditionsVideoplayer({
                    showDivsConditionsName: "messageerror",
                    showDivsConditionsValue: "error",
                })
            );

        }
    } catch (e) {
    
    }
}
    
const Deleteselecteditem = async (id:any)=> {
    try {
        const res = await DeleteData(`/player-parameters/delete/${id}`);
        if(res.status === 200){
            getAllData()
        }else{
            await dispatch(
                setshowDivsConditionsVideoplayer({
                    showDivsConditionsName: "messageerror",
                    showDivsConditionsValue: "error",
                })
            );

        }
      } catch (e) {
      }
 }

 const Deletemultipleselecteditem = async()=> {
     
    try {
        const listid =listitemselected.join()
        
        const res = await DeleteData(`/multiDelete/player-parameters/${listid}`);
        if(res.status === 200){
            
            dispatch(
                setPaginationPropsVideoplayer({
                    PaginationPropsNameChange: "id",
                    PaginationPropsValueChange: [],
                })
            );
            getAllData()
        }else{
        

        }
      } catch (e) {
      }
 }


    return ({
        handleAddVideoplayer,
        onResetVideoplayer,
        formAddVideoplayer,
        handleSearchRow,
        onChangeFolder,
        handleShowModal,
        visible,
        handleCancel,
     
        visibleExport,
        
        CopyUrlAuditeur,
        onFinishAddVideoplayer,
        onFinishFailedAddVideoplayer,
        onValueFormUserchangeVideoplayer,
        handleEditVideoplayer,
        values,

        handelSearch,
        getAllData,
        Deleteselecteditem,
        Deletemultipleselecteditem
       
    })
}
export default Hooks