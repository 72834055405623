import {
  AddBoxOutlined,
  HomeOutlined,
  Close,
  CheckCircleOutlineOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Button, Link, Breadcrumbs } from "@mui/material";
import { useAppSelector } from "../../../Redux/hooks";
import hooks from "../utils/hooks";

const MetadonnesHeader = () => {
  const showDivCondition = useAppSelector(
    (state) => state.MetadonnesReducer.showDivsConditionsMetadonnes
  );
  const { handleAddMetadonnes, onResetMetadonnes } = hooks();
  const { t } = useTranslation();
  return (
    <div>
      <div className={"add_div"}>
        <div>
          <span className={"profile_title"}>{t("metadonnes.title")}</span>
        </div>

        <div className={"div_btn_add_cancel"}>
          {!showDivCondition.addMetadonnes &&
          !showDivCondition.editMetadonnes ? (
            <Button
              className={"btnSubmit"}
              startIcon={<AddBoxOutlined style={{ color: "white" }} />}
              onClick={handleAddMetadonnes}
              style={{ backgroundColor: "#1890FF" }}
            >
              <span className={"spn_add"} style={{ color: "white" }}>
                {t("add")}
              </span>
            </Button>
          ) : (
            <div className={"div_btn_add_cancel"}>
              <Button
                className={"btnCancel"}
                startIcon={<Close style={{ color: "#1890FF" }} />}
                style={{
                  backgroundColor: "white",
                  border: "solid 2px #1976D2",
                }}
                onClick={onResetMetadonnes}
              >
                <span style={{ color: "#1890FF" }} className={"spn_cancel"}>
                  {t("cancel")}
                </span>
              </Button>

              <Button
                className={"Submit"}
                startIcon={
                  <CheckCircleOutlineOutlined style={{ color: "white" }} />
                }
                style={{ backgroundColor: "#1890FF" }}
              >
                <span style={{ color: "white" }} className={"spn_add"}>
                  {t("validate")}
                </span>
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={"Breadcrumb"}>
        <Breadcrumbs>
          <Link underline="none" color="inherit" href="">
            <span>
              <HomeOutlined className={"home_icon"} />
            </span>
          </Link>
          <Link underline="none" color="inherit" href="">
            {t("Configuration")}
          </Link>
          <Link
            underline="none"
            color="inherit"
            href=""
            className="pointer"
            onClick={onResetMetadonnes}
          >
            {t("metadonnes.title")}
          </Link>
          {showDivCondition.addMetadonnes ? (
            <Link
              underline="none"
              color="inherit"
              href=""
              className="pointer"
              onClick={onResetMetadonnes}
            >
              {t("metadonnes.add")}
            </Link>
          ) : null}
        </Breadcrumbs>
      </div>
    </div>
  );
};

export default MetadonnesHeader;
