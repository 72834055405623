export function setAddMediaActions(e) {
    return { type: "SET_AddMEDIAActions", payload: e };
}
export function resetState() {
    return { type: "RESET_STATE" }
}
export function getSkins(e) {
    return { type:"GET_SKINS",payload:e }
}
export function setSkin(e) {
    return { type:"SET_AddSkin", payload:e }
}
export function setTotalRecord(e){
    return { type:"SET_TOTAL_SKINS",payload:e }
}
export function setSelectedSkin(e){
    return { type:"SET_SELECTED_SKIN", payload:e }
}
export function skinId(e){
    return { type: "SKIN_ID" , payload:e }
}
export function setSkinShowCondition(e){
    return { type: "SET_SKIN_CONDITION", payload:e }
}
export function getUrlPlayer(e){
    return { type:"GET_URL_PLAYER",payload:e}
}
export function setLoaderBoxes(e){
    return { type:"SKIN_BOXES_LOADER", payload:e}
}
export function setEditMode(e){
    return { type:"EDIT_MODE" , payload:e}
}
export function setAddMode(e) {
    return { type: "ADD_MODE" , payload:e}
}
export function setRegisteredSkin(e){
    return  { type:"SET_REGISTERED_SKIN" , payload:e}
}
export function setTypeSkin(e){
    return { type:"SKIN_TYPE", payload:e}
}

export function setLoadingSkins(e){
    return { type:"SET_LOADING_SKINS",payload:e}
}
export function setResetSkinPage(e){
    return { type : "RESET_SKIN_PAGE" , payload: e}
}
export function setselectedTheme(e){
    return { type : "SET_SELECTED_THEME",payload :e}
}
export function setPaginationSkin(e){
    return {type : "SET_PaginationSkin",payload: e}
}
export function setThemesSkin(e){
    return { type : "SET_THEMES_SKIN", payload:e}
}
export function setChannelsSkin(e){
    return { type : "SET_CHANNELS_SKIN", payload:e}
}
export function setThemeSelectedSkin(e){
    return { type : "SET_THEMES_SELECTED_SKIN",payload:e}
}
export function setChannelSelectedSkin(e){
    return { type : "SET_CHANNELS_SELECTED_SKIN",payload:e}
}
export function setVideoChannels(e){
    return { type: "SET_VIDEOS_CHANNELS" , payload:e}
}
export function getLatesVideoId(e){
    return { type: "GET_LATEST_VIDEO_ID", payload:e}
}
export function setErrorVideoUpload(e){
    return { type: "SET_ERROR_VIDEO" , payload:e}
}