import { useTranslation } from "react-i18next";
import "../Mooc.scss";

export const DataMooc = () => {
  const { t } = useTranslation();

  const Sorted = (a: any, b: any) => {
    if (!a.type || !b.type) return 1;
    const A = a.type?.toLowerCase()?.trim();
    const B = b.type?.toLowerCase()?.trim();
    return A > B ? 1 : -1;
  };

  const columns = [
    {
      headerName: t("Configuration.Mooc.Header.type"),
      field: "type",
      key: 2,
      sorter: (a: any, b: any) => Sorted(a, b),
      sortDirections: ["descend", "ascend", "descend"],
      width: 750,
    },
    {
      headerName: (
        <span className="DateCol">{t("Configuration.Mooc.Header.value")}</span>
      ),
      field: "value",
      key: 3,
      sortDirections: ["descend", "ascend", "descend"],
      width: 750,
    },
  ];

  const data = {
    totalElements: 22,
    content: [
      { id: "1", value: "Daughter", type: "Business Development" },
      { id: "2", value: "Daughter", type: "Research and Development" },
      { id: "3", value: "Cellar", type: "Engineering" },
      { id: "4", value: "Daughter", type: "Sales" },
      { id: "5", value: "Cellar", type: "Services" },
      { id: "6", value: "Moldy", type: "Business Development" },
      { id: "7", value: "Like", type: "Engineering" },
      { id: "8", value: "Daughter", type: "Support" },
      { id: "9", value: "Like", type: "Sales" },
      { id: "10", value: "Advice", type: "Services" },
      { id: "11", value: "Moldy", type: "Engineering" },
      { id: "12", value: "Advice", type: "Business Development" },
      { id: "13", value: "Cellar", type: "Support" },
      { id: "14", value: "Daughter", type: "Business Development" },
      { id: "15", value: "Greasy", type: "Engineering" },
      { id: "16", value: "Like", type: "Services" },
      { id: "17", value: "Daughter", type: "Research and Development" },
      { id: "18", value: "Daughter", type: "Sales" },
      { id: "19", value: "Flashy", type: "Services" },
      { id: "20", value: "Flashy", type: "Support" },
      { id: "21", value: "Daughter", type: "Support" },
      { id: "22", value: "Moldy", type: "Engineering" },
    ],
  };
  const dataContent = data.content;

  return {
    columns,
    data,
    dataContent,
  };
};
