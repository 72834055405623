import gql from "graphql-tag";

export const Get_All_Clients = gql`
mutation ($where:ParamWhereInput $pagination: Pagination) { 
    Params(where:$where pagination:$pagination orderBy:{field:CREATED_AT direction:DESC}) {
    totalCount
        edges{
            node{
                Id
                Cle
                Value
                CreatedAt
                UpdatedAt
                }
            }
        }
}`;

export const Get_One_Client_Info = gql`
        mutation ($where:ParamWhereInput $pagination:Pagination)  {
            Params (where: $where pagination:$pagination) {
                totalCount
                edges{
                    node{
                        Id
                        Cle
                        Value
                        CreatedAt
                        UpdatedAt
                    }
                }
            }
        }
      `



export const Upadte_Client = gql`
      mutation ($input: UpdateParamInput!) {
        updateParam(input: $input) 
          {
            Id
            Cle
            Value
            CreatedAt
            UpdatedAt
  }}`

export const Add_Client = gql`
    mutation($input:CreateParamInput!) {
        createParam(input:$input){
            Id
            Cle
            Value
            CreatedAt
            UpdatedAt
        }
    }`


export const Delete_Client = gql`
    mutation ($id:Int) { 
        DeleteParam(id:$id) 
    }
`

export const Search_Client_By_Type = gql`
    mutation ($where: ParamWhereInput $pagination:Pagination  ) 
        { Params (where: $where pagination:$pagination orderBy:{field:CREATED_AT direction:DESC} ) {
            totalCount
                edges{
                    node{
                        Id
                        Cle
                        Value
                        CreatedAt
                        UpdatedAt
                        }
                    }
                }
        }
`



export const multiDeleteClient = gql`
    mutation ($id:[Int]){
        DeleteParams(id:$id){
            message
            code
        }
    }
    `