import React, { useEffect, useState } from "react";
import UseActionMenu from "./MoocUserTable";
import { DataGrid } from "@mui/x-data-grid";

import {
  setPaginationPropsMooc,
  setshowDivsConditionsMooc,
} from "../../store/MoocActions";
import { Hooks } from "../../utils/hooks";

import { DataMooc } from "../../utils/Data";
import { useAppSelector } from "../../../../Redux/hooks";
import { useDispatch } from "react-redux";

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;

function useDataTableMedia({ columns, dataSource, updateEntityPath }) {
  const dispatch = useDispatch();
  const valuePagination = useAppSelector(
    (state) => state.MoocReducer.paginationPropsMooc
  );

  const { dataContent } = DataMooc();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize] = useState(DEFAULT_PAGE_SIZE);
  const [datasource, setDataSource] = useState(dataContent);
  const { valuesSearch, handelSearch } = Hooks();

  const onSelectChange = (selectedRowKeys, record) => {
    let filter;
    filter = [];
    filter = dataSource.content
      .filter((item) => {
        return !selectedRowKeys.includes(item.id);
      })
      .map((ele) => ele.id);
    selectedRowKeys = [...valuePagination.id, ...selectedRowKeys];
    let uniqItems;
    let uniqItemsFilter;
    uniqItems = Array.from(new Set(selectedRowKeys));
    uniqItemsFilter = uniqItems.filter((item) => {
      return !filter.includes(item);
    });

    dispatch(
      setPaginationPropsMooc({
        PaginationPropsNameChange: "id",
        PaginationPropsValueChange: uniqItemsFilter,
      })
    );
    dispatch(
      setshowDivsConditionsMooc({
        showDivsConditionsName: "elementSelected",
        showDivsConditionsValue: uniqItemsFilter.length,
      })
    );
    setSelectedRowKeys(uniqItemsFilter);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
  };

  const updatedColumns = [
    ...columns,
    {
      headerName: "Actions",
      key: "action",
      renderCell: (record, action) => {
        return <UseActionMenu record={record} />;
      },
      width: 260,
    },
  ];
  const resetPagination = () => {
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current - 1);
  };

  useEffect(() => {
    const data = handelSearch(valuesSearch.search, dataContent);
    setDataSource(data);
  }, [valuesSearch.search]);

  const DataTable = () => (
    <div
      style={{
        height: 700,
        width: "98%",
        marginTop: "1%",
        border: "none",
        marginLeft: "1%",
      }}
    >
      <DataGrid
        rows={datasource && datasource}
        columns={updatedColumns}
        rowKey={(record) => record.id}
        onChange={handleTableChange}
        rowClassName={"DataTable__custom-row"}
        onRow={(record, index) => ({
          index,
        })}
        rowSelection={rowSelection}
        checkboxSelection
      />
    </div>
  );

  return {
    DataTable,
    selectedRow,
    selectedRowKeys,
    currentPage,
    pageSize,
    resetPagination,
  };
}

export default useDataTableMedia;
