import React,{useEffect,useState} from 'react'
import VideoshomeHeader from './components/videoshomeHeader';
import VideoshomeTable from "./components/List/videoshomeTable";
//import AddEditVideoshome from "./components/AddEdit/addEdit";
import './videoshome.scss'
import {useAppSelector} from "../../../Redux/hooks";
import Hooks from "./utils/hooks";
import {Grid} from "@material-ui/core";
import { Loader } from '@empreinte/components.loader';
import { getUserData } from '../../../userData/actions';
import { useDispatch } from 'react-redux';

const Videoshome = () => {

   // const showDivCondition = useAppSelector((state:any) => state.VideoshomeReducer.showDivsConditionsVideoshome)
    const dispatch = useDispatch();
    const loader = useAppSelector((state:any)=> state.VideoshomeReducer.isLoading)
    const {getListChannel} = Hooks()

  

    useEffect(() => {
        dispatch(getUserData())
    }, [])
    useEffect(()=>{
        getListChannel();
    },[])
    

    return (
        <div className={"divGlobalTable"}>
                  <Grid>
                <Loader isOpen={loader}/>
                    <div>
                    
                        <VideoshomeHeader/>
                        <VideoshomeTable/>
                        </div>
                    </Grid>
        </div>
    )
}

export default Videoshome
