import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setFilterActualityActions } from "../../store/ActualityActions";
import { DataActuality } from "../../utils/Data";
import useDataTableMedia from "./DataTable";
import { Hooks } from "../../utils/hooks";
import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
const ActualityTable = () => {
  const { columns, data } = DataActuality();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { handleSearchRow, valuesSearch } = Hooks();

  const { DataTable, currentPage, pageSize, resetPagination } =
    useDataTableMedia({
      columns: columns,
      dataSource: data,
      updateEntityPath: "update-product",
    });

  return (
    <div>
      <div className="Filter">
        <div className={"div_rect rect"}>
          <div className="rect_filter">
            <FormControl
              className="search"
              style={{ width: "99.5%", marginLeft: "1%" }}
            >
              <TextField
                variant="outlined"
                placeholder={t("Profile.search")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        onClick={(e) =>
                          handleSearchRow({
                            key: "cliecked",
                            value: valuesSearch.searchFake,
                          })
                        }
                        style={{ color: "grey", marginLeft: "10px" }}
                      />
                    </InputAdornment>
                  ),
                }}
                name={"search"}
                onKeyDown={(e) => handleSearchRow(e)}
                onChange={(e) =>
                  dispatch(
                    setFilterActualityActions({
                      FilterActualityNameChange: "searchFake",
                      FilterActualityValueChange: e.target.value,
                    })
                  )
                }
                value={valuesSearch.searchFake}
              />
            </FormControl>
          </div>
        </div>
      </div>

      <div className={"dataTable"}>
        <DataTable />
      </div>
    </div>
  );
};
export default ActualityTable;
