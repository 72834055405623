import React, { useEffect, useState,useLayoutEffect} from "react";

import AddEditPreset from "./components/AddEdit/addEdit";
import PresetTable from "./components/List/presetTable";
import PresetHeader from "./components/presetHeader";
import { useAppSelector,useAppDispatch } from "../../Redux/hooks";
import Hooks from "./utils/hooks";
import {Grid} from "@material-ui/core";
import {GraphQLFetchData} from "./utils/GraphFetchData";
import LoadingScreen from "./utils/LoadingScreen";
import Notfound from '../Notfound'
import "./preset.scss";
import { getUserData } from "../../userData/actions";

const Preset = () => {
    const dispatch = useAppDispatch()
    const {
    formAddPreset,
    usePrevious
  } = Hooks();
  const {GetListPresets} =GraphQLFetchData()
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const showDivCondition = useAppSelector(
    (state) => state.PresetReducer.showDivsConditionsPreset
  );

  const values = useAppSelector(
    (state) => state.PresetReducer.addPresetConstraintsData
  );
  const loadingscreen = useAppSelector(
    (state) => state.ReducerUserData.loading_screen
  );

  const unauthorizedUser = useAppSelector(
    (state) => state.ReducerUserData.unauthorizedUser
  );
  useLayoutEffect(()=>{
    dispatch(getUserData())
},[])
useEffect(() => {
  const offset = (currentPage - 1) * pageSize;
  if(!unauthorizedUser){
    if (searchText !== "") {
      if(searchText !== prevSearchText){
        setCurrentPage(1)
      }
      GetListPresets({
        variables: {
          where: { nameContains: searchText },
          pagination: { limit: pageSize, offset },
        }
      })
    } else {
      GetListPresets({ variables : {
        pagination: { limit: pageSize, offset }
      } });
    }
    
  }
}, [currentPage, pageSize, searchText,unauthorizedUser,showDivCondition.action]);
const prevSearchText = usePrevious(searchText);

  useEffect(() => {
    if (showDivCondition.editPreset) {
      formAddPreset.setFieldsValue({
        Id: values.Id,
        State: values.State,
        Name: values.Name,
        BitrateVideo: values.BitrateVideo,
        BitrateAudio: values.BitrateAudio,
        SamplerateAudio: values.SamplerateAudio,
        Framerate: values.Framerate,
        CreatedAt: values.CreatedAt,
        UpdatedAt: values.UpdatedAt,
        Width: values.Width,
        Height: values.Height,
        SortBitrate: values.SortBitrate,
      });
    }

    if (showDivCondition.addPreset) {
      formAddPreset.setFieldsValue({
        Id: 0, 
        State: false,
        Name:"" ,
        BitrateVideo:"",
        BitrateAudio:"",
        SamplerateAudio:"",
        Framerate: 0,
        CreatedAt: "",
        UpdatedAt: "",
        Width:"", 
        Height:"" ,
        SortBitrate: 0,
      });
    }
  }, [showDivCondition.editPreset, showDivCondition.addPreset]);
  if (loadingscreen) {
    return <LoadingScreen/>
  } else if (unauthorizedUser) {
    return <Notfound/>
}
  return (
    <div className={"divGlobalTable"}>
      {!showDivCondition.addPreset && !showDivCondition.editPreset ? (
        <div>
          <PresetHeader />
          <PresetTable 
          searchText={searchText}
          setSearchText={setSearchText}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPageSize={setPageSize}
          pageSize={pageSize} />
        </div>
      ) : (
        <div>
          <Grid>
            <PresetHeader />
            <AddEditPreset />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Preset;
