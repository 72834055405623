

export function setPaginationPropsMooc(e:any){
    return{
        type:"SET_PaginationPropsMooc",
        payload:e,
    }
}

export function setshowDivsConditionsMooc(e:any){
    return {
        type : "SET_showDivsConditionsMooc",
        payload:e
    }
}

export function setAddMoocActions(e:any){
    return{
        type:"SET_AddMooc",
        payload:e,
    }
}

export function setFilterMoocActions(e:any){

    return{
        type:"SET_FilterMooc",
        payload:e,
    }

}