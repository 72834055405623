import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    GetData,
    PostData,
    DeleteData,
} from "../../../../APIcalls";
import { setPreviewLink ,setVisible,getData,setDisabledVideo,setErrorMessage,setVideoPush, setLoading} from "../store/videopushActions";
import { useNavigate } from "react-router-dom";
export const Hooks=()=>{
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const videoPushData = async () => {
       
        try {
            dispatch(setLoading(true))
          const res = await GetData("/video/video-push");

          if (res.status === 200) {
            dispatch(setVisible(true));
            dispatch(setLoading(false))
          dispatch(getData(res.data));
          dispatch(setVideoPush(true));
          }
          
        } catch (error) {
          dispatch(setErrorMessage(error)
          );
        }
      };
    
    const previewVideo = async (id:any) => {
        dispatch(setVideoPush(true))
    await GetData(`/video/video/preview-link/${id}?y=bo`)
      .then((res) => {
        dispatch(setVisible(true));
        dispatch(setPreviewLink(res?.data?.link));
      })
      .catch((error) => {
        dispatch(setVideoPush(false))
      dispatch(setErrorMessage(error))
      }
      );
  };

  const sendVideoBackward=async(id:any)=>{
    await PostData(`/video/disable-push-video/${id}`)
    .then((res)=>{
        dispatch(setVisible(true));
        dispatch(setDisabledVideo(res.data));
        navigate('/')

    })
    .catch((error) => dispatch(setErrorMessage(error)));
  } 

    return{
        previewVideo,
        videoPushData,
        sendVideoBackward
    }
}
