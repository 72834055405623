import gql from "graphql-tag";

export const graphQL_schema = () => {
    const getThemes = gql`
    mutation{
        Themes(pagination:{
          limit:0,
          offset:0}){
          edges{
            node{
              Id
              Title
              WebtvVideo{
                Id
                Title
              }
              WebtvChannel{
                Id
                Title
              }
            }
          }
        }
      }
    `
    const getChannels = gql`
    mutation{
        channels(pagination:{
          limit:0,
          offset:0}){
          edges{
            node{
              Id
              Title
              
            
            }
          }
        }
      }
    `
    const Get_Recent_Video = gql `
    mutation{
      Videos(pagination:{
        limit:1,
        offset:0
      }, orderBy:{direction:DESC,field:ID}
      where:{
        titleContains:"",
        isVod:true,
        isTranscoded:true,
        deletedAt:null
      }) {
      totalCount
        edges
        {video{
          Id
          TemplateID
          FileTitle
          Title
          GenerateMultipleFormats
          InsertDate
         StartDate
          EndDate
          DispoFo Duration
          Secure
          Downloadable
          Offline
          Description
          Transcription
          Author
          Copyright
          
          KeyWords
          Logo
          MainPage
          IsTranscoded
          PushVideo
          CreatedAt
          UpdatedAt
          AudioDescription
          AudioDescriptionFile
          IsArchived
          Contributor{Id Vignette Username}
          Theme{Id UrlName Title}      
        }}
    }
    }`
  
    return({getThemes,getChannels,Get_Recent_Video})
}