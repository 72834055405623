import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAddReferentActions, setFilterReferentActions, setshowDivsConditionsReferent, 
    setDataReferentActions, setPaginationPropsReferent } from "../store/referentActions"
import { Form, message } from "antd";
import { useAppSelector } from "../../../Redux/hooks";
import {
    GetData,
    PostData,
    UpdateData,
    DeleteData,
} from "../../../APIcalls";

const Hooks = () => {

    const [visible, setVisible] = useState<any>(false)
    const [visibleExport, setVisibleExport] = useState<any>(false)
    const [statusSuccessMessages, setStatusSuccessMessages] = useState<any>(true)
    const [statusErrorMessages, setStatusErrorMessages] = useState<any>(true)
    const dispatch = useDispatch()
    const [formAddReferent] = Form.useForm()

    // use Selector redux
    const values = useAppSelector((state) => state.ReferentReducer.addReferentConstraintsData)
    const listitemselected = useAppSelector((state) => state.ReferentReducer.paginationPropsReferent.id)
    const Referentcondition = useAppSelector((state) => state.ReferentReducer.showDivsConditionsReferent)

    useEffect(() => {
        formAddReferent.setFieldsValue({
            id: values.id,
            url: values.url,
            mode: values.mode,
          
        })

    }, [])


    ////*************** show Modal Function ******************/////////
    const handleShowModal = () => {
        setVisible(true)
    }
    const handleCancel = () => {
        setVisible(false)
    }

    /////////******* copy lien intégration */////////////
    const CopyUrlAuditeur = async () => {
        if ((document.getElementById("myUrlAuditeurReferent") as HTMLFormElement).value === "") {
            await setStatusErrorMessages(false)
            return (
                statusErrorMessages && message.error({ content: "Cet champ est Vide", duration: 2 }).then(
                    async () => {
                        setStatusErrorMessages(true)
                    }
                )
            )
        } else {

            (document.getElementById("myUrlAuditeurReferent") as HTMLFormElement).select();
            document.execCommand("Copy");
            await setStatusSuccessMessages(false)
            statusSuccessMessages && await message.success({ content: "cet champ est copié avec succée", duration: 2 }).then(
                async () => {
                    setStatusSuccessMessages(true)
                }
            );
        }
    }

    /////********* show add edit referent ****************/////
    const handleAddReferent = () => {
        formAddReferent.setFieldsValue({
            id: "",
            url: "",
            mode: "",
            name: "",
            list: "",

        })
        dispatch(
            setshowDivsConditionsReferent({
                showDivsConditionsName: "addReferent",
                showDivsConditionsValue: true,
            })
        );

    }



    const onResetReferent = async () => {
        /**edit item list */
        await dispatch(
            setshowDivsConditionsReferent({
                showDivsConditionsName: "editReferent",
                showDivsConditionsValue: false,
            })
        );
        await dispatch(
            setshowDivsConditionsReferent({
                showDivsConditionsName: "addReferent",
                showDivsConditionsValue: false,
            })
        );
        await dispatch(setAddReferentActions({
            addReferentConstraintsDataNameChange: "id",
            addReferentConstraintsDataValueChange: ""
        }));
        await dispatch(setAddReferentActions({
            addReferentConstraintsDataNameChange: "url",
            addReferentConstraintsDataValueChange: ""
        }));
        await dispatch(setAddReferentActions({
            addReferentConstraintsDataNameChange: "mode",
            addReferentConstraintsDataValueChange: false
        }));
        await dispatch(setAddReferentActions({
            addReferentConstraintsDataNameChange: "name",
            addReferentConstraintsDataValueChange: ""
        }));
        await dispatch(setAddReferentActions({
            addReferentConstraintsDataNameChange: "list",
            addReferentConstraintsDataValueChange: ""
        }));




    }

    ////****************** function Filter *****************///////
    //*********************** search header **************///////
    const handleSearchRow = async (event: any) => {
        if (event.key === 'Enter') {
            await dispatch(setFilterReferentActions({
                FilterReferentNameChange: "search",
                FilterReferentValueChange: event.target.value
            }));
        }
    }
    const onChangeFolder = async (e: any) => {
            await dispatch(setFilterReferentActions({
            FilterReferentNameChange: "type",
            FilterReferentValueChange: e
        }));
    }

    /////**************** Form Add Referent *******************/////////
    const onFinishAddReferent = (values: any) => {
        if (Referentcondition.addReferent === true) {
             AddData(values)

        } else if (Referentcondition.editReferent === true) {

            ModifData(values, Referentcondition.elementSelected)
        }
    }
    const onFinishFailedAddReferent = (errorInfo: any) => {
    
    }
    const onValueFormUserchangeReferent = async (val: any) => {
        if (val.title) {
            await dispatch(setAddReferentActions({
                addReferentConstraintsDataNameChange: "id",
                addReferentConstraintsDataValueChange: val.id
            }));
        } else if (val.descReferent) {
            await dispatch(setAddReferentActions({
                addReferentConstraintsDataNameChange: "url",
                addReferentConstraintsDataValueChange: val.url
            }));
        }

    }
    ////////****** Edit Referent ***************////
    const handleEditReferent = async (record: any) => {

        try {
            const res = await GetData(`/referrer/${record.id}`);
            if (res.status === 200) {
                // await dispatch(setDetailServeurActions(res.data));
                // setVisible(true)  
            

                await dispatch(setshowDivsConditionsReferent({
                    showDivsConditionsName: "elementSelected",
                    showDivsConditionsValue: res.data.id
                }));
        
                await dispatch(setAddReferentActions({
                    addReferentConstraintsDataNameChange: "id",
                    addReferentConstraintsDataValueChange: res.data.id
                }));
        
                await dispatch(setAddReferentActions({
                    addReferentConstraintsDataNameChange: "url",
                    addReferentConstraintsDataValueChange: res.data.url
                }));
                await dispatch(setAddReferentActions({
                    addReferentConstraintsDataNameChange: "mode",
                    addReferentConstraintsDataValueChange: res.data.mode
                }));
               
                await dispatch(
                    setshowDivsConditionsReferent({
                        showDivsConditionsName: "editReferent",
                        showDivsConditionsValue: true,
                    })
                );
                await formAddReferent.setFieldsValue({
                    id: res.data.id,
                    url: res.data.url,
                    mode: res.data.mode,
                    
                })

            }

        } catch (e) {
            // dispatch({
            //   type: ErrorActions.Message_ERROR,
            //   payload: {
            //     msg: e.response.data.message,
            //   },
            // });
        }

     
    }
    /********************************** */
    const handelSearch = (word: string, tab: any) => {
        if (word.trim() === "") return tab;

        const res = tab.filter((obj: any) => {
            if (obj.url.toLowerCase().includes(word.toLowerCase())) return obj;
        });

        return res;
    };



    /*******************Crud *********************** */
    const getAllData = async () => {
        try {
            const res = await GetData(`/dataTable/referrer`);
          
            if (res.status === 200) {
                dispatch(setDataReferentActions(res.data.data));
            }

        } catch (e) {
            // dispatch({
            //   type: ErrorActions.Message_ERROR,
            //   payload: {
            //     msg: e.response.data.message,
            //   },
            // });
        }
    }
    const AddData = async (data:any)=> {
        try {
            const body={"empParametersReferrerForm":data

            }
            const res = await PostData(`/referrer/add`, body);
          
            if(res.status === 200){
                await dispatch(
                    setshowDivsConditionsReferent({
                        showDivsConditionsName: "addReferent",
                        showDivsConditionsValue: false,
                    })
                );
                //getAllData()
                
            }
          } catch (e) {
              
            // dispatch({
            //   type: ErrorActions.Message_ERROR,
            //   payload: {
            //     msg: e.response.data.message,
            //   },
            // });
          }
     }
    const ModifData = async (data: any, id: any) => {
        try {
     
            const body = {
                "id": id, "empParametersReferrerForm": data
            }
            const res = await PostData(`/referrer/edit/${id}`, body);

            if (res.status === 200) {
                await dispatch(
                    setshowDivsConditionsReferent({
                        showDivsConditionsName: "editReferent",
                        showDivsConditionsValue: false,
                    })
                );
                //getAllData() 
            } else {
                await dispatch(
                    setshowDivsConditionsReferent({
                        showDivsConditionsName: "messageerror",
                        showDivsConditionsValue: "error",
                    })
                );

            }
        } catch (e) {
       
        }
    }
    const Deleteselecteditem = async (id:any)=> {
        try {
            const res = await DeleteData(`/referrer/delete/${id}`);
           
            if(res.status === 200){
                getAllData()
            }else{
                await dispatch(
                    setshowDivsConditionsReferent({
                        showDivsConditionsName: "messageerror",
                        showDivsConditionsValue: "error",
                    })
                );

            }
          } catch (e) {
          }
     }

     const Deletemultipleselecteditem = async()=> {
         
        try {
          
            const listid =listitemselected.join()
            const res = await DeleteData(`/multiDelete/referrers/${listid}`);
          
            if(res.status === 200){
                
                dispatch(
                    setPaginationPropsReferent({
                        PaginationPropsNameChange: "id",
                        PaginationPropsValueChange: [],
                    })
                );
                getAllData()
            }else{
            //  dispatch(
            //         setshowDivsConditionsServeur({
            //             showDivsConditionsName: "messageerror",
            //             showDivsConditionsValue: "error",
            //         })
            //     );

            }
          } catch (e) {
          }
     }

    return ({
        handleAddReferent,
        onResetReferent,
        formAddReferent,
        handleSearchRow,
        onChangeFolder,
        handleShowModal,
        visible,
        handleCancel,

        visibleExport,

        CopyUrlAuditeur,
        onFinishAddReferent,
        onFinishFailedAddReferent,
        onValueFormUserchangeReferent,
        handleEditReferent,
        values,

        handelSearch,
        getAllData,

        Deleteselecteditem,
        Deletemultipleselecteditem

    })
}
export default Hooks