import React from "react";
import { useTranslation } from "react-i18next";
import "./videopush.scss";
export const NoVideo=()=>{
    const { t } = useTranslation()
    return(
        <div className="noVideo">
        <img src="/noVideo.png" alt={"no video pushed"} style={{zIndex:'0'}} />
        <span className="textNoVideo">{t('configuration.Videopush.noVideo')}</span>

        </div>
    )
}