
export const VideoshomeList = () => {

    const FilterVideoshome = () => {
        return (
            {
                search: "",
                searchFake: "",
                type: "",
            }
        )
    }

    const paginationPropsVideoshome = () => {
        return (
            {
                order: 'descend',
                pageSize: 10,
                columnKey: 0,
                current: 1,
                id: [],
                idLive: 0,
            }
        )
    }

  

    const AddVideoshomeConstraintsData = () => {
        return (
            {
                id: "",
                url: "",
                titre: "",
                theme: "",
                canal: "",
            }
        )
    }
    const DataVideoshome= () =>{
        return []
    }




    return ({
        paginationPropsVideoshome,
       
        AddVideoshomeConstraintsData,
        FilterVideoshome,
        DataVideoshome
    })
}
