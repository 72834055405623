import { useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import { setPresetDataActions } from "../store/presetActions";
import { Get_All_Presets ,Add_Preset,Upadte_Preset,Delete_Preset,Search_presets_By_Type} from "./GraphQL";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import {setshowDivsConditionsPreset,setPaginationPropsPreset,restartData} from '../store/presetActions'

export const GraphQLFetchData = () => {
  
  const { t } = useTranslation();
  const dispatch = useDispatch();

const [GetListPresets] = useMutation(
  Get_All_Presets,
  {
    fetchPolicy: "no-cache",
    variables: {},
    onCompleted: (data) => {
        dispatch(
            setPresetDataActions({
              List: data?.presets?.edges,
              msg: "",
              error: false,
            })
          );
          dispatch(
            setPaginationPropsPreset({
              PaginationPropsNameChange: "totalCount",
              PaginationPropsValueChange: data?.presets?.totalCount
            })
          );
    },
    onError:(error)=>{
      dispatch(setPresetDataActions({ error: true, list: [] }));
      message.error({ content: t('messageerroroperation'), duration: 2 })
    }
  }
);
  const [AddPreset] = useMutation(
    Add_Preset,
    {
      fetchPolicy: "no-cache",
      variables: {},
      onCompleted: (data) => {
        dispatch(
          restartData()
        );
        message.success({ content: t('successadd'), duration: 2 })  
      },
      onError:(error)=>{
        message.error({ content: t('messageerroroperation'), duration: 2 })
      }
    }
  );
  const [UpdatePreset] = useMutation(
    Upadte_Preset,
    {
      fetchPolicy: "no-cache",
      variables: {},
      onCompleted: (data) => {
        dispatch(
          setshowDivsConditionsPreset({
            showDivsConditionsName: "editPreset",
            showDivsConditionsValue: false,
          })
        );
        dispatch(
          restartData()
        );
        message.success({ content: t('successedit'), duration: 2 })  
      },
      onError:(error)=>{
        message.error({ content: t('messageerroroperation'), duration: 2 })
      }
    }
  );
  const [DeletePreset] = useMutation(
    Delete_Preset,
    {
      fetchPolicy: "no-cache",
      variables: {},
      onCompleted: (data) => {
        dispatch(
          restartData()
        );
        message.success({ content: t("successdelete"), duration: 2 });
      },
      onError:(error)=>{
        message.error({ content: t('messageerroroperation'), duration: 2 })
      }
    }
  );
  const [SearchByKeyWordPreset] = useMutation(
    Search_presets_By_Type,
    {
      fetchPolicy: "no-cache",
      variables: {},
      onCompleted: (data) => {
        dispatch(setPresetDataActions({
          List: data?.presets?.edges,
          msg: "",
          error: false,
        }));
      },
      onError:(error)=>{
        message.error({ content: t('messageerroroperation'), duration: 2 })
      }
    }
  
  )
  
return {
    GetListPresets,
    AddPreset,
    UpdatePreset,
    DeletePreset,
    SearchByKeyWordPreset
  };
}