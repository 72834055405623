import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import ApolloProvider from "./ApolloProvider";

ReactDOM.render(ApolloProvider, document.getElementById('root'));


reportWebVitals();
