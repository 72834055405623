import { VideoplayerList } from "./videoplayerConstants";
const { showDivsConditionsVideoplayer, AddVideoplayerConstraintsData, paginationPropsVideoplayer,
     FilterVideoplayer, DataVideoplayer, DetailVideoplayer } = VideoplayerList()

const VideoplayerListINITIALSTATE = {
    showDivsConditionsVideoplayer: showDivsConditionsVideoplayer(),
    paginationPropsVideoplayer: paginationPropsVideoplayer(),
    addVideoplayerConstraintsData: AddVideoplayerConstraintsData(),
    filterVideoplayer: FilterVideoplayer(),
    dataVideoplayer: DataVideoplayer(),
    detailVideoplayer: DetailVideoplayer()
}

export const VideoplayerReducer = (state = VideoplayerListINITIALSTATE, action: any) => {

    switch (action.type) {

        case "SET_FilterVideoplayer":
            const { FilterVideoplayerNameChange, FilterVideoplayerValueChange } = action.payload
            return {
                ...state,
                filterVideoplayer: { ...state.filterVideoplayer, [FilterVideoplayerNameChange]: FilterVideoplayerValueChange }
            }
        case "SET_PaginationPropsVideoplayer":
            const { PaginationPropsNameChange, PaginationPropsValueChange } = action.payload
            return {
                ...state,
                paginationPropsVideoplayer: { ...state.paginationPropsVideoplayer, [PaginationPropsNameChange]: PaginationPropsValueChange }
            }
        case "SET_showDivsConditionsVideoplayer":
            const { showDivsConditionsName, showDivsConditionsValue } = action.payload
            const showDivsConditionsObj = { ...state.showDivsConditionsVideoplayer, [showDivsConditionsName]: showDivsConditionsValue }
            return {
                ...state,
                showDivsConditionsVideoplayer: showDivsConditionsObj
            }
        case "SET_AddVideoplayerActions":
            const { addVideoplayerConstraintsDataNameChange, addVideoplayerConstraintsDataValueChange } = action.payload
            return {
                ...state,
                addVideoplayerConstraintsData: { ...state.addVideoplayerConstraintsData, [addVideoplayerConstraintsDataNameChange]: addVideoplayerConstraintsDataValueChange }
            }
        case "SET_DataVideoplayerActions":

            return {
                ...state,
                dataVideoplayer: action.payload
            }
        case "SET_DetailVideoplayerActions":

            return {
                ...state,
                detailVideoplayer: action.payload
            }

        default: {
            return state
        }

    }

}

