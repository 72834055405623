import { useState } from "react";
import MetadonnesHeader from "./components/metadonnesHeader";
import AddEditMetadonnes from "./components/AddEdit/addEdit";
import MetadonnesTable from "./components/List/metadonnesTable";

import frFR from "antd/lib/locale/fr_FR";
import { useAppSelector } from "../../Redux/hooks";

const Metadonnes = () => {
  const showDivCondition = useAppSelector(
    (state) => state.MetadonnesReducer.showDivsConditionsMetadonnes
  );

  const [locale] = useState<any>(frFR);
  return (
    <div>
      {!showDivCondition.addMetadonnes ? (
        <div>
          <MetadonnesHeader />
          <MetadonnesTable />
        </div>
      ) : (
        <div>
          <MetadonnesHeader />
          <AddEditMetadonnes />
        </div>
      )}
    </div>
  );
};

export default Metadonnes;
