import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
	Row,
	Col,
	Input,
	Spin,
	Divider,
	Card,
	Switch,
	Collapse,
} from "antd";
import { setSkin } from '../store/skinActions'
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import "./skinPlayer.scss";
import { SketchPicker } from "react-color";
import { useAppSelector } from "../../../Redux/hooks";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {SkinHooks} from "../utils/Hooks";
import { useDispatch } from "react-redux";
import { setLoadingSkins } from "../store/skinActions";


const { Panel } = Collapse;
export const SkinPlayer = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch()
	const { id, filename } = useParams();
	const tab = useMediaQuery("(min-width: 920px)");
	const history = useNavigate();
	const { formatDuration ,colorStyle,addSkin,updateSkin,resetSkin,createSkin} = SkinHooks();
	const showSkinCondition = useAppSelector((state) => state.skinReducer.AddSkinContraints)
	const DefaultSkin = useAppSelector((state) => state.skinReducer.selectedSkinObject);
	const skinId = useAppSelector((state)=>state.skinReducer.skinId)
	const skinName = DefaultSkin?.name
	const statsPosition = useAppSelector((state)=>state.skinReducer.statsPosition);
	const [btnDisabled,setBtnDisabled]= useState(false)
	const dataSkin = useAppSelector((state)=>state.skinReducer.addSkin)
	
	const [skinSettings, setSkinSettings] = useState({
		id:"",
        name: "",
        color_control_bar: 'rgba(0, 0, 0, 1)',
        color_primary: 'rgba(230, 68, 68, 1)',
        color_secondary: 'rgba(255, 255, 255, 1)',
        color_background: 'rgba(0, 0, 0, 1)',
        control_play_pause_btn: true,
        control_stop_btn: false,
        control_progress_bar: true,
        control_volume: true,
        control_subtitles: true,
        control_transcript: true,
        control_quality: true,
        control_time: true,
        control_speed: true,
        control_audio_tracks: true,
        control_audio_description: true,
        control_chapters: true,
        control_pip: false,
        control_fullscreen: true,
        control_share: true,
        info_title: true,
        info_desc: true,
        info_date: true,
        info_views: true,
        info_duration: true,
        info_thumbnail: true,
        info_files: true,
        info_chapters: true,
        info_copyright: true,
        info_logo: false,
        info_privacy_url: false,
        info_stats: true,
        action_rate: true,
        action_download_video: true,
        action_download_files: true,
        action_download_transcript: true,
        action_loop: true,
        is_editable: true
    });


useEffect(() => {
	
    if (Object.keys(DefaultSkin).length > 0) {
		if(showSkinCondition?.isEdit){
		
			setSkinSettings(DefaultSkin);
		}else{
			setSkinSettings(dataSkin)
		}
    }
}, [DefaultSkin,showSkinCondition]);

	const [themeColors, setThemeColors] = useState({
		color1: colorStyle(DefaultSkin.color_primary, "change"),
		color2: colorStyle(DefaultSkin.color_secondary, "change"),
		color3: colorStyle(DefaultSkin.color_control_bar,"change"),
		color4: colorStyle(DefaultSkin.color_background,"change"),
	});
	const [showColorPicker1, setShowColorPicker1] = useState(false);
	const [showColorPicker2, setShowColorPicker2] = useState(false);
	const [showColorPicker3, setShowColorPicker3] = useState(false);
	const [showColorPicker4, setShowColorPicker4] = useState(false);

	const [name, setName] = useState("");
	const [disabled,setDisabled] = useState(true)
	
	

	const [loaderIn, setLoaderIn] = useState(false);
	const colorPickerRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
	
	///////////////////// switch buttons ////////////////////

	const buttonSection = Object.entries(skinSettings)
    .filter(([key, value]) => key.startsWith('control_'))
    .map(([key, value]) => ({
		label:t(`Skin-Player.${key}`),
        name: key,
        checked: value,
    }))

	const controlButton = Object.entries(skinSettings)
	.filter(([key,value]) => key.startsWith('info_'))
	.map(([key, value]) => ({
		label:t(`Skin-Player.${key}`),
        name: key,
        checked: value,
    }))

	const playerSection = Object.entries(skinSettings)
	.filter(([key,value]) => key.startsWith('action_'))
	.map(([key, value]) => ({
		label:t(`Skin-Player.${key}`),
        name: key,
        checked: value,
    }))

	const switchToggleBtn = (id, e) => {

		if(showSkinCondition.isEdit | showSkinCondition.isAdd){
			if (skinSettings.hasOwnProperty(id)) {
				setSkinSettings(prevState => ({
					...prevState,
					[id]: e // Update the property corresponding to the id with the value of e
				}));
				dispatch({
					type:'SET_SELECTED_SKIN',
					payload:skinSettings
				})
				dispatch(setSkin({
					addSkinDataNameChange: id,
					addSkinDataValueChange: e
				  }));
			}
		}
		if(id){
			setDisabled(false)
		}
	};
	const handleColorClick = (index) => {
		switch (index) {
			case 0:
				setShowColorPicker1(!showColorPicker1);
				break;
			case 1:
				setShowColorPicker2(!showColorPicker2);
				break;
			case 2:
				setShowColorPicker3(!showColorPicker3);
				break;
			case 3:
				setShowColorPicker4(!showColorPicker4);
				break;
			default:
				break;
		}
	};

	const handleColorChange = (type, newColor) => {
		switch (type) {
			case 'color_primary':
				setThemeColors({
					...themeColors,
					color1: { r: newColor.rgb.r, g: newColor.rgb.g, b: newColor.rgb.b, a: newColor.rgb.a },
				});
				break;
			case 'color_secondary':
				setThemeColors({
					...themeColors,
					color2: { r: newColor.rgb.r, g: newColor.rgb.g, b: newColor.rgb.b, a: newColor.rgb.a },
				});
				break;
			case 'color_control_bar':
				setThemeColors({
					...themeColors,
					color3: { r: newColor.rgb.r, g: newColor.rgb.g, b: newColor.rgb.b, a: newColor.rgb.a },
				});
				break;
			case 'color_background':
				setThemeColors({
					...themeColors,
					color4: { r: newColor.rgb.r, g: newColor.rgb.g, b: newColor.rgb.b, a: newColor.rgb.a },
				});
			
			default:
				break;
		}
			if (skinSettings.hasOwnProperty(type)) {
				setSkinSettings(prevState => ({
					...prevState,
					[type]: colorStyle(newColor.rgb,"rgba") // Update the property corresponding to the id with the value of e
				}));
				dispatch(setSkin({
					addSkinDataNameChange: type,
					addSkinDataValueChange: colorStyle(newColor.rgb,"rgba")
				}));
				dispatch({
					type:'SET_SELECTED_SKIN',
					payload:skinSettings
				})
			}
	
		setDisabled(false)
		
	};

	const handleClickOutside = (index, event) => {
		if (colorPickerRefs[index].current && !colorPickerRefs[index].current.contains(event.target)) {
			// Click occurred outside the color picker
			switch (index) {
				case 0:
					setShowColorPicker1(false);
					break;
				case 1:
					setShowColorPicker2(false);
					break;
				case 2:
					setShowColorPicker3(false);
					// setLoaderIn(false);
					break;
				case 3:
					setShowColorPicker4(false);
					break;
				default:
					break;
			}
		}
	};

/////////////////////Color select while click Enter /////////////////////////////
	const handleEnterKey = (index, event) => {
		if (event.key === "Enter") {
			switch (index) {
				case 0:
					setShowColorPicker1(false);
					break;
				case 1:
					setShowColorPicker2(false);
					break;
				case 2:
					setShowColorPicker3(false);
					// setLoaderIn(false);
					break;
				case 3:
					setShowColorPicker4(false);
					break;
				default:
					break;
			}
		}
	};

	useEffect(()=>{
		window.parent.postMessage({ info: "SkinMessage", data: { skinData: DefaultSkin }}, "*");
	},[])


	useEffect(() => {
		const addEventListeners = (index) => {
			document.addEventListener("click", (event) => handleClickOutside(index, event));
			document.addEventListener("keydown", (event) => handleEnterKey(index, event));
		};

		colorPickerRefs.forEach((ref, index) => {
			addEventListeners(index);
		});
		return () => {
			colorPickerRefs.forEach((ref, index) => {
				document.removeEventListener("click", (event) => handleClickOutside(index, event));
				document.removeEventListener("keydown", (event) => handleEnterKey(index, event));
			});
		};
	}, []);


	const isLoading = useAppSelector((state)=>state.skinReducer.loadingSkins)


	useEffect(()=>{
	
		if(showSkinCondition && disabled === false){

			dispatch({
				type:'SET_SELECTED_SKIN',
				payload:skinSettings
			})
		}
	},[skinSettings,showSkinCondition,disabled])


	useEffect(()=>{
		if(showSkinCondition.isEdit){
			if(name || !disabled){
				setBtnDisabled(true)
			}else if(!name || disabled){
				setBtnDisabled(false)
			}
		}else if(showSkinCondition.isAdd){
			if(!name || disabled){
				setBtnDisabled(false)
			}else{
				setBtnDisabled(true)
			}
		}
	
	},[showSkinCondition,name,disabled])
	return(
	 isLoading ? (
		<Spin size="large" fullscreen className="spinner" />
	) : (
	
				<Card>

				
					{/* Skin Name */}
					<div className="section">
						<span className="skin-name">{t("Skin-Player.name-skin")}</span>
						<Input
							placeholder="skin name"
							className="body-skin skin-input"
							// value={name}
							defaultValue={skinName && showSkinCondition.isEdit ? skinName : ""}
							onChange={(e) => {
								setName(e.target.value);
								setSkinSettings({
									...skinSettings,
									name:e.target.value
								})
								if(!e.target.value && disabled === true){
									setDisabled(true)
								
								}else  {{
									setDisabled(false)
									dispatch(setSkin({
										addSkinDataNameChange: "name",
										addSkinDataValueChange: e.target.value ? e.target.value : skinName
									}));
								}
								}
								
								
							}}
						></Input>
					</div>
					<div className="containder-edit">
					{/* Personnalisation Section */}
					<div className="section">
						<Collapse bordered={false} defaultActiveKey={["1"]}>
							<Panel
								key={"1"}
								header={t("Skin-Player.Personnalisation")}
								className="Skin-title commonClass"
							>
								<Divider className="divider" />
								<div className="body-skin commonClass" id="body-skin-color">
									<Row>
										<Col span={20}>{t("Skin-Player.Progress-bar")}</Col>
										<Col span={4}>
											<div className="color-picker" ref={colorPickerRefs[0]}>
												<span>{colorStyle(themeColors.color1, "hex")}</span>
												<div
												className="color_circle"
													style={{
														
														backgroundColor: colorStyle(themeColors.color1, "rgba"),
													
													}}
													onClick={() => handleColorClick(0)}
												></div>
											</div>

											{showColorPicker1 && (
												<div onClick={(e) => e.stopPropagation()}>
													<SketchPicker
														disableAlpha={false}
														color={colorStyle(themeColors.color1, "rgba")}
														onChange={(newColor) => handleColorChange('color_primary', newColor)}
														className="SketchPicker"
													/>
												</div>
											)}
										</Col>
									</Row>
									<Row>
										<Col span={20}>{t("Skin-Player.Buttons")}</Col>
										<Col span={4}>
											<div className="color-picker" ref={colorPickerRefs[1]}>
												<span>{colorStyle(themeColors.color2, "hex")}</span>
												<div
													style={{
														width: "20px",
														height: "20px",
														borderRadius: "50%",
														backgroundColor: colorStyle(themeColors.color2, "rgba"),
														cursor: "pointer",
													}}
													onClick={() => handleColorClick(1)}
												></div>
											</div>

											{showColorPicker2 && (
												<div onClick={(e) => e.stopPropagation()}>
													<SketchPicker
														color={colorStyle(themeColors.color2, "rgba")}
														onChange={(newColor) => handleColorChange('color_secondary', newColor)}
														className="SketchPicker"
													/>
												</div>
											)}
										</Col>
									</Row>
									<Row>
										<Col span={20}>{t("Skin-Player.Control-bar")}</Col>
										<Col span={4}>
											<div className="color-picker" ref={colorPickerRefs[2]}>
												<span>{colorStyle(themeColors.color3, "hex")}</span>
												<div
													style={{
														width: "20px",
														height: "20px",
														borderRadius: "50%",
														backgroundColor: colorStyle(themeColors.color3, "rgba"),
														cursor: "pointer",
													}}
													onClick={() => handleColorClick(2)}
												></div>
											</div>

											{showColorPicker3 && (
												<div onClick={(e) => e.stopPropagation()}>
													<SketchPicker
														color={colorStyle(themeColors.color3, "rgba")}
														onChange={(newColor) => handleColorChange('color_control_bar', newColor)}
														className="SketchPicker"
													/>
												</div>
											)}
										</Col>
									</Row>
									<Row>
										<Col span={20}>{t("Skin-Player.backgroundColor")}</Col>
										<Col span={4}>
											<div className="color-picker" ref={colorPickerRefs[3]}>
												<span>{colorStyle(themeColors.color4, "hex")}</span>
												<div
													style={{
														width: "20px",
														height: "20px",
														borderRadius: "50%",
														backgroundColor: colorStyle(themeColors.color4, "rgba"),
														cursor: "pointer",
													}}
													onClick={() => handleColorClick(3)}
												></div>
											</div>

											{showColorPicker4 && (
												<div onClick={(e) => e.stopPropagation()}>
													<SketchPicker
														color={colorStyle(themeColors.color4, "rgba")}
														onChange={(newColor) => handleColorChange('color_background', newColor)}
														className="SketchPicker"
													/>
												</div>
											)}
										</Col>
									</Row>
								</div>
							</Panel>
						</Collapse>
					</div>

					{/*Control Section  */}
					<div className="section">
						<Collapse bordered={false}>
							<Panel key={"2"} header={t("Skin-Player.Control-bar")} className="Skin-title commonClass">
								<Divider className="divider" />
								<div className="body-skin commonClass">
									{buttonSection.map((item, index) => {
										return (
											<Row key={index}>
												<Col span={23}>{item.label}</Col>
												<Col span={1}>
													<Switch
														defaultChecked={item.checked}
														onChange={(e) => switchToggleBtn(item.name, e)}
													/>
												</Col>
											</Row>
										);
									})}
								</div>
							</Panel>
						</Collapse>
					</div>
					{/* General information Section */}
					<div className="section">
						<Collapse bordered={false}>
							<Panel
								key={"3"}
								header={t("Skin-Player.InfosModal")}
								className="Skin-title commonClass"
							>
								<Divider className="divider" />
								<div className="body-skin commonClass">
									{controlButton.map((item, index) => {
										return (
											<Row key={index}>
												<Col span={23}>{item.label}</Col>
												<Col span={1}>
													<Switch
														defaultChecked={item.checked}
														onChange={(e) => switchToggleBtn(item.name, e)}
													/>
												</Col>
											</Row>
										);
									})}
								</div>
							</Panel>
						</Collapse>
					</div>

					{/* Actions Section */}
					<div className="section">
						<Collapse bordered={false} defaultActiveKey={["4"]}>
							<Panel key={"4"} header={t("Skin-Player.Actions")} className="Skin-title commonClass">
								<Divider className="divider" />
								<div className="body-skin commonClass">
									{playerSection.map((item, index) => {
										return (
											<Row key={index}>
												<Col span={23}>{item.label}</Col>
												<Col span={1}>
													<Switch
														checked={item.checked}
														onChange={(e) => switchToggleBtn(item.name, e)}
													/>
												</Col>
											</Row>
										);
									})}
								</div>
							</Panel>
						</Collapse>
					</div>
					</div>
					{/* Register Section */}
					<div className="section button-group">
						<Button
							type="primary"
							variant="text"
							size="12px"
							style={{ color: "#6B6B89" }}
							onClick={() =>{
								
								// history.push({
								// 	pathname: `/ws-media/video/${filename}/${id}/edit/previewSkin`,
								// })
								resetSkin();
								dispatch(setLoadingSkins(true))
							}
								
							}
						>
							{t("cancel")}
						</Button>
						<Button
							type="primary"
							variant="contained"
							size="12px"
							style={{
								color: btnDisabled ? "" : "white",
								backgroundColor: btnDisabled ? "" : "#42A4FF",
							}}
							disabled={!btnDisabled}
							onClick={async ()=>{
								showSkinCondition.isAdd ?  await createSkin():await updateSkin(skinId,skinSettings)
								resetSkin()
								// history.push({
								// 	pathname: `/ws-media/video/${filename}/${id}/edit/previewSkin`,
								// })
							
							}
							
							}
						>
							{showSkinCondition.isEdit ? t("edit") : t("Enregistrer")}
						</Button>
					</div>
				</Card>
		
			
	)
	)
};
