import { useTranslation } from "react-i18next";
import { Card, TextField } from "@mui/material";
import { Hooks } from "../../utils/hooks";
import { useState } from "react";
const AddEditMooc = () => {
  const { t } = useTranslation();
  const { values } = Hooks();
  const [content, setcontent] = useState(values.type);
  const [headerValue, setheaderValue] = useState(values.value);
  const changeheaderValue = (newheader: any) => {
    setheaderValue(newheader.target.value);
  };
  const changeContent = (newContent: any) => {
    setcontent(newContent.target.value);
  };
  return (
    <div className={"divCard"}>
      <Card>
        <div>
          <div className={"InputLablDiv"}>
            <TextField
              required
              id={"title"}
              name="type"
              label={t("Configuration.Mooc.key")}
              placeholder={t("Configuration.Mooc.key")}
              className="textField"
              value={content}
              onChange={changeContent}
              multiline
            />
          </div>
          <div className={"InputLablDiv"}>
            <TextField
              id={"title"}
              name={"value"}
              label={t("Configuration.Mooc.Header.value")}
              placeholder={t("Configuration.Mooc.Header.value")}
              className="textField"
              value={headerValue}
              onChange={changeheaderValue}
              multiline
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
export default AddEditMooc;
