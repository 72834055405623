import {React} from "react";
import "../App.css"
const Notfound = () => {
    return (
        <div className="notFoundContainer" >
        <img style={{width:"1333px"}} src="/configurations/notfound.png" alt="not found " />
        </div>
    )
}

export default Notfound;