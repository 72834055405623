
export const ServeurList = () => {

    const FilterServeur = () => {
        return (
            {
                search: "",
                searchFake: "",
                type: "",
            }
        )
    }

    const paginationPropsServeur = () => {
        return (
            {
                totalCount: 0,
                order: 'descend',
                pageSize: 10,
                columnKey: 0,
                current: 1,
                id: [],
                idLive: 0,
                deleteActionsTrace:false
            }
        )
    }

    const showDivsConditionsServeur = () => {
        return ({
            elementsSelected:[],
            elementSelected: 0,
            addServeur: false,
            editServeur: false,
            messageerror:"",
            disablesubmitform: true,
        })
    }

    const AddServeurConstraintsData = () => {
        return (
            {
                Protocol: "",
                Type:"",
                Internet: false,
                Record: false,
                IsDisplay: false,
                IsBuffer: false,
                Dir:"",
                UrlVideo:"",
                UrlMp4:"",
                UrlDiffusion:"",
                UrlVodAndroid:"",
                UrlLiveAndroid:"",
                UrlCdnAndroid:"",
                UrlVodIos:"",
                UrlLiveIos:"",
                UrlCdnIos:"",
                DirLecture:"",
                UrlBackup:"",
                UrlMobile:"",
                UrlServerFtp:"",
                LoginLive:"",
                Name:"",
                Login:"",
                Pwd:"",
                PwdLive:"",
                Bitrate:"",
                ServerName:"",
                ServerType:"",
                Port: 0,
                SshKey:"",
                StreamPlaylistLive:"",
                Notes:"",
            }

        )
    }
    const DataServeur= () =>{
        return []
    }
    const DetailServeur= () =>{
        return []
    }
    
    return ({
        paginationPropsServeur,
        showDivsConditionsServeur,
        AddServeurConstraintsData,
        FilterServeur,
        DataServeur,
        DetailServeur
    })
}
