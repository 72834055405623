
export const PresetList = () => {

    const FilterPreset = () => {
        return (
            {
             
                search: "",
                type: "",
            }
        )
    }

    const paginationPropsPreset = () => {
        return (
            {
                totalCount:0,
                order: 'descend',
                pageSize: 10,
                columnKey: 0,
                current: 1,
                id: [],
                idLive: 0,
            }
        )
    }

    const showDivsConditionsPreset = () => {
        return ({
            action:false,
            elementSelected: 0,
            addPreset: false,
            editPreset: false,
            disablesubmitform: true,
        })
    }

    const AddPresetConstraintsData = () => {
        return (
            {
                
                State: false,
                Name:"" ,
                BitrateVideo:"",
                BitrateAudio:"",
                SamplerateAudio:"",
                Framerate: "",
                CreatedAt: "",
                UpdatedAt: "",
                Width:"", 
                Height:"" ,
                SortBitrate: "",
            }

        )
    }
    const DetailPreset= () =>{
        return {}
    }
    const PresetTypes={
        LOADING:"LOADING",
        SET_PRESET_DATA:"SET_PRESET_DATA",
        RESET_MOOC_ERROR:"RESET_PRESET_ERROR",
    }
    return ({
        paginationPropsPreset,
        showDivsConditionsPreset,
        AddPresetConstraintsData,
        FilterPreset,
        PresetTypes,
        DetailPreset
    })
}
