import React from "react";
import { Tooltip, Button } from "@mui/material";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import useDataTableMedia from "./DataTable";
import { useAppSelector } from "../../../../Redux/hooks";
import { setFilterVideoplayerActions } from "../../store/videoplayerActions";
import { useDispatch } from "react-redux";
import { DataVideoplayer } from "../../utils/Data";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import "../../videoplayer.scss";
import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
const VideoplayerTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const valuePagination = useAppSelector(
    (state) => state.VideoplayerReducer.paginationPropsVideoplayer
  );

  const { columns } = DataVideoplayer();
  const data = useAppSelector(
    (state) => state.VideoplayerReducer.dataVideoplayer
  );

  const { handleSearchRow, Deletemultipleselecteditem } = Hooks();

  const { DataTable } = useDataTableMedia({
    columns: columns,
    dataSource: data,
    updateEntityPath: "update-product",
  });
 
  const delet = t("Videoplayer.delete");
  return (
    <div>
      <div className="Filter">
        {valuePagination.id.length === 0 ? null : (
          <div className="delete_number">
            <Tooltip title={delet}>
              <Button
                startIcon={<DeleteOutlineOutlined />}
                onClick={() => Deletemultipleselecteditem()}
              />
            </Tooltip>
            <p>
              <span>{valuePagination.id.length || ""}</span>{" "}
              <span id={"text_selection"}>{t("Videoplayer.selectedItem")}</span>
            </p>
          </div>
        )}

        <div className={"div_rect"}>
          <div className="rect_filter">
            <FormControl
              className="search"
              style={{ width: "99.5%", marginLeft: "1%" }}
            >
              <TextField
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        style={{
                          color: "rgba(0, 0, 0, 0.25)",
                          marginLeft: "10px",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
                name={"search"}
                placeholder={t("Videoplayer.search")}
                onKeyDown={(e) => handleSearchRow(e)}
                onChange={(e) =>
                  dispatch(
                    setFilterVideoplayerActions({
                      FilterVideoplayerNameChange: "searchFake",
                      FilterVideoplayerValueChange: e.target.value,
                    })
                  )
                }
              />
            </FormControl>
          </div>
        </div>
      </div>

      <div className={"dataTable"}>
        <DataTable />
      </div>
    </div>
  );
};

export default VideoplayerTable;
