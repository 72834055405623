import { Grid } from "@material-ui/core";
import MoocTable from "./components/List/MoocTable";
import AddEditMooc from "./components/AddEdit/addEdit";
import MoocHeader from "./components/MoocHeader";
import { Hooks } from "./utils/hooks";

import "./Mooc.scss";

const Mooc = () => {
  const { showDivCondition, onFinishAddMooc, onValueFormUserchangeMooc } =
    Hooks();

  return (
    <div className={"divGlobalTable"}>
      {showDivCondition.addMooc ? (
        <div>
          <Grid
            onSubmit={onFinishAddMooc}
            onChange={onValueFormUserchangeMooc}
            className={"ProfileForm"}
          >
            <MoocHeader />
            <AddEditMooc />
          </Grid>
        </div>
      ) : (
        <div>
          <MoocHeader />
          <MoocTable />
        </div>
      )}
    </div>
  );
};

export default Mooc;
