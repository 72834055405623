import React, { useEffect,useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Tooltip,
} from "@mui/material";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import Hooks from "../../utils/hooks";
import "../../preset.scss";
import { useAppSelector } from "../../../../Redux/hooks";
import { InputAdornment } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {GraphQLFetchData} from "../../utils/GraphFetchData"
import { ModalInputs } from '@empreinte/components.modal-inputs';

function useActionMenu({ record }: any) {
  const {DeletePreset} = GraphQLFetchData()
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    overflow: "scroll",
    height: 600,
    pl: 3,
    pr: 1,
    py: 1,
  };
  const { t } = useTranslation();
  const {
    handleShowModal,
    visible,
    handleCancel,
    handleEditPreset,
  } = Hooks();

  const { configuration_presets_module } = useAppSelector(
    (state) => state.ReducerUserData.modules
  );
  const isSuperAdmin = useAppSelector((state: any) => state.ReducerUserData.isSuperAdmin)
  const details = t("configuration.Preset.details");
  const edit = t("configuration.Preset.edit");
  const delet = t("configuration.Preset.delete");
  const [confirmationDelete,setConfirmationDelete]= useState(false)
  return (
    <div>
      {confirmationDelete ? (
        <div>
        <Dialog
          open={confirmationDelete}
          onClose={()=>setConfirmationDelete(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
          {`${t("DeleteConfirmation")} `}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
            {`${t("DeleteConfirmationContentPreset")} `}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={()=>setConfirmationDelete(false)}>
            {`${t("No")} `}
            </Button>
            <Button onClick={()=>DeletePreset({variables : {ids:[record.Id]}})} autoFocus>
            {`${t("Yes")} `}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      ) : <></>}
      <div className="action">
        {(configuration_presets_module?.configuration_presets_post_edit_action?.authorized || isSuperAdmin) && (
          <div has-permission="configuration_presets_post_edit">
            <Tooltip placement="top" title={edit}>
              <ModeEditIcon
                className={"icon_action"}
                onClick={() => handleEditPreset(record)}
              />
            </Tooltip>
          </div>
        )}
        {(configuration_presets_module?.configuration_presets_get_detail_action?.authorized || isSuperAdmin) && (
          <div has-permission="configuration_presets_get_detail">
            <Tooltip placement="top" title={details}>
              <RemoveRedEyeIcon
                className={"icon_action"}
                onClick={() => handleShowModal()}
              />
            </Tooltip>
          </div>
        )}
        {(configuration_presets_module?.configuration_presets_delete_preset_action?.authorized || isSuperAdmin) && (
          <div has-permission="configuration_presets_delete_preset">
            <Tooltip placement="top" title={delet}>
              <DeleteIcon
                className={"icon_action"}
                onClick={() => setConfirmationDelete(true)}
              />
            </Tooltip>
          </div>
        )}
        <ModalInputs
        className="modal-preset-details" 
        open={visible}
        dividers
        title={t("configuration.Preset.modaledittitle")}
        onClose={handleCancel}
        CancelBtnName={t("Serveur.close")}
        onCancel={handleCancel}
        children={
          <div className="preset-details-modal">
            <div className="preset-details-modal-container">
              <span className="preset-details-modal-title">{t("configuration.Preset.Id")}</span>
              <span className="preset-details-modal-value">{record.Id ? record.Id : '--'}</span>
            </div>
            <div className="preset-details-modal-container">
              <span className="preset-details-modal-title">{t("configuration.Preset.Name")}</span>
              <span className="preset-details-modal-value">{record.Name ? record.Name : "--"}</span>
            </div>
            <div className="preset-details-modal-container">
              <span className="preset-details-modal-title">{t("configuration.Preset.State")}</span>
              <span className="preset-details-modal-value" style={{padding: '23px', paddingLeft:'0px'}}>
                                  <InputAdornment position="start" >
                                    {record.State === true ? (
                                      <>
                                        <CheckCircleIcon
                                          className={"checkedicon statusIcon"}
                                        />
                                        {t("configuration.Preset.authorized")}
                                      </>
                                    ) : (
                                      <>
                                        <HighlightOffIcon
                                          className={"closeicon statusIcon"}
                                        />
                                        {t("configuration.Preset.notauthorized")}
                                      </>
                                    )}
                                  </InputAdornment>
                                
                              
              </span>
            </div>
            <div className="preset-details-modal-container">
              <span className="preset-details-modal-title">{t("configuration.Preset.BitrateAudio")}</span>
              <span className="preset-details-modal-value">{record.BitrateAudio ? record.BitrateAudio : "--"}</span>
            </div>
            <div className="preset-details-modal-container">
              <span className="preset-details-modal-title">{t("configuration.Preset.CreatedAt")}</span>
              <span className="preset-details-modal-value">{record.CreatedAt ? record.CreatedAt : "--"}</span>
            </div>
            <div className="preset-details-modal-container">
              <span className="preset-details-modal-title">{t("configuration.Preset.Framerate")}</span>
              <span className="preset-details-modal-value">{record.Framerate ? record.Framerate : "--"}</span>
            </div>
            <div className="preset-details-modal-container">
              <span className="preset-details-modal-title">{t("configuration.Preset.Height")}</span>
              <span className="preset-details-modal-value">{record.Height ? record.Height :"--"}</span>
            </div>
            <div className="preset-details-modal-container">
              <span className="preset-details-modal-title">{t("configuration.Preset.Width")}</span>
              <span className="preset-details-modal-value">{record.Width ? record.Width : "--"}</span>
            </div>
            <div className="preset-details-modal-container">
              <span className="preset-details-modal-title">{t("configuration.Preset.SamplerateAudio")}</span>
              <span className="preset-details-modal-value">{record.SamplerateAudio ? record.SamplerateAudio : "--"}</span>
            </div>
            <div className="preset-details-modal-container">
              <span className="preset-details-modal-title">{t("configuration.Preset.SortBitrate")}</span>
              <span className="preset-details-modal-value">{record.SortBitrate || record.SortBitrate === 0 ? record.SortBitrate : "--"}</span>
            </div>
            <div className="preset-details-modal-container">
              <span className="preset-details-modal-title">{t("configuration.Preset.BitrateVideo")}</span>
              <span className="preset-details-modal-value">{record.BitrateVideo ? record.BitrateVideo : "--"}</span>
            </div>
            <div className="preset-details-modal-container">
              <span className="preset-details-modal-title">{t("configuration.Preset.UpdatedAt")}</span>
              <span className="preset-details-modal-value">{record.UpdatedAt ? record.UpdatedAt : "--"}</span>
            </div>
          </div>
        }
        />
       
      </div>
    </div>
  );
}

export default useActionMenu;
