import React from "react";
import { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@empreinte/components.button";
import "../videopush.scss";
const VideopushHeader=({ onButtonClick }:any)=>{
    const { t } = useTranslation();


    return(
        <div className={"add_div"}>
                <div>
               <span className={"profile_title_conf"}>{t('configuration.Videopush.videopushTitle')}</span>
                </div>
                <div className={"div_btn_add_cancel"}>
                <Button
                    style={{fontWeight:"600"}}              
                    title={t('configuration.Videopush.backward')}
                    color="primary"
                    variant="contained"
                    component="label"
                    onClick={onButtonClick}
                ></Button>
                </div>
        </div>
    )
}
export default VideopushHeader