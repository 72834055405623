import { useEffect, useState } from "react";
import "../../client.scss";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import { useAppSelector } from "../../../../Redux/hooks";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import { useDispatch } from "react-redux";
import { setAddClientActions } from "../../store/clientActions";
import { InputText } from "@empreinte/components.input-text";

import { Loader } from "@empreinte/components.loader";

const AddEditClient = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { values, disabledsubmitbutton } = Hooks();

  const [errorkeyInputField, setErrorkeyInputField] = useState("");
  const [errorValueInputField, setErrorValueInputField] = useState("");

  const loaderState = useAppSelector((state) => state.ClientReducer.isLoading);
  const validInputsToAddServer = () => {
    let isValidToSubmit = true;
    Object.values(values).forEach((value, i) => {
      if (
        value === "" &&
        Object.keys(values)[i] !== "CreatedAt" &&
        Object.keys(values)[i] !== "UpdatedAt" &&
        Object.keys(values)[i] !== "Id"
      ) {
        isValidToSubmit = false;
      }
    });

    if (!isValidToSubmit) {
      disabledsubmitbutton("notdisabled");
    } else {
      disabledsubmitbutton("disabled");
    }
  };
  useEffect(() => {
    validInputsToAddServer();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const onchangeForm = (e: any) => {
    if (e.target.value.length > 255) {
      return false;
    }

    dispatch(
      setAddClientActions({
        addClientConstraintsDataNameChange: e.target.name,
        addClientConstraintsDataValueChange: e.target.value,
      })
    );
  };

  useEffect(() => {
    if (values.Cle && values.Cle.length >= 255) {
      setErrorkeyInputField(t("configuration.Client.errorkeyInput"));
      disabledsubmitbutton("notdisabled");
    } else setErrorkeyInputField("");
  }, [values.Cle,values]);
  useEffect(() => {
    if (values.Value && values.Value.length >= 255) {
      setErrorValueInputField(t("configuration.Client.errorValueInput"));
      disabledsubmitbutton("notdisabled");
    } else setErrorValueInputField("");
  }, [values.Value, values]);

  const handleKayPress = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  return (
    <>
      <Loader
        isOpen={loaderState}
        handleClose={() => {}}
        handleOpen={() => {}}
      />
      <div className={"divCard"}>
        <Card>
          <div>
            <div className={"InputLablDiv"}>
              <div className="Input">
                <InputText
                  style={{ width: "100%" }}
                  name="Cle"
                  className="input_addEdit"
                  labelInput={t("configuration.Client.key")}
                  required={true}
                  handleChange={onchangeForm}
                  placeholder={t("configuration.Client.key")}
                  value={values.Cle}
                  multiline={true}
                  onKeyDownAction={handleKayPress}
                />
                {errorkeyInputField !== "" && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errorkeyInputField}
                  </p>
                )}
              </div>
            </div>
            <div className={"InputLablDiv"}>
              <div className="Input">
                <InputText
                  style={{ width: "100%" }}
                  className="input_addEdit"
                  name="Value"
                  labelInput={t("configuration.Client.Valeur")}
                  required={true}
                  handleChange={onchangeForm}
                  value={values.Value}
                  placeholder={t("configuration.Client.Valeur")}
                  multiline={true}
                  onKeyDownAction={handleKayPress}
                />
                {errorValueInputField !== "" && (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    {errorValueInputField}
                  </p>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
export default AddEditClient;
