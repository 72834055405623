import React,{useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../Redux/hooks";
import {Breadcrumbs , Link  } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { Button } from '@empreinte/components.button';
import AddIcon from '@mui/icons-material/Add';

import Hooks from "../utils/hooks";
import "../configuration.scss";

import { notification } from 'antd';

const ConfigurationHeader = () => {
    // const navigate=useNavigate()
    const { t } = useTranslation();
    const disabledButton = useAppSelector((state)=>state.ConfigurationReducer.isButtonDisabled)
    const selectedItems= useAppSelector((state)=>state.ConfigurationReducer.ConfigChannelSelected)

    const {onFinishAddConfiguration} = Hooks()
    const [open, setOpen] = useState(false);
    const [showToast, setShowToast] = useState(false);

  
      const handleOpen=()=>{
        setOpen(true)
      }

    
      const handleAddData = () => {
        onFinishAddConfiguration(selectedItems);
        handleOpen();
        notification.success({
          message: '',
          description:`${t('AddConfiguartionUpdate')}`,
          duration: 3,
          placement:'top',
          closeIcon:false,
        });
      };

      
    // showDivCondition
    // showDivsConditionsConfiguration
    return (
        <div>
            <div className={"add_div"}>
            <div>
               <span className={"profile_title_conf"}>{t('configuration.Configurationhomepage.configurationtitle')}</span>
           </div>{/*DIVTITLEHEADER*/}
           <div className={"div_btn_add_cancel"}>
           
              <Button
              title={t('configuration.Configurationhomepage.add')}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              component="label"
              disabled={disabledButton}
              onClick={handleAddData}
                ></Button>
              
          </div>
          </div>
        <div className={"Breadcrumb"}>
        {/* <Breadcrumbs>
        <Link underline="none" color="inherit" href="" 
        //  onClick={() => {
        //     navigate("/dashboard")
        //  }}
          >
          <span><HomeOutlinedIcon className={"home_icon"} /></span>
         </Link >
         <Link underline="none" color="inherit" href="">{t('configuration.Configurationhomepage.homepage')}</Link>
         <Link underline="none" color="inherit" href="">{t('configuration.Configurationhomepage.configuration')}</Link>
          </Breadcrumbs> */}
         </div>
         </div>
    )
}

export default ConfigurationHeader
