import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {Card ,TextField}  from '@mui/material';
import Hooks from "../../utils/hooks";
import '../../preset.scss'
import { useAppSelector } from "../../../../Redux/hooks";
import {Checkbox ,FormControlLabel,FormGroup}  from '@mui/material';
import { useDispatch } from "react-redux";
import {setAddPresetActions} from "../../store/presetActions"

const AddEditPreset = () => {
  const { t } = useTranslation();
  const { values, disabledsubmitbutton ,EditCondition,validateNumber,validateString,valid} = Hooks()
  const dispatch = useDispatch();
  const check = () => {
    return Object.values(valid).every(e => e === true);
  };
  const validInputsToAddServer = () => {
    let isValidToSubmit = true
    Object.values(values).map((value,i)=>{if((value === "" && (Object.keys(values)[i] !== "CreatedAt" && Object.keys(values)[i] !== "UpdatedAt" )) || value === 0){isValidToSubmit=false}})
    if(check()){
    if (!isValidToSubmit ) {
      disabledsubmitbutton("notdisabled");
    } else {
      disabledsubmitbutton("disabled");
    } 
  }else { 
    disabledsubmitbutton("notdisabled");
  }
  };
  useEffect(() => {
    validInputsToAddServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values,valid]);
  console.log(valid)
      const onchangeForm = (e: any) => {
        if(e.target.name === "SortBitrate" || e.target.name === "Framerate"){
          validateNumber(e.target.value, e.target.name)
        }else{
          validateString(e.target.value, e.target.name)
        }
        dispatch(
          setAddPresetActions({
            addPresetConstraintsDataNameChange: e.target.name,
            addPresetConstraintsDataValueChange: e.target.value,
          })
        );
      };
      const onchangeFormChecks = (e: any) => {
        dispatch(
          setAddPresetActions({
            addPresetConstraintsDataNameChange: e.target.name,
            addPresetConstraintsDataValueChange: e.target.checked,
          })
        );
      };
        const handleKeyPress = (event:any) => {
          if (event.key === 'Enter') {
            event.preventDefault(); // Prevents adding a new line
            event.target.blur(); // Leaves the input field
          }
        };
    return (
        <>
            <div className="addEditCard" style={{ width: "56%" }}>
            <Card>
                <TextField
                maxRows={1}
                error={!valid.Name && (values.Name.length > 0)}
                helperText={!valid.Name && (values.Name.length > 0) && (values.Name.length < 200) ? t("configuration.Preset.this input should contain letters") : !valid.Name && (values.Name.length > 200)  ? t("configuration.Preset.this input is too long") : ""}
                style={{width : '100%'  , marginBottom : '2%' , marginTop:'2%' }}
                required
                id={"Name"}
                name="Name"
                label={t('configuration.Preset.Name')}
                placeholder={t('configuration.Preset.Name')}
                className="textField"
                defaultValue={values.Name}
                onChange={onchangeForm}
                multiline
                onKeyPress={(e)=>handleKeyPress(e)}
                 />
                <TextField
                maxRows={1}
                error={!valid.BitrateVideo && (values.BitrateVideo.length > 0)}
                helperText={!valid.BitrateVideo && (values.BitrateVideo.length > 0) && (values.BitrateVideo.length < 200) ? t("configuration.Preset.this input should contain letters") : !valid.BitrateVideo && (values.BitrateVideo.length > 200)  ? t("configuration.Preset.this input is too long") : ""}
                style={{width : '100%'  , marginBottom : '2%' , marginTop:'2%'}}
                required
                id={"BitrateVideo"}
                name="BitrateVideo"
                label={t('configuration.Preset.BitrateVideo')}
                placeholder={t('configuration.Preset.BitrateVideo')}
                className="textField"
                defaultValue={values.BitrateVideo}
                onChange={onchangeForm}
                multiline
                onKeyPress={(e)=>handleKeyPress(e)}

                 />
                 <TextField
                 maxRows={1}
                error={!valid.BitrateAudio && (values.BitrateAudio.length > 0)}
                helperText={!valid.BitrateAudio && (values.BitrateAudio.length > 0) && (values.BitrateAudio.length < 200) ? t("configuration.Preset.this input should contain letters") : !valid.BitrateAudio && (values.BitrateAudio.length > 200)  ? t("configuration.Preset.this input is too long") : ""}
                style={{width : '100%'  , marginBottom : '2%' , marginTop:'2%'}}
                required
                id={"BitrateAudio"}
                name="BitrateAudio"
                label={t('configuration.Preset.BitrateAudio')}
                placeholder={t('configuration.Preset.BitrateAudio')}
                className="textField"
                defaultValue={values.BitrateAudio}
                onChange={onchangeForm}
                multiline
                onKeyPress={(e)=>handleKeyPress(e)}

                 />
                  <TextField
                  maxRows={1}
                error={!valid.SamplerateAudio && (values.SamplerateAudio.length > 0)}
                helperText={!valid.SamplerateAudio && (values.SamplerateAudio.length > 0) && (values.SamplerateAudio.length < 200) ? t("configuration.Preset.this input should contain letters") : !valid.SamplerateAudio && (values.SamplerateAudio.length > 200)  ? t("configuration.Preset.this input is too long") : ""}
                style={{width : '100%'  , marginBottom : '2%' , marginTop:'2%'}}
                required
                id={"SamplerateAudio"}
                name="SamplerateAudio"
                label={t('configuration.Preset.SamplerateAudio')}
                placeholder={t('configuration.Preset.SamplerateAudio')}
                className="textField"
                defaultValue={values.SamplerateAudio}
                onChange={onchangeForm}
                multiline
                onKeyPress={(e)=>handleKeyPress(e)}

                 />
                <TextField
                maxRows={1}
                error={!valid.Framerate && (values.Framerate.length > 0)}
                helperText={!valid.Framerate && (values.Framerate <= 0) && (values.Framerate.length > 0) ?  "this number is out of range" : !valid.Framerate && (values.Framerate.length > 0) && (values.Framerate.length < 200) ? t("configuration.Preset.this input should contain only numbers") : !valid.Framerate && (values.Framerate.length > 200)   ? t("configuration.Preset.this input is too long") : ""}
                style={{width : '100%'  , marginBottom : '2%' , marginTop:'2%'}}
                required
                id={"Framerate"}
                name="Framerate"
                type="number"
                label={t('configuration.Preset.Framerate')}
                placeholder={t('configuration.Preset.Framerate')}
                className="textField"
                defaultValue={values.Framerate}
                onChange={onchangeForm}
                multiline
                onKeyPress={(e)=>handleKeyPress(e)}

                 />
                     <TextField
                     maxRows={1}
                    error={!valid.Width && (values.Width.length > 0)}
                    helperText={!valid.Width && (values.Width.length > 0) && (values.Width.length < 200) ? t("configuration.Preset.this input should contain letters") : !valid.Width && (values.Width.length > 200)  ? t("configuration.Preset.this input is too long") : ""}
                    style={{width : '100%'  , marginBottom : '2%' , marginTop:'2%'}}
                    required
                    id={"Width"}
                    name="Width"
                    label={t('configuration.Preset.Width')}
                    placeholder={t('configuration.Preset.Width')}
                    className="textField"
                    defaultValue={values.Width}
                    onChange={onchangeForm}
                    multiline
                onKeyPress={(e)=>handleKeyPress(e)}

                     />
                    <TextField
                    maxRows={1}
                    error={!valid.Height && (values.Height.length > 0)}
                    helperText={!valid.Height && (values.Height.length > 0) && (values.Height.length < 200) ? t("configuration.Preset.this input should contain letters") : !valid.Height && (values.Height.length > 200)  ? t("configuration.Preset.this input is too long") : ""}
                    style={{width : '100%'  , marginBottom : '2%' , marginTop:'2%'}}
                    required
                    id={"Height"}
                    name="Height"
                    label={t('configuration.Preset.Height')}
                    placeholder={t('configuration.Preset.Height')}
                    className="textField"
                    defaultValue={values.Height}
                    onChange={onchangeForm}
                    multiline
                onKeyPress={(e)=>handleKeyPress(e)}

                    />
                    <TextField
                    maxRows={1}
                    error={!valid.SortBitrate && (values.SortBitrate.length > 0)}
                    helperText={!valid.SortBitrate && (values.SortBitrate <= 0) && (values.SortBitrate.length > 0) ?  "this number is out of range" : !valid.SortBitrate && (values.SortBitrate.length > 0) && (values.SortBitrate.length < 200) ? t("configuration.Preset.this input should contain only numbers") : !valid.SortBitrate && (values.SortBitrate.length > 200)   ? t("configuration.Preset.this input is too long") : ""}
                    style={{width : '100%'  , marginBottom : '2%' , marginTop:'2%'}}
                    required
                    type="number"
                    id={"SortBitrate"}
                    name="SortBitrate"
                    label={t('configuration.Preset.SortBitrate')}
                    placeholder={t('configuration.Preset.SortBitrate')}
                    className="textField"
                    defaultValue={values.SortBitrate}
                    onChange={onchangeForm}
                    multiline
                onKeyPress={(e)=>handleKeyPress(e)}

                    />
                <FormGroup>
                <FormControlLabel  control={<Checkbox  onChange={onchangeFormChecks} value={values.State} name="State" defaultChecked={values.State} style={{ textAlign: "start" , marginLeft:'2%'}} />}  label={t('configuration.Preset.State')} />
               </FormGroup>
                </Card>
            </div>


        </>
    )
}
export default AddEditPreset