import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../Redux/hooks";
import {
  setshowDivsConditionsMooc,
  setFilterMoocActions,
  setAddMoocActions,
} from "../store/MoocActions";

export const Hooks = () => {
  const dispatch = useDispatch();

  const values = useAppSelector(
    (state) => state.MoocReducer.addMoocConstraintsData
  );
  const valuesSearch = useAppSelector((state) => state.MoocReducer.filterMooc);

  const showDivCondition = useAppSelector(
    (state) => state.MoocReducer.showDivsConditionsMooc
  );
  const handelSearch = (word: string, tab: any) => {
    if (word.trim() === "") return tab;
    const res = tab.filter((obj: any) => {
      if (obj.type.toLowerCase().includes(word.toLowerCase())) return obj;
      if (obj.value.toLowerCase().includes(word.toLowerCase())) return obj;
    });
    return res;
  };

  const handleAddMooc = () => {
    dispatch(
      setshowDivsConditionsMooc({
        showDivsConditionsName: "addMooc",
        showDivsConditionsValue: true,
      })
    );
  };

  const onResetMooc = async () => {
    dispatch(
      setshowDivsConditionsMooc({
        showDivsConditionsName: "addMooc",
        showDivsConditionsValue: false,
      })
    );
    await dispatch(
      setAddMoocActions({
        addMoocConstraintsDataNameChange: "value",
        addMoocConstraintsDataValueChange: "",
      })
    );
    await dispatch(
      setAddMoocActions({
        addMoocConstraintsDataNameChange: "type",
        addMoocConstraintsDataValueChange: "",
      })
    );
  };
  const onFinishAddMooc = (val: any) => { 
  };
  const onFinishFailedAddMooc = (errorInfo: any) => {
  };
  const onValueFormUserchangeMooc = async (val: any) => {
    if (val.value) {
      await dispatch(
        setAddMoocActions({
          addMoocConstraintsDataNameChange: "value",
          addMoocConstraintsDataValueChange: val.value,
        })
      );
    } else if (val.type) {
      await dispatch(
        setAddMoocActions({
          addMoocConstraintsDataNameChange: "type",
          addMoocConstraintsDataValueChange: val.type,
        })
      );
    }
  };

  const handleEditMooc = async (record: any) => {
    if (record) {
      Object.entries(record).map((rec: any) => {
        dispatch(
          setAddMoocActions({
            addMoocConstraintsDataNameChange: rec[0] + "",
            addMoocConstraintsDataValueChange: rec[1],
          })
        );
      });
    }

    dispatch(
      setshowDivsConditionsMooc({
        showDivsConditionsName: "addMooc",
        showDivsConditionsValue: true,
        record: record.id,
      })
    );
  };

  const handleSearchRow = async (e: any) => {
    if (e.key === "clicked") {
      await dispatch(
        setFilterMoocActions({
          FilterMoocNameChange: "search",
          FilterMoocValueChange: e.value,
        })
      );
    } else if (e.key === "Enter") {
      await dispatch(
        setFilterMoocActions({
          FilterMoocNameChange: "search",
          FilterMoocValueChange: e.target.value,
        })
      );
    }
  };

  return {
    handleAddMooc,
    onResetMooc,
    onFinishAddMooc,
    onFinishFailedAddMooc,
    onValueFormUserchangeMooc,
    handleEditMooc,
    values,
    handleSearchRow,
    valuesSearch,
    handelSearch,
    showDivCondition,
  };
};
