import { useEffect, useState, useLayoutEffect,useRef } from "react";
import { useDispatch } from "react-redux";

import { Form, message } from "antd";

import {
  setAddPresetActions,
  setPaginationPropsPreset,
  setPresetDataActions,
  setshowDivsConditionsPreset,
} from "../store/presetActions";
import { PresetList } from "../store/preset";
import { useAppSelector } from "../../../Redux/hooks";
import { DeleteData, GetData, PostData } from "../../../APIcalls";
import { useTranslation } from "react-i18next";
import { GraphQLFetchData } from "./GraphFetchData";

const Hooks = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<any>(false);
  const dispatch = useDispatch();
  const { PresetTypes } = PresetList();
  const [formAddPreset] = Form.useForm();
  const [valid, setValid] = useState({
    BitrateAudio: true,
    BitrateVideo: true,
    Framerate: true,
    Height: true,
    Name: true,
    SamplerateAudio: true,
    SortBitrate: true,
    Width: true});
  const values = useAppSelector(
    (state) => state.PresetReducer.addPresetConstraintsData
  );
  const { AddPreset, UpdatePreset, SearchByKeyWordPreset } = GraphQLFetchData();

  const EditCondition = useAppSelector(
    (state) => state.PresetReducer.showDivsConditionsPreset.editPreset
  );
  const ServerId = useAppSelector(
    (state) => state.PresetReducer.showDivsConditionsPreset.elementSelected
  );
  const usePrevious = (value:any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  function validateString(input: any, assign: any) {
    if (typeof input !== "string") {
      return setValid((prevState) => {
        return {
          ...prevState,
          [assign]: false,
        };
      });
    }

    if (input.trim().length === 0) {
      return setValid((prevState) => {
        return {
          ...prevState,
          [assign]: false,
        };
      });
    }

    if (input.length > 200) {
      return setValid((prevState) => {
        return {
          ...prevState,
          [assign]: false,
        };
      });
    }
    if (/^\d+$/.test(input)) {
      return setValid((prevState) => {
        return {
          ...prevState,
          [assign]: false,
        };
      });
    } else {
      return setValid((prevState) => {
        return {
          ...prevState,
          [assign]: true,
        };
      });
    }
  }
  function validateNumber(input: string, assign: any) {
    const inputAsString = input.toString();

    function isInputValid(inputAsString:string) {
      const regex = new RegExp("^[0-9]+$");
      const isValid = regex.test(inputAsString);
      setValid((prevState) => 
         ({
          ...prevState,
          [assign]: isValid,
        })
      );
    }

    isInputValid(inputAsString)

    if (input.length > 200) {
      return setValid((prevState) => {
        return {
          ...prevState,
          [assign]: false,
        };
      });
    }
    if (parseInt(input) <= 0) {
      return setValid((prevState) => {
        return {
          ...prevState,
          [assign]: false,
        };
      });
    }
    
  }

  useEffect(() => {
    formAddPreset.setFieldsValue({
      State: values.State,
      Name: values.Name,
      BitrateVideo: values.BitrateVideo,
      BitrateAudio: values.BitrateAudio,
      SamplerateAudio: values.SamplerateAudio,
      Framerate: values.Framerate,
      CreatedAt: values.CreatedAt,
      UpdatedAt: values.UpdatedAt,
      Width: values.Width,
      Height: values.Height,
      SortBitrate: values.SortBitrate,
    });
  }, []);

  const handleSubmitEditingPreset = async () => {
    await UpdatePreset({
      variables: {
        input: {
          id: ServerId,
          BitrateVideo: values.BitrateVideo,
          BitrateAudio: values.BitrateAudio,
          SamplerateAudio: values.SamplerateAudio,
          Framerate: values.Framerate,
          State: values.State,
          Name: values.Name,
          Width: values.Width,
          Height: values.Height,
          SortBitrate: values.SortBitrate,
        },
      },
    });
    onResetPreset();
  };

  ////*************** show Modal Function ******************/////////
  const handleShowModal = async () => {
    setVisible(true);
  };
  const handleCancel = () => {
    dispatch(setPresetDataActions({ msg: "", err: false, preset: {} }));
    setVisible(false);
  };

  ///******** delete Mooc ********///
  const handleDeletePreset = (ids: any) => {
    deleteMultiPreset(ids);
  };

  /////********* show add edit Preset ****************/////
  const handleAddPresetSubmit = async () => {
    await AddPreset({
      variables: {
        input: {
          BitrateVideo: values.BitrateVideo,
          BitrateAudio: values.BitrateAudio,
          SamplerateAudio: values.SamplerateAudio,
          Framerate: values.Framerate,
          State: values.State,
          Width: values.Width,
          Height: values.Height,
          SortBitrate: values.SortBitrate,
          Name: values.Name,
        },
      },
    });
    onResetPreset();
  };
  const handleAddPreset = () => {
    dispatch(
      setshowDivsConditionsPreset({
        showDivsConditionsName: "addPreset",
        showDivsConditionsValue: true,
      })
    );
  };

  const onResetPreset = async () => {
    /**edit item list */
    dispatch(
      setshowDivsConditionsPreset({
        showDivsConditionsName: "action",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setshowDivsConditionsPreset({
        showDivsConditionsName: "editPreset",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setshowDivsConditionsPreset({
        showDivsConditionsName: "addPreset",
        showDivsConditionsValue: false,
      })
    );
    dispatch(
      setshowDivsConditionsPreset({
        showDivsConditionsName: "elementSelected",
        showDivsConditionsValue: "",
      })
    );

    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "Name",
        addPresetConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "BitrateVideo",
        addPresetConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "BitrateAudio",
        addPresetConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "SamplerateAudio",
        addPresetConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "Framerate",
        addPresetConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "CreatedAt",
        addPresetConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "UpdatedAt",
        addPresetConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "Width",
        addPresetConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "Height",
        addPresetConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "State",
        addPresetConstraintsDataValueChange: false,
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "SortBitrate",
        addPresetConstraintsDataValueChange: "",
      })
    );
    dispatch(
      setPaginationPropsPreset({
        PaginationPropsNameChange: "id",
        PaginationPropsValueChange: [],
      })
    );
  };

  ////****************** function Filter *****************///////
  //*********************** search header **************///////
  const handleSearchRow = (event: any) => {
    if (event.key === "Enter") {
      SearchByKeyWordPreset({
        variables: { where: { nameContains: event.target.value } },
      });
    } else if (event === "") {
      SearchByKeyWordPreset({ variables: { where: { nameContains: event } } });
    }
  };

  const handelSearch = (word: string, tab: any) => {
    if (word.trim() === "") return tab;

    // eslint-disable-next-line array-callback-return
    const res = tab.filter((obj: any) => {
      if (obj.Name.toLowerCase().includes(word.toLowerCase())) return obj;
    });
    return res;
  };

  

  /////**************** Form Add Preset *******************/////////

  ////////****** Edit Preset ***************////
  const handleEditPreset = (record: any) => {
    dispatch(
      setshowDivsConditionsPreset({
        showDivsConditionsName: "editPreset",
        showDivsConditionsValue: true,
      })
    );
    dispatch(
      setshowDivsConditionsPreset({
        showDivsConditionsName: "elementSelected",
        showDivsConditionsValue: record.Id,
      })
    );

    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "Name",
        addPresetConstraintsDataValueChange: record.Name,
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "BitrateVideo",
        addPresetConstraintsDataValueChange: record.BitrateVideo,
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "BitrateAudio",
        addPresetConstraintsDataValueChange: record.BitrateAudio,
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "SamplerateAudio",
        addPresetConstraintsDataValueChange: record.SamplerateAudio,
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "Framerate",
        addPresetConstraintsDataValueChange: record.Framerate,
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "CreatedAt",
        addPresetConstraintsDataValueChange: record.CreatedAt,
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "UpdatedAt",
        addPresetConstraintsDataValueChange: record.UpdatedAt,
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "Width",
        addPresetConstraintsDataValueChange: record.Width,
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "Height",
        addPresetConstraintsDataValueChange: record.Height,
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "State",
        addPresetConstraintsDataValueChange: record.State,
      })
    );
    dispatch(
      setAddPresetActions({
        addPresetConstraintsDataNameChange: "SortBitrate",
        addPresetConstraintsDataValueChange: record.SortBitrate,
      })
    );
  };

  /********************************** */
  /////**************** Disabled btn add/edit *******************/////////
  const disabledsubmitbutton = async (type: any) => {
    if (type === "disabled") {
      await dispatch(
        setshowDivsConditionsPreset({
          showDivsConditionsName: "disablesubmitform",
          showDivsConditionsValue: false,
        })
      );
    } else if (type === "notdisabled") {
      await dispatch(
        setshowDivsConditionsPreset({
          showDivsConditionsName: "disablesubmitform",
          showDivsConditionsValue: true,
        })
      );
    }
  };
  ///******* handle Api *******/

  const deletePreset = async (id: number) => {
    dispatch({
      type: PresetTypes.LOADING,
    });

    await DeleteData("preset/delete/" + id)
      .then(({ data }: any) => {
        if (data.code === 200) {
          dispatch(setPresetDataActions({ msg: "", error: false }));
          message.success({ content: t("successdelete"), duration: 2 });
        } else {
          const msg = HandleFaildApiMessage(data.code, data?.message);
          dispatch(setPresetDataActions({ msg, error: true }));
          message.error({ content: t("messageerroroperation"), duration: 2 });
        }
      })
      .catch((err) => {
        const msg = HandleFaildApiMessage(
          err.response.data.code,
          err.response.data?.message
        );
        dispatch(setPresetDataActions({ msg, error: true, list: [] }));
      });
  };
  const deleteMultiPreset = async (ids: any) => {
    dispatch({
      type: PresetTypes.LOADING,
    });

    await DeleteData("multiDelete/presets/" + ids)
      .then(({ data }: any) => {
        if (data.code === 200) {
          dispatch(setPresetDataActions({ msg: "", error: false }));
          dispatch(
            setPaginationPropsPreset({
              PaginationPropsNameChange: "id",
              PaginationPropsValueChange: [],
            })
          );
          dispatch(
            setshowDivsConditionsPreset({
              showDivsConditionsName: "elementSelected",
              showDivsConditionsValue: 0,
            })
          );
        } else {
          dispatch(setPresetDataActions({ msg: "error", error: true }));
        }
      })
      .catch((err) => {
        const msg =
          err.response && err.response.status === 404 ? "server  down" : err;
        dispatch(setPresetDataActions({ msg, error: true }));
      });
  };

  const HandleFaildApiMessage = (code: any, msg: string) => {
    switch (code) {
      case 404:
        return "page not found";
        break;
      case 401:
        return "you are not authorized";
        break;
      case 500:
        return "serveur error";
        break;

      default:
        return msg;
        break;
    }
  };

  ///******* end handle Api *******/

  const handelFilter = (type: any, tab: any) => {
    function smallval(item: any) {
      if (type === "All") {
        return item;
      } else if (type !== "") {
        return item.type === type;
      } else {
        return item;
      }
    }

    const res = tab.filter(smallval);

    return res;
  };

  const useWindowSize = () => {
    const [size, setSize] = useState(0);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  };

  return {
    handleAddPreset,
    onResetPreset,
    formAddPreset,
    handleSearchRow,
    handleShowModal,
    visible,
    handleCancel,
    EditCondition,
    handleEditPreset,
    values,
    handelSearch,
    handelFilter,
    deletePreset,
    handleDeletePreset,
    disabledsubmitbutton,
    handleAddPresetSubmit,
    handleSubmitEditingPreset,
    useWindowSize,
    validateString,
    validateNumber,
    valid,
    usePrevious
  };
};
export default Hooks;
