
import { useTranslation } from "react-i18next";
import { useMediaQuery } from '@material-ui/core';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UseActionMenu from "../../serveur/components/List/actionsUserTable";
export const DataServeur = () => {
    const showPhoneColumns = useMediaQuery('(min-width:757px)');
    const { t } = useTranslation();

    const TableColumns = [
        {
          headerName: t('Serveur.type'),
          field: 'Type',
          width:showPhoneColumns ? 200 : 100,
          flex: 1
      },
      {
          headerName: t('Serveur.access'),
          field: 'Internet',
          renderCell:(params :any) =>{
           return <>
           {params?.row?.Internet === true ? <CheckCircleIcon className={'checkedicon'}/>:<HighlightOffIcon className={'closeicon'}/>}
           </>
        },
        width:showPhoneColumns ? 200 : 100,
        flex: 1
    
      },
      {
          headerName: t('Serveur.identify'),
          field: 'Name',
          width:showPhoneColumns ? 300 : 100,
          flex: 1
      },
      {
          headerName: t('Serveur.url'),
          field: 'UrlDiffusion',
          width:showPhoneColumns ? 400 : 200,
          flex: 1
      },
      {
          headerName: t('Serveur.urlMp4'),
          field: 'UrlMp4',
          width:showPhoneColumns ? 400 : 200,
          flex: 1
      },
      {
        headerName: t('Serveur.actions'),
        field: 'action',
        editable: false,
        sortable: false,
        width:showPhoneColumns ? 310 : 100,
        flex: 1,
        renderCell: (params: any) => {
            return (
                <UseActionMenu record={params} />
            )
        }
    }
    
    ];
    
   
    const getListType = [
        {value: 'All', label:'All'}, 
        {value: 'Real', label:'Real'},
        {value: 'WinMedia', label:'WinMedia'},
        {value: 'Akamai', label:'Akamai'},
        {value: 'VOD', label:'VOD'},
        {value: 'QuickTime', label:'QuickTime'},
        {value: 'Flv', label:'Flv'},
        {value: 'static', label:'static'},
    ]

    const ListTypeadd = [
        {value: 'Real', label:'Real'},
        {value: 'WinMedia', label:'WinMedia'},
        {value: 'Akamai', label:'Akamai'},
        {value: 'VOD', label:'VOD'},
        {value: 'QuickTime', label:'QuickTime'},
        {value: 'Flv', label:'Flv'},
        {value: 'static', label:'static'},
    ]

    const ListserverType = [
        {value: 'Buffer', label:'Buffer'}, 
        {value: 'Wowza', label:'Wowza'}, 
        {value: 'Akamai', label:'Akamai'}
    ]
    const Listhttp=[
        {value: 'Http', label: 'Http'}, 
        {value: 'rtmp', label: 'rtmp'},
        {value: 'ftp', label: 'ftp'},
        {value: 'hds_Wowza', label: 'hds_Wowza'},
        {value: 'hds_ambr', label: 'hds_ambr'},
        {value: 'hls', label: 'hls'},
        
    ]
    const Optionscheckbox=['Internet','Record']
    return ({
        TableColumns,
        getListType,
        ListTypeadd,
        ListserverType,
        Listhttp,
        Optionscheckbox

    })

}
