import React, { useState, useEffect } from 'react';
import useDataTableMedia from "./DataTable";
import {useAppSelector} from "../../../../../Redux/hooks";
import {setOpenModalDel} from "../../store/videoshomeActions";
import {useDispatch} from "react-redux";
import {DataVideoshome} from "../../utils/Data";
import {useTranslation} from "react-i18next";
import Hooks from "../../utils/hooks";
import '../../videoshome.scss' ; 
import { SelectList } from '@empreinte/components.select-list';
import { SearchBar } from '@empreinte/components.search-bar';
import DeleteIcon from '@mui/icons-material/Delete';
import { ModalConfirm } from '@empreinte/components.modal-confirm';
import { GraphQLFetchData } from  "../../utils/GraghFetchData"

const VideoshomeTable = () =>{

    const dispatch = useDispatch()
    const {t} = useTranslation();
    const listChoices = useAppSelector((state) => state.VideoshomeReducer.ListChannelsChoices)
    const valuePagination = useAppSelector((state) => state.VideoshomeReducer.paginationPropsVideoshome)
    const values = useAppSelector((state) => state.VideoshomeReducer.filterVideoshome)
    const {columns, ListType} = DataVideoshome()
    const data = useAppSelector((state) => state.VideoshomeReducer.dataVideoshome)
    const {handleSearchRow , onChangeFolder,getListVideoshome,getListVofcanal} = Hooks()

    const isSelected = useAppSelector((state)=>state.VideoshomeReducer.selectedCase)
    const openDelSpace = useAppSelector((state)=>state.VideoshomeReducer.showDeleteSpace)
    const openModalDel = useAppSelector((state)=>state.VideoshomeReducer.showDelModal)
    const [openModal,setOpenModal] = useState(false)
    const numberOfselectedRows = isSelected.length;
    const {multiDel}=Hooks()
    const {multiDelete} = GraphQLFetchData()
    const [searchText,setSearchText]=useState('')
    const [currenPage,setCurrentPage]=useState(1)
   
    const length = useAppSelector((state)=>state.VideoshomeReducer.totalRecord)
    const statusDel = useAppSelector((state)=>state.VideoshomeReducer.statusDel)
    const channelSelected = useAppSelector((state)=>state.VideoshomeReducer.ChannelSelected)
    const {
        DataTable,
        resetPagination,
        pageSize
    } = useDataTableMedia({
        columns: columns,
        dataSource: data,
        updateEntityPath: 'update-product',
    });
    
    const onSearch = (value:any) => {
    setSearchText(value.target.value);
    handleSearchRow(value.target.value)
    if(channelSelected === "tous"){
        getListVideoshome(currenPage,pageSize,value.target.value)
    }else{
        getListVofcanal(currenPage,pageSize,channelSelected,value.target.value)
    }
   setCurrentPage(1)
    }
    useEffect(() => {

    }, [listChoices])
    const del = t('Videoshome.delete')
    const choices = listChoices.map((item:any , key:any)=>{
        return {value:item.value , label:item.id}
        })
        const choicesWithDefault = [
            { value: "tous", label: `${t('Canal')}` },
            ...choices,
          ];
    return(
        <div className={"divCard"}>
            <div className="contentTable">
            <div className="rect_colonne">

            {
                        !openDelSpace
                            ?
                            null
                            :
                            <>
                            <div className="delete_number" onClick={()=>{
                               dispatch(setOpenModalDel(true))
                               setOpenModal(true)
                            }}>
                                <DeleteIcon  className="btnDelet" />
                                 <p> <span id={"text_selection"}>{numberOfselectedRows + ' ' + t('configuration.Videoshome.selectedItem')}</span>
                                    </p>
                            </div>
                        
                          
                        </>
                     }
                    {openModal &&(
                     <ModalConfirm
                        open={openModal}
                        onClose={()=>setOpenModal(false)}
                        title={t('DeleteMulti')}
                        content={numberOfselectedRows === 1 ?t('DeleteConfirmationOneDelete'):t('DeleteConfirmationMultiDelete')}
                        fullWidth={true}
                        CancelBtnName={t('No')}
                        ConfirmBtnName={t('Yes')}
                        onCancel={()=>setOpenModal(false)}
                        onConfirm={() =>{
                             multiDel()
                            dispatch(setOpenModalDel(false))
                            setOpenModal(false)
                        }
                          
                        
                        }
                        ></ModalConfirm>
                )
                   
                }
                   <div className="canalList">   
                    <SelectList
                    className='canalList'
                    handleChange={onChangeFolder}
                    defaultValue="tous"
                    data={choicesWithDefault}
                    ></SelectList>
</div>  
                <div className="Filter">
        
                   
                    <div className={"div_rect"}>
                        <div className="rect_filter">
                       
                            <SearchBar
                            className='searchBar'
                            placeholder={t('configuration.Videoshome.search')}
                            name={"search"}
                            // onKeyDown={(e)=>handleSearchRow(e)}
                            setSearchText={onSearch}
                            />

                    

                        </div>
                    </div>
                    
                </div>{/*./Filter*/}
            </div>       
            <div className={"dataTable"}>
            <DataTable/>
            </div>
            </div>
            {/*./dataTable*/}


        </div>
    )
}

export default VideoshomeTable
