
import  axios  from "axios";
const search = window.location.search;
const token = new URLSearchParams(search).get("token");

const headers={
   Authorization: `Bearer ${token}`,
"Accept":"application/json, text/plain, */*",
"User-Agent":"Mozilla/5.0 (Windows NT 6.1; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.110 Safari/537.36",
"Content-Type":"application/json;charset=UTF-8",
"Origin":window.process.env.Origin ,
"Referer":window.process.env.Referer,
}


const url = "https:" + window.process.env.API_HOST_GLOBAL
const urlVideo = window.process.env.VIDEO_API;


export const PostData = async (path, data, header) => {
  return await axios.post(`${url}${path}`, data, {headers});
};
export const GetData = async (path, da) => {
  return await axios.get(`${url}${path}`,{
    headers
  });
};
export const DeleteData = async (path) => {
  return await axios.delete(`${urlVideo || url}${path}`, {headers});
};
export const UpdateData = async (path, data) => {
  return await axios.put(`${url}${path}`, data, {headers});
};
export const SendAccess = async (path, sessionId, action) => {
  try {
    return await axios.post(`${url}${path}`, {
      EmpStatsQuery : {
        session_id: sessionId,
        action: action,
      }

    },{headers});
  } catch (e) {
    console.error("ERROR Send access :", e);
  }
};

