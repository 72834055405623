import React, { useEffect,useState } from "react";
import { Tooltip, Button } from "@mui/material";
import useDataTableMedia from "./DataTable";
import { useAppSelector } from "../../../../Redux/hooks";
// import { DataPreset } from "../../utils/Data";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import "../../preset.scss";
import { SearchBar} from '@empreinte/components.search-bar';
import DeleteIcon from '@mui/icons-material/Delete';
import { ModalConfirm } from '@empreinte/components.modal-confirm';
import {GraphQLFetchData} from "../../utils/GraphFetchData"

const PresetTable = ({setSearchText, searchText, setCurrentPage,currentPage,setPageSize,pageSize}:any) => {
  const { t } = useTranslation();
  const valuePagination = useAppSelector(
    (state) => state.PresetReducer.paginationPropsPreset
  );
  const { configuration_presets_module } = useAppSelector(
    (state) => state.ReducerUserData.modules
  );
  const isSuperAdmin = useAppSelector((state: any) => state.ReducerUserData.isSuperAdmin)
  const {DeletePreset} = GraphQLFetchData()

  const [deleteMultiple, setDeleteMultiple] = useState(false)

  const { DataTable } = useDataTableMedia({
    updateEntityPath: "update-product",
  });

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
   }
   const delet = t("Serveur.delete");
 

  return (
    <div>
      <div className="main-page-preset">
          <>
            
              <ModalConfirm 
              className="config-server-modal"
              open={deleteMultiple}
              fullWidth
              onClose={()=>setDeleteMultiple(false)}
              title={t("DeleteConfirmation")}
              content={t("DeleteConfirmationContentPresetMultiple")}
              CancelBtnName={t("No")}
              onCancel={()=>setDeleteMultiple(false)}
              ConfirmBtnName={t("Yes")}
              onConfirm={() => {DeletePreset({variables : {ids:valuePagination.id}});setDeleteMultiple(false)}}
              />
            {valuePagination.id.length >= 1 ? (
              <>
              <Tooltip title={delet}>
                <Button 
                className="btn-server-multidelete"
                style={{display: "flex", alignItems:"center", color:"black", fontSize:"1.5rem", borderRadius: "90%"}}
                startIcon={<DeleteIcon />}
                onClick={() => setDeleteMultiple(true)}
                />
              </Tooltip>
              </>
            ): null}
              
            
          </>
    
       <SearchBar 
          className="search-bar-preset-main"
          name="search"
          placeholder={t("Serveur.search")}
          setSearchText={handleSearch}
          value={searchText}
          />
      </div>

      {(configuration_presets_module?.configuration_presets_list_dtable_action
        ?.authorized || isSuperAdmin) && (
        <div
          has-permission="configuration_presets_list_dtable"
          className={"dataTable"}
        >
          <DataTable searchText={searchText}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPageSize={setPageSize}
          pageSize={pageSize}/>
        </div>
      )}
    </div>
  );
};

export default PresetTable;
