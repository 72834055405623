
export function setPaginationPropsClient(e:any){
    return{
        type:"SET_PaginationPropsClient",
        payload:e,
    }
}
export function setLoader(e:any) {
    return {
        type:"SET_loader",
        payload:e,
    }
}
export function setshowDivsConditionsClient(e:any){
    return {
        type : "SET_showDivsConditionsClient",
        payload:e
    }
}

export function setTrashContainer (e:any) {
    return {
        type:"SET_trash",
        payload:e,
    }
}

export function setIdSet (e:any) {
    const idList = e.map((e:any)=>e);
    return {
        type:"SET_IDSET",
        payload:idList
    }
};

export function setRightsLoader(e:any) {
    return {
        type:"Set_rights_loader",
        payload:e
    }
}

export function setRowId (e:any) {
    return {
        type:"Set_Row_id",
        payload:e,
    }
}

export function setmultiDeletemodal (e:any) {
    return  {
        type:"SET_multiModal",
        payload:e
    }
}

export function Set_totlaEn (e:any) {
    return {
        type:"Set_totlaEn",
        payload:e
    }
}

export function setDeleteLoader (e:any) {
    return {
        type:"Set_Delete_Loader",
        payload:e
    }
}



export function setAddClientActions(e:any){
    return{
        type:"SET_AddClientActions",
        payload:e,
    }
}

export function setFilterClientActions(e:any){

    return{
        type:"SET_FilterClient",
        payload:e,
    }

}

export function setEditClientActions(e:any){
    return{
        type:"SET_EditClientActions",
        payload:e,
    }
}

export function setDataClientActions(e:any){
    return{
        type:"SET_DataClientActions",
        payload:e,
    }
}

export function setDetailClientActions(e:any){
    return{
        type:"SET_DetailClientActions",
        payload:e,
    }
}