import React from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../Redux/hooks";
import Hooks from "../utils/hooks";
import {Header} from "@empreinte/components.header"
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AddToPhotosOutlinedIcon from '@mui/icons-material/AddToPhotosOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import AddIcon from '@mui/icons-material/Add';
import "../client.scss"
const ClientHeader = () => {
  const { t } = useTranslation();

  const { handleAddClient, onResetClient ,handleSubmitEditingClient,handleSubmitAddClient} = Hooks();
  const showDivCondition = useAppSelector(
    (state) => state.ClientReducer.showDivsConditionsClient
  );
  const disablesubmitform = useAppSelector(
    (state) => state.ClientReducer.showDivsConditionsClient.disablesubmitform
  );
  const { param_module } = useAppSelector(
    (state) => state.ReducerUserData.modules
  );
  const isSuperAdmin = useAppSelector((state: any) => state.ReducerUserData.isSuperAdmin)
    
  return (
    <div>
            <Header hasPermission='true'  hasIcon={true} title={
             !showDivCondition.addClient && !showDivCondition.editClient ? (
              <span className={"Client-TitleHeader"}>{t("configuration.Client.client")}</span>
            ) : showDivCondition.addClient ? (
              <>
                <span className={"Client-TitleHeader"}>{t("configuration.Client.addclient")}</span>
              </>
            ) : showDivCondition.editClient ? (
              <>
                <span className={"Client-TitleHeader"}>{t("modifier")}</span>
              </>
            ) : (
              ""
            )
            } firstBtnTitle={t("add")}
             mainPageBtnAction={
              !showDivCondition.addClient && !showDivCondition.editClient && (param_module?.param_post_add_action?.authorized || isSuperAdmin) 
              ? handleAddClient 
              : null
            }
             thirdBtnTitle={showDivCondition.addClient ? t("configuration.Client.register") : showDivCondition.editClient ? t("edit") : null}
             addBtnAction={
              showDivCondition.addClient ? handleSubmitAddClient : handleSubmitEditingClient
             }
             secondBtnTitle={"Cancel"}
             firstBtnIcon={<AddIcon/>}
             arrowBackAction={onResetClient}
             isDisabled={disablesubmitform}
             CancelBtnAction={showDivCondition.addClient || showDivCondition.editClient ? onResetClient : null}
            onMainPage={!showDivCondition.addClient && !showDivCondition.editClient } />
   
    </div>
  );
};

export default ClientHeader;
