export function setPaginationPropsVideoshome(e: any) {
    return {
        type: "SET_PaginationPropsVideoshome",
        payload: e,
    }
  }
  
  
  export function setFilterVideoshomeActions(e: any) {
  
    return {
        type: "SET_FilterVideoshome",
        payload: e,
    }
  
  }
  
  
  export function setListVideoshomeActions(e: any) {
    return {
        type: "SET_ListVideoshome",
        payload: e,
    }
  }
  
  export function setDataVideoshomeActions(e: any) {
    return {
        type: "SET_DataVideoshomeActions",
        payload: e,
    }
  }
  
  
  export function setChannelSelectedVideoshomeActions(e: any) {
    return {
        type: "SET_ChannelSelectedVideoshomeActions",
        payload: e,
    }
  
  }
  export function setErrorrMessageVideoshomeActions(e: any) {
  
    return {
        type: "SET_ErrorrMessage",
        payload: e,
    }
  }
  
  export function setmultiCase(e:any){
    return {
        type:"SET_MULTIDEL",
        payload: e
    }
  }
  export function setVideoLink(e:any){
    return{
        type:"SET_VIDEOLINK",
        payload: e
    }
  }
  export function setOpenModal(e:any){
    return{
        type:"SET_OpenModal",
        payload: e
    }
  }
  export function setLoading(e:any){
    return {
        type:"SET_LOADING",
        payload:e,
    }
  }
  export function setSelected(e:any){
    const listSelected = e.map((e:any)=>e);
    return {
        type:"SET_IDSELECTED",
        payload:listSelected,
    }
  }
  
  export function setOpenDeleteSpace(e:any){
    return{
        type:"SET_DELETESPACE",
        payload:e
    }
  }
  export function setOpenModalDel(e:any){
    return{
      type: "SET_MODALDEL",
      payload:e
    }
  }
  export const showNotification = (message:any, notificationType:any) => ({
    type: 'SHOW_NOTIFICATION',
    payload: { message, notificationType },
  });
  

