import { useEffect, useLayoutEffect, useState } from "react";
import ServeurHeader from "./components/serveurHeader";
import ServeurTable from "./components/List/serveurTable";
import AddEditServeur from "./components/AddEdit/addEdit";
import "./serveur.scss";
import { useAppSelector, useAppDispatch } from "../../Redux/hooks";
import Hooks from "./utils/hooks";
import { Grid } from "@material-ui/core";
import { getUserData } from "../../userData/actions";
import {GraphQLFetchData} from "./utils/GraphFetchData";
import LoadingScreen from '../serveur/utils/LoadingScreen';
import Notfound from '../Notfound'
import i18next from "i18next";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Serveur = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {GetListServers} = GraphQLFetchData()
  const {search} = useLocation()
  const obj = new URLSearchParams(search);
  const {
    formAddServeur,
  } = Hooks();
  const showDivCondition = useAppSelector(
    (state) => state.ServeurReducer.showDivsConditionsServeur
  );
  const [searchText, setSearchText] = useState("");
  const [valueFilter,setValueFilter] = useState( { label: `${t("Serveur.all-groups")}`, value: `${t("Serveur.all-groups")}` })
  const [deleteMultiple, setDeleteMultiple] = useState(false)

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const loadingscreen = useAppSelector(
    (state) => state.ReducerUserData.loading_screen
  );

  const unauthorizedUser = useAppSelector(
    (state) => state.ReducerUserData.unauthorizedUser
  );
  const values = useAppSelector(
    (state) => state.ServeurReducer.addServeurConstraintsData
  );
  const actionTrace = useAppSelector(
    (state) => state.ServeurReducer.paginationPropsServeur.deleteActionsTrace
  );
  useEffect(() => {
    dispatch(getUserData());
  }, [dispatch]);

  useEffect(()=>{
    const lang = obj.get("lang")
    if(lang==="en" || lang==="fr"){
      i18next.changeLanguage(lang)
    }
},[obj.get("lang")]);
  useEffect(() => {
    const offset = (currentPage - 1) * pageSize;
    let filterWord = ((valueFilter.value as any !== "Tous Les Groupes") && (valueFilter.value as any !== "All The Groups"))   ? valueFilter.value : ""
    const whereObj:any = {}
    if(searchText) {whereObj.nameContains = searchText}
    if(filterWord) {whereObj.typeContains = filterWord}
      GetListServers({
        variables: {
          ...((searchText||filterWord) && {where:whereObj}),
          pagination: { limit: pageSize, offset },
        }
      })
}, [currentPage, pageSize, searchText,unauthorizedUser,valueFilter,actionTrace]);

useLayoutEffect(() => {
    if (showDivCondition.editServeur) {
      formAddServeur.setFieldsValue({
        Protocol: values.Protocol,
        Type: values.Type,
        Internet: values.Internet,
        Record: values.Record,
        IsDisplay: values.Internet,
        IsBuffer: values.IsBuffer,
        Dir:values.Dir,
        UrlVideo:values.UrlVideo,
        UrlMp4:values.UrlMp4,
        UrlDiffusion:values.UrlDiffusion,
        UrlVodAndroid:values.UrlVodAndroid,
        UrlLiveAndroid:values.UrlLiveAndroid,
        UrlCdnAndroid:values.UrlCdnAndroid,
        UrlVodIos:values.UrlVodIos,
        UrlLiveIos:values.UrlLiveIos,
        UrlCdnIos:values.UrlCdnIos,
        DirLecture:values.DirLecture,
        UrlBackup:values.UrlBackup,
        UrlMobile:values.UrlMobile,
        UrlServerFtp:values.UrlServerFtp,
        LoginLive:values.LoginLive,
        Name:values.Name,
        Login:values.Login,
        Pwd:values.Pwd,
        PwdLive:values.PwdLive,
        Bitrate:values.Bitrate,
        ServerName:values.ServerName,
        ServerType:values.ServerType,
        Port: values.Port,
        SshKey:values.SshKey,
        StreamPlaylistLive:values.StreamPlaylistLive,
        Notes:values.Notes,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDivCondition.editServeur]);

  useLayoutEffect(() => {
    if (showDivCondition.addServeur) {
      formAddServeur.setFieldsValue({
        Protocol: "",
        Type:"",
        Internet: false,
        Record: false,
        IsDisplay: false,
        IsBuffer: false,
        Dir:"",
        UrlVideo:"",
        UrlMp4:"",
        UrlDiffusion:"",
        UrlVodAndroid:"",
        UrlLiveAndroid:"",
        UrlCdnAndroid:"",
        UrlVodIos:"",
        UrlLiveIos:"",
        UrlCdnIos:"",
        DirLecture:"",
        UrlBackup:"",
        UrlMobile:"",
        UrlServerFtp:"",
        LoginLive:"",
        Name:"",
        Login:"",
        Pwd:"",
        PwdLive:"",
        Bitrate:"",
        ServerName:"",
        ServerType:"",
        Port: 0,
        SshKey:"",
        StreamPlaylistLive:"",
        Notes:"",
      });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDivCondition.addServeur]);

  if (loadingscreen) {
    return <LoadingScreen/>
  } else if (unauthorizedUser) {
    return <Notfound/>
}

  return (
    <div className="server-table-main-page">
      {!showDivCondition.addServeur && !showDivCondition.editServeur ? (
        <div>
          <ServeurHeader />
          <ServeurTable 
          searchText={searchText}
          setSearchText={setSearchText}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          valueFilter={valueFilter}
          setValueFilter={setValueFilter}
          deleteMultiple={deleteMultiple}
          setDeleteMultiple={setDeleteMultiple}
          />
        </div>
      ) : (
        <div>
          <Grid>
            <ServeurHeader />
            <AddEditServeur />
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Serveur;