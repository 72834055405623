import React, { useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from "@mui/material";
import { InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import { useAppSelector } from "../../../../Redux/hooks";
import "../../serveur.scss";
import {GraphQLFetchData} from "../../utils/GraphFetchData";
import { ModalConfirm } from '@empreinte/components.modal-confirm';
import { ModalInputs } from '@empreinte/components.modal-inputs';
import { Helpers } from "../../../../utils/helpers";

function useActionMenu({ record }: any) {
  const {DeleteServer} = GraphQLFetchData()
  const { Deleteselecteditem } = Hooks()
  const {acces_send} = Helpers()
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    overflow: "scroll",
    height: 600,
    pl: 3,
    pr: 1,
    py: 1,
  };
  const { t } = useTranslation();
  const {
    handleShowModal,
    visible,
    handleCancel,
    GetOneServerInfoToEdit
  } = Hooks();
  const datarecord = useAppSelector(
    (state) => state.ServeurReducer.detailServeur
  );
  const details = t("Serveur.details");
  const edit = t("Serveur.edit");
  const delet = t("Serveur.delete");
  const { configuration_server_module } = useAppSelector(
    (state) => state.ReducerUserData.modules
  );
  const isSuperAdmin = useAppSelector((state: any) => state.ReducerUserData.isSuperAdmin)
  const [confirmationDelete,setConfirmationDelete]= useState(false)
  const [deleteMultiple, setDeleteMultiple] = useState(false)
  
  return (
    <div>
      {
        deleteMultiple ? (
          <div>
          <ModalConfirm 
          className="config-server-modal"
          open={confirmationDelete}
          fullWidth
          onClose={()=>setDeleteMultiple(false)}
          title={t("DeleteConfirmation")}
          content={t("DeleteConfirmationContentM")}
          CancelBtnName={t("No")}
          onCancel={()=>setDeleteMultiple(false)}
          ConfirmBtnName={t("Yes")}
          onConfirm={()=>{DeleteServer({variables : {id:record.Id}});acces_send(`${configuration_server_module?.configuration_server_delete_multi_action?.name}_action`)}}
          />

      </div>
        ): null
      }
      {confirmationDelete ? (
        <div>
          <ModalConfirm 
          className="config-server-modal"
          open={confirmationDelete}
          fullWidth
          onClose={()=>setConfirmationDelete(false)}
          title={t("DeleteConfirmation")}
          content={t("DeleteConfirmationContent")}
          CancelBtnName={t("No")}
          onCancel={()=>setConfirmationDelete(false)}
          ConfirmBtnName={t("Yes")}
          onConfirm={()=>{Deleteselecteditem(record.Id); setConfirmationDelete(false);acces_send(`${configuration_server_module?.configuration_server_delete_server_action?.name}_action`)}}
          />

      </div>
      ) : <></>}
      <div className="action">
        {(configuration_server_module?.configuration_server_post_edit_action
          ?.authorized || isSuperAdmin) && (
            <div has-permission="configuration_server_post_edit">
            <Tooltip placement="top" title={edit}>
              <ModeEditIcon
                color="action" fontSize="small"
                className={"icon_action"}
                onClick={() => {GetOneServerInfoToEdit(record);acces_send(`${configuration_server_module?.configuration_server_post_edit_action?.name}_action`)}}
                />
            </Tooltip>
          </div>
        )}
        {(configuration_server_module?.configuration_server_get_detail_action
          ?.authorized || isSuperAdmin) && (
          <div has-permission="configuration_server_get_detail">
            <Tooltip placement="top" title={details}>
              <RemoveRedEyeIcon
                color="action" fontSize="small"
                className={"icon_action"}
                onClick={()=>{handleShowModal();acces_send(`${configuration_server_module?.configuration_server_get_detail_action?.name}_action`)}}
                
              />
            </Tooltip>
          </div>
        )}
        {(configuration_server_module?.configuration_server_delete_server_action
          ?.authorized || isSuperAdmin) && (
          <div has-permission="configuration_server_delete_server">
            <Tooltip placement="top" title={delet}>
              <DeleteIcon
                color="action" fontSize="small"
                className={"icon_action"}
                onClick={() => setConfirmationDelete(true)}
              />
            </Tooltip>
          </div>
        )}
        <ModalInputs
        className="modal-server-details" 
        open={visible}
        dividers
        title={t("Serveur.modaledittitle")}
        onClose={handleCancel}
        CancelBtnName={t("Serveur.close")}
        onCancel={handleCancel}
        children={
          <div className="server-details-modal">
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.protocol")}</span>
              <span className="server-details-modal-value">{record.Protocol ? record.Protocol : '--'}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.type")}</span>
              <span className="server-details-modal-value">{record.Type ? record.Type : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.internet")}</span>
              <span className="server-details-modal-value" style={{padding: '23px', paddingLeft:'0px'}}>
                                  <InputAdornment position="start" >
                                    {record.Internet === true ? (
                                      <>
                                        <CheckCircleIcon
                                          className={"checkedicon statusIcon"}
                                        />
                                        {t("Serveur.authorized")}
                                      </>
                                    ) : (
                                      <>
                                        <HighlightOffIcon
                                          className={"closeicon statusIcon"}
                                        />
                                        {t("Serveur.notauthorized")}
                                      </>
                                    )}
                                  </InputAdornment>
                                
                              
              </span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.name")}</span>
              <span className="server-details-modal-value">{record.Name ? record.Name : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.identify")}</span>
              <span className="server-details-modal-value">{record.Login ? record.Login : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.dir")}</span>
              <span className="server-details-modal-value">{record.Dir ? record.Dir : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.url")}</span>
              <span className="server-details-modal-value">{record.UrlVideo ? record.UrlVideo :"--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.urlMp4")}</span>
              <span className="server-details-modal-value">{record.UrlMp4 ? record.UrlMp4 : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.urlstreaming")}</span>
              <span className="server-details-modal-value">{record.UrlDiffusion ? record.UrlDiffusion : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.urlvideoandroid")}</span>
              <span className="server-details-modal-value">{record.UrlVodAndroid ? record.UrlVodAndroid : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.urldirectandroid")}</span>
              <span className="server-details-modal-value">{record.UrlLiveAndroid ? record.UrlLiveAndroid : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.urlcdnandroid")}</span>
              <span className="server-details-modal-value">{record.UrlCdnAndroid ? record.UrlCdnAndroid : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.urliosvideo")}</span>
              <span className="server-details-modal-value">{record.UrlVodIos ? record.UrlVodIos: "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.urlcdnios")}</span>
              <span className="server-details-modal-value">{record.UrlCdnIos ? record.UrlCdnIos : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.playbackdirectory")}</span>
              <span className="server-details-modal-value">{record.DirLecture ? record.DirLecture : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.urlrestore")}</span>
              <span className="server-details-modal-value">{record.UrlBackup ? record.UrlBackup : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.urlmobile")}</span>
              <span className="server-details-modal-value">{record.UrlMobile ? record.UrlMobile : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.urlftpserver")}</span>
              <span className="server-details-modal-value">{record.UrlServerFtp ? record.UrlServerFtp : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.loginlive")}</span>
              <span className="server-details-modal-value">{record.LoginLive ? record.LoginLive : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.bitrate")}</span>
              <span className="server-details-modal-value">{record.Bitrate ? record.Bitrate : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.port")}</span>
              <span className="server-details-modal-value">{record.Port ? record.Port : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.sshkey")}</span>
              <span className="server-details-modal-value">{record.SshKey ? record.SshKey : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.streamplaylistlive")}</span>
              <span className="server-details-modal-value">{record.StreamPlaylistLive ? record.StreamPlaylistLive : "--"}</span>
            </div>
            <div className="server-details-modal-container">
              <span className="server-details-modal-title">{t("Serveur.notes")}</span>
              <span className="server-details-modal-value">{record.Notes ? record.Notes : "--"}</span>
            </div>
          </div>
        }
        />
      </div>
    </div>
  );
}

export default useActionMenu;
