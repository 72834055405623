
export const MetadonnesList = () => {



    const showDivsConditionsMetadonnes = () => {
        return ({
            elementSelected: 0,
            addMetadonnes: false,
            editMetadonnes: false,
            disablesubmitform: true,
        })
    }




    return ({
       
        showDivsConditionsMetadonnes,
       
    })
}
