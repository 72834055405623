import {skinConstant} from "./skinConstant";

const { AddMediaConstraintsData,addSkin,AddSkinContraints,paginationProps } = skinConstant();

const skinINITIALSTATE = {
    AddMediaConstraintsData: AddMediaConstraintsData(),
    addSkin:addSkin(),
    paginationProps:paginationProps(),
    AddSkinContraints:AddSkinContraints(),
    skins:[],
    skinTotal:0,
    selectedSkinObject:[],
    skinId:'',
    statsPosition: { x: 0, y: 0 },
    link:'',
    loaderBox:false,
    editMode:false,
    addMode:false,
    skinType:false,
    loadingSkins:false,
    resetSkinPage:false,
    selectedTheme:"",
    ListThemes:[],
    ListChannels:[],
    ListVideosPerThemes:[],
    channelsSkin:[],
    themesSkin:[],
    nbVideosChannel:0,
    latestId:null,
    errorVideo:false
}


export const skinReducer = (state = skinINITIALSTATE, action) => {
    switch (action.type) {
        case "SET_AddSkin":
            const { addSkinDataNameChange, addSkinDataValueChange } = action.payload;
            return {
                ...state,
                addSkin: {
                    ...state.addSkin,
                    [addSkinDataNameChange]: addSkinDataValueChange
                }
            };
            case "SET_SKIN_CONDITION":
                const { showSkinConditionsName, showSkinConditionsValue } = action.payload;
                return {
                    ...state,
                    AddSkinContraints: {
                        ...state.AddSkinContraints,
                        [showSkinConditionsName]: showSkinConditionsValue
                    }
                };    
                case "SET_AddMEDIAActions":
                    const { addMediaConstraintsDataNameChange, addMediaConstraintsDataValueChange } = action.payload;
        
        
                    return {
                        ...state,
                        AddMediaConstraintsData: {
                            ...state.AddMediaConstraintsData,
                            [addMediaConstraintsDataNameChange]: addMediaConstraintsDataValueChange
                        }
                    };    
            case "GET_SKINS":
                return{
                     ...state,
                skins:action.payload   
                };
        case "SET_PaginationSkin":
            const {PaginationSkinNameChange, PaginationSkinValueChange} = action.payload;
            return {
                ...state,
                paginationProps: {
                    ...state.paginationProps,
                    [PaginationSkinNameChange]: PaginationSkinValueChange
                }
            };
            case "SET_SELECTED_SKIN":
                return {
                    ...state,
                    selectedSkinObject:action.payload
                }
            case "SET_SELECTED_THEME":
                return {
                    ...state,
                    selectedTheme:action.payload
                }        
            case "SET_TOTAL_SKINS":
                return{
                    ...state,
                    skinTotal:action.payload
                }    
            case "SKIN_ID":
                return {
                    ...state,
                    skinId:action.payload
                } 
            case "RESET_SKIN_PAGE":
                return {
                    ...state,
                    resetSkinPage:action.payload
                }    
            case "SKIN_BOXES_LOADER":
                return{
                    ...state,
                    loaderBox:action.payload
                } 
            case "SET_LOADING_SKINS":
                return {
                    ...state,
                    loadingSkins:action.payload
                }    
            case "SKIN_TYPE":
                return {
                    ...state,
                    skinType:action.payload
                }  
            case "SET_THEMES_SKIN":
                return {
                    ...state,
                    ListThemes:action.payload
                }       
            case "SET_CHANNELS_SKIN":
                return {
                    ...state,
                    ListChannels:action.payload
                }  
            case "SET_THEMES_SELECTED_SKIN":
                return {
                    ...state,
                    themesSkin:action.payload
                }       
            case "SET_CHANNELS_SELECTED_SKIN":
                return {
                    ...state,
                    channelsSkin:action.payload
                }  
            case "SET_VIDEOS_CHANNELS":
                return {
                    ...state,
                    nbVideosChannel:action.payload
                }       
                case "GET_URL_PLAYER":
            return {
                ...state,
                link: action.payload
            }     
            case "EDIT_MODE":
                return {
                    ...state,
                    editMode:action.payload
                }
            case "ADD_MODE":
                return {
                    ...state,
                    addMode:action.payload
                }          
            case "SET_STATS_POSITION":
                return {
                    ...state,
                    statsPosition: action.payload
                };
             case "GET_LATEST_VIDEO_ID":
                return {
                    ...state,
                    latestId:action.payload
                }
            case "SET_ERROR_VIDEO":
                return {
                    ...state,
                    errorVideo:action.payload
                }       
            case "RESET_STATE":
                return skinINITIALSTATE
    default:
        {
            return state;
        }
}
}