import "./App.css";
import Preset from "./MenuConfiguration/preset/Preset";
import Serveur from "./MenuConfiguration/serveur/serveur";
import Client from "./MenuConfiguration/client/client";
import Videoplayer from "./MenuConfiguration/videoplayer/videoplayer";
import Referent from "./MenuConfiguration/referent/referent";
import Videoshome from "./MenuConfiguration/homepage/videoshome/videoshome";
import Configuration from "./MenuConfiguration/homepage/configuration/configuration";
import Videopush from "./MenuConfiguration/homepage/videopush/videopush";
import SkinPage from "./MenuConfiguration/SkinPlayer/SkinPage";
import "./i18n";
import Metadonnes from "./MenuConfiguration/Metadonnes/metadonnes";
import Actuality from "./MenuConfiguration/Actuality/Actuality";
import Mooc from "./MenuConfiguration/Mooc/Mooc";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import Notfound from "./MenuConfiguration/Notfound";
import i18next from 'i18next';
// import { useLocation } from "react-router-dom";
import { useEffect } from "react";
function App() {
  //let params = new URLSearchParams(document.location.search);
  const queryParam = new URLSearchParams(document.location.search);
  useEffect(()=>{
    const lang = queryParam.get("lang")
    if(lang==="en" || lang==="fr"){
      i18next.changeLanguage(lang)
    }
},[queryParam.get("lang")]);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/configurations/preset" element={<Preset />} />
          <Route path="/configurations/serveur" element={<Serveur />} />
          <Route path="/configurations/client" element={<Client />} />
          <Route path="/configurations/videoplayer" element={<Videoplayer />} />
          <Route path="/configurations/referent" element={<Referent />} />
          <Route path="/configurations/videoshome" element={<Videoshome />} />
          <Route path="/configurations/configuration" element={<Configuration />} />
          <Route path="/configurations/videopush" element={<Videopush/>}/>
          <Route path="/configurations/actuality" element={<Actuality />} />
          <Route path="/configurations/metadonnes" element={<Metadonnes />} />
          <Route path="/configurations/mooc" element={<Mooc />} />
          <Route path="/configurations/skin" element={<SkinPage/>}/>
          <Route path="/configurations/*" element={<Notfound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
