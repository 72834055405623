
import '../../referent.scss'
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import {Card , TextField , Select , MenuItem ,FormControl} from '@mui/material';
const Referent = () => {

    const { t } = useTranslation();
    const { values } = Hooks();

    return (
<div className={"divCard"}>
      <Card> 
          <div>
          <TextField
          required
          id={"title"}
          name="content"
          label={t('Referent.name')}
          placeholder={t('Referent.name')}
          className="textField"
          defaultValue={values.url}
         inputProps={{pattern: new RegExp("^http://*"),}}
          multiline
          />

          <FormControl fullWidth style={{marginTop:"20px"}}>
        <Select
        defaultValue="Type"
        label="Type"
        >
        <MenuItem value="Liste noire" >Liste noire</MenuItem>
        <MenuItem value="Liste blanche" >Liste blanche</MenuItem>
        </Select>
        </FormControl>
            </div>
      </Card>
    </div>   
    )
}
export default Referent