import React from "react";
import { Button, Link, Breadcrumbs } from "@mui/material";
import {
  AddBoxOutlined,
  HomeOutlined,
  Close,
  CheckCircleOutlineOutlined,
  ArrowBack,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../Redux/hooks";
import Hooks from "../utils/hooks";

const VideoplayerHeader = () => {
  const { t } = useTranslation();
  const { handleAddVideoplayer, onResetVideoplayer, values } = Hooks();
  const showDivCondition = useAppSelector(
    (state) => state.VideoplayerReducer.showDivsConditionsVideoplayer
  );

  return (
    <div>
      <div className={"add_div"}>
        <div>
          {!showDivCondition.addVideoplayer &&
          !showDivCondition.editVideoplayer ? (
            <span className={"profile_title"}>
              {t("Videoplayer.videoplayer")}
            </span>
          ) : showDivCondition.addVideoplayer ? (
            <>
              <ArrowBack className="arrowleft" onClick={onResetVideoplayer} />
              <span className={"profile_title"}>
                {t("Videoplayer.addvideoplayer")}
              </span>
            </>
          ) : showDivCondition.editVideoplayer ? (
            <>
              <ArrowBack className="arrowleft" onClick={onResetVideoplayer} />
              <span className={"profile_title"}>
                {t("Videoplayer.modifvideoplayer")} : {values.type}
              </span>
            </>
          ) : (
            ""
          )}
        </div>
        <div className={"div_btn_add_cancel"}>
          {!showDivCondition.addVideoplayer &&
          !showDivCondition.editVideoplayer ? (
            <Button
              style={{ backgroundColor: "#1890FF" }}
              className={"btnSubmit"}
              startIcon={<AddBoxOutlined style={{ color: "white" }} />}
              onClick={handleAddVideoplayer}
            >
              <span style={{ color: "white" }} className={"spn_add"}>
                {t("Videoplayer.add")}
              </span>
            </Button>
          ) : (
            <div className={"div_btn_add_cancel"}>
              <Button
                style={{
                  backgroundColor: "white",
                  border: "solid 2px #1976D2",
                }}
                className={"btnCancel"}
                startIcon={<Close style={{ color: "#1890FF" }} />}
                onClick={onResetVideoplayer}
              >
                <span style={{ color: "#1890FF" }} className={"spn_cancel"}>
                  {t("Videoplayer.cancel")}
                </span>
              </Button>
              <Button
                style={{ backgroundColor: "#1890FF" }}
                className={"btnSubmit"}
                startIcon={
                  <CheckCircleOutlineOutlined style={{ color: "white" }} />
                }
              >
                <span style={{ color: "white" }} className={"spn_add"}>
                  {t("Videoplayer.validate")}
                </span>
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={"Breadcrumb"}>
        <Breadcrumbs>
          <Link underline="none" color="inherit" href="">
            <span>
              <HomeOutlined className={"home_icon"} />
            </span>
          </Link>
          <Link underline="none" color="inherit" href="">
            {t("Videoplayer.configuration")}{" "}
          </Link>
          <Link
            underline="none"
            color="inherit"
            href=""
            onClick={onResetVideoplayer}
          >
            {t("Videoplayer.videoplayer")}{" "}
          </Link>
          {showDivCondition.addVideoplayer ? (
            <Link underline="none" color="inherit" href="">
              {t("Videoplayer.addvideoplayer")}{" "}
            </Link>
          ) : showDivCondition.editVideoplayer ? (
            <Link underline="none" color="inherit" href="">
              {t("Videoplayer.modifvideoplayer")}{" "}
            </Link>
          ) : (
            ""
          )}
        </Breadcrumbs>
      </div>
    </div>
  );
};

export default VideoplayerHeader;
