import { Card, TextField } from "@mui/material";
import "../../videoplayer.scss";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
const AddEditVideoplayer = () => {
  const { t } = useTranslation();

  const { values } = Hooks();

  return (
    <div className={"divCard"}>
      <Card>
        <div className={"formItem1"}>
          <div className={"InputLablDiv"}>
            <TextField
              required
              name="content"
              label={t("Videoplayer.type")}
              placeholder={"type"}
              className="textField"
              defaultValue={values.type}
              multiline
            />
          </div>

          <div className={"InputLablDiv"}>
            <TextField
              required
              name="content"
              label={t("Videoplayer.value")}
              placeholder={"value"}
              className="textField"
              defaultValue={values.value}
              multiline
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
export default AddEditVideoplayer;
