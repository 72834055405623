
import { useTranslation } from "react-i18next";

export const MetadonnesData = () => {

    const { t } = useTranslation();

    const columns = [
        {
            headerName: <label className="titlecolumn">{t('metadonnes.type')}</label>,
            field: 'type',
           
            sortDirections: ['descend', 'ascend', 'descend',],
            className:"contentcolumn",
            width: 700,
           
        },
       
        {
            headerName: <label className="titlecolumn">{t('metadonnes.title')}</label>,
            field: 'receptionDate',
           
            sortDirections: ['descend', 'ascend', 'descend',],
            className:"contentcolumn",
            width: 700,
            
        },

    ];
    
 
   
    return ({
        columns
    })

}
