
import {Tooltip , Button} from '@mui/material';
import {DeleteOutlineOutlined} from '@mui/icons-material';
import {useAppSelector} from "../../../../Redux/hooks";
import {setFilterReferentActions} from "../../store/referentActions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import Hooks from "../../utils/hooks";
import '../../referent.scss';
import {
    FormControl,
    InputAdornment,
    TextField,
  } from "@material-ui/core";
  import SearchIcon from "@material-ui/icons/Search";

const ReferentTable = () =>{

    const dispatch = useDispatch()
    const {t} = useTranslation();
    const delet = t('Referent.delete')
    const valuePagination = useAppSelector((state) => state.ReferentReducer.paginationPropsReferent)
    const {handleSearchRow , Deletemultipleselecteditem} = Hooks()

    return(
        <div >

            <div className="Filter">

                {
                    valuePagination.id.length === 0
                        ?
                        null
                        :
                        <div className="delete_number">
                            <Tooltip title={delet}>
                                <Button className="btnDelet" startIcon={<DeleteOutlineOutlined/>} onClick={() =>Deletemultipleselecteditem()}/>
                            </Tooltip>
                            <p><span>{valuePagination.id.length || ""}</span> <span id={"text_selection"}>{t('Referent.selectedItem')}</span></p>
                        </div>

                }

                <div className={"div_rect"}>
                   
                    <div className="rect_filter">
                    <FormControl className="search" style={{width : "96.5%" , marginLeft : "1%" }}>
                        <TextField
                            variant="outlined"
                            placeholder={t('Referent.search')}
                            InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                   <SearchIcon style={{color: "grey", marginLeft: "10px" ,}} />
                                  </InputAdornment>
                                ),
                              }}
                            name={"search"}
                            onKeyDown={(e)=>handleSearchRow(e)}  
                            onChange={(e) =>
                                dispatch(setFilterReferentActions({
                                    FilterReferentNameChange: "searchFake",
                                    FilterReferentValueChange: e.target.value
                                }))
                            }
                        />
                      </FormControl>
                    </div>
                </div>
                
            </div>



        </div>
    )
}

export default ReferentTable