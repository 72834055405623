import {combineReducers} from 'redux'
import {PresetReducer} from "../MenuConfiguration/preset/store/presetReducer";
import {ServeurReducer} from "../MenuConfiguration/serveur/store/serveurReducer";
import {ClientReducer} from "../MenuConfiguration/client/store/clientReducer";
import {VideoplayerReducer} from "../MenuConfiguration/videoplayer/store/videoplayerReducer";
import {ReferentReducer} from "../MenuConfiguration/referent/store/referentReducer";
import {VideoshomeReducer} from "../MenuConfiguration/homepage/videoshome/store/videoshomeReducer";
import {ConfigurationReducer} from "../MenuConfiguration/homepage/configuration/store/configurationReducer";
import { MetadonnesReducer } from '../MenuConfiguration/Metadonnes/store/metadonnesReducer';
import { ActualityReducer } from "../MenuConfiguration/Actuality/store/ActualityReducer";
import { MoocReducer } from "../MenuConfiguration/Mooc/store/MoocReducer";
import ReducerUserData from '../userData/reducer'
import { videopushReducer } from '../MenuConfiguration/homepage/videopush/store/videopushReducer';
import { skinReducer } from '../MenuConfiguration/SkinPlayer/store/skinReducer';

export const rootReducer = combineReducers({
    ReducerUserData,
    PresetReducer,
    ServeurReducer,
    ClientReducer,
    VideoplayerReducer,
    ReferentReducer,
    ConfigurationReducer,
    VideoshomeReducer,
    MetadonnesReducer,
    ActualityReducer,
    MoocReducer,
    videopushReducer,
    skinReducer
})
