import { useEffect, useState } from "react";
import {
  Card,
  Box,
  Tab,
} from "@mui/material";
import "../../serveur.scss";
import { useTranslation } from "react-i18next";
import Hooks from "../../utils/hooks";
import { DataServeur } from "../../utils/Data";
import { useAppSelector } from "../../../../Redux/hooks";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useDispatch } from "react-redux";
import { setAddServeurActions } from "../../store/serveurActions";
import { Checkbox } from '@empreinte/components.checkbox';
import { SelectList } from '@empreinte/components.select-list';
import { InputText } from '@empreinte/components.input-text';
import { Divider } from '@mui/material';
import "../../serveur.scss";

const AddEditServeur = () => {
  const { t } = useTranslation();
  const { values, disabledsubmitbutton } = Hooks();
  const Servercondition = useAppSelector(
    (state) => state.ServeurReducer.showDivsConditionsServeur
  );
  const { ListserverType, Listhttp } = DataServeur();
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);
  const types = useAppSelector((state) => state.ServeurReducer.Types)
  const validInputsToAddServer = () => {
    let isValidToSubmit = true
    Object.values(values).forEach(value=>{if(value === "" || value === 0){isValidToSubmit=false}})

    if (!isValidToSubmit) {
      disabledsubmitbutton("notdisabled");
    } else {
      disabledsubmitbutton("disabled");
    }
  };
  useEffect(() => {
    validInputsToAddServer();
  }, [values]);

  const onchangeForm = (e: any) => {
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: e.target.name,
        addServeurConstraintsDataValueChange: e.target.value,
      })
    );
  };
  const onchangeFormChecks = (e: any) => {
    dispatch(
      setAddServeurActions({
        addServeurConstraintsDataNameChange: e.target.name,
        addServeurConstraintsDataValueChange: e.target.checked,
      })
    );
  };

  const handlePortChange = (e:any) => {
    const inputValue = e.target.value;
    const numericValue = parseInt(inputValue, 10);
    if (numericValue >= 0 || inputValue === '') {
      onchangeForm(e);
    }
  }

  const serverTypesSet = new Set();
  const uniqueServerTypes = types.filter((edge: any) => {
    if (!serverTypesSet.has(edge.node.Type)) {
      serverTypesSet.add(edge.node.Type);
      return true;
    }
    return false;
  });
  
  const ServerTypes = uniqueServerTypes.map((edge: any) => ({
    label: edge.node.Type,
    value: edge.node.Type
  }));
  const contentgeneral = (
    <>
      <div className="content-general-container">
          <SelectList 
            required
            name="Type"
            className="select-list-server"
            labelText={t("Serveur.type")}
            value={values?.Type}
            handleChange={onchangeForm}
            data={ServerTypes}
          />
          <SelectList 
            required
            name="ServerType"
            id="demo-simple-select-disabled-label"
            className="select-list-server"
            labelText={t("Serveur.serverType")}
            value={values?.ServerType}
            handleChange={onchangeForm}
            data={ListserverType}
          />
          <InputText 
            className="input-border"
            required
            fullWidth
            id="title"
            name="ServerName"
            labelInput={t("Serveur.serverName")}
            placeholder={t("Serveur.serverName")}
            value={values?.ServerName}
            handleChange={onchangeForm}
          />
        <SelectList 
            name="Protocol"
            id="demo-simple-select-disabled-label"
            className="select-list-server"
            labelText={t("Serveur.protocol")}
            value={values?.Protocol}
            handleChange={onchangeForm}
            data={Listhttp}
            required
          />
        <div style={{ display: "flex", justifyContent: "column" }}>
          <Checkbox 
            name="Internet"
            label="Internet"
            handleChange={onchangeFormChecks}
            checked={values?.Internet}
          />

          <Checkbox 
            name="Record"
            label="Record"
            handleChange={onchangeFormChecks}
            checked={values?.Record}
          />
        </div>
        <InputText 
          className="input-border"
          required
          fullWidth
          name="Name"
          labelInput={t("Serveur.name")}
          placeholder={t("Serveur.name")}
          value={values?.Name}
          handleChange={onchangeForm}
        />
        <InputText 
        className="input-border"
          required
          fullWidth
          id="Login"
          name="Login"
          labelInput={t("Serveur.identifiant")}
          placeholder={t("Serveur.identifiant")}
          value={values?.Login}
          handleChange={onchangeForm}
        />
        <InputText 
        className="input-border"
          required
          fullWidth
          id="Login"
          name="Pwd"
          labelInput={t("Serveur.login")}
          placeholder={t("Serveur.login")}
          value={values?.Pwd}
          handleChange={onchangeForm}
        />
        <InputText 
        className="input-border"
          required
          fullWidth
          id="Dir"
          name="Dir"
          labelInput={t("Serveur.dir")}
          placeholder={t("Serveur.dir")}
          value={values?.Dir}
          handleChange={onchangeForm}
        />
    </div>
    </>
    
  );
  const contentconfiguration = (
    <>
      <div className="content-configuration-container">
        <Card className={"card1"} style={{ width: "50%", boxShadow: "none" }}>
        <InputText 
        className="input-border"
          style={{marginBottom: "15px"}}
          fullWidth
          name="UrlVideo"
          type="url"
          labelInput={t("Serveur.url")}
          placeholder={t("Serveur.url")}
          value={values?.UrlVideo}
          handleChange={onchangeForm}
          required
        />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="UrlMp4"
            type="url"
            labelInput={t("Serveur.urlMp4")}
            placeholder={t("Serveur.urlMp4")}
            value={values?.UrlMp4}
            handleChange={onchangeForm}
            required
          />
          <InputText
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="UrlDiffusion"
            labelInput={t("Serveur.urlstreaming")}
            placeholder={t("Serveur.urlstreaming")}
            value={values?.UrlDiffusion}
            type="url"
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="UrlVodAndroid"
            labelInput={t("Serveur.urlvideoandroid")}
            placeholder={t("Serveur.urlvideoandroid")}
            value={values?.UrlVodAndroid}
            handleChange={onchangeForm}
            type="url"
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="UrlLiveAndroid"
            labelInput={t("Serveur.urldirectandroid")}
            placeholder={t("Serveur.urldirectandroid")}
            value={values?.UrlLiveAndroid}
            handleChange={onchangeForm}
            type="url"
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="UrlCdnAndroid"
            type="url"
            labelInput={t("Serveur.urlcdnandroid")}
            placeholder={t("Serveur.urlcdnandroid")}
            value={values?.UrlCdnAndroid}
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="UrlVodIos"
            type="url"
            labelInput={t("Serveur.urliosvideo")}
            placeholder={t("Serveur.urliosvideo")}
            value={values?.UrlVodIos}
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="UrlLiveIos"
            type="url"
            labelInput={t("Serveur.urliosdirect")}
            placeholder={t("Serveur.urliosdirect")}
            value={values?.UrlLiveIos}
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="UrlCdnIos"
            type="url"
            labelInput={t("Serveur.urlioscdn")}
            placeholder={t("Serveur.urlioscdn")}
            value={values?.UrlCdnIos}
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="DirLecture"
            labelInput={t("Serveur.playbackdirectory")}
            placeholder={t("Serveur.playbackdirectory")}
            value={values?.DirLecture}
            handleChange={onchangeForm}
            required
          />
        </Card>{" "}
        <Divider orientation='vertical' textAlign='center' flexItem={true} light={true} variant='fullWidth' />
        <Card className={"card2"} style={{ width: "50%", boxShadow: "none" }}>
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="UrlBackup"
            type="url"
            labelInput={t("Serveur.urlrestore")}
            placeholder={t("Serveur.urlrestore")}
            value={values?.UrlBackup}
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="UrlMobile"
            type="url"
            labelInput={t("Serveur.urlmobile")}
            placeholder={t("Serveur.urlmobile")}
            value={values?.UrlMobile}
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="UrlServerFtp"
            type="url"
            labelInput={t("Serveur.urlftpserver")}
            placeholder={t("Serveur.urlftpserver")}
            value={values?.UrlServerFtp}
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="LoginLive"
            labelInput={t("Serveur.loginlive")}
            placeholder={t("Serveur.loginlive")}
            value={values?.LoginLive}
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="PwdLive"
            labelInput={t("Serveur.passwordlive")}
            placeholder={t("Serveur.passwordlive")}
            value={values?.PwdLive}
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="Bitrate"
            labelInput={t("Serveur.bitrate")}
            placeholder={t("Serveur.bitrate")}
            value={values?.Bitrate}
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="Port"
            labelInput={t("Serveur.port")}
            placeholder={t("Serveur.port")}
            type="number"
            value={values?.Port}
            handleChange={handlePortChange}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="SshKey"
            labelInput={t("Serveur.sshkey")}
            placeholder={t("Serveur.sshkey")}
            value={values?.SshKey}
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="StreamPlaylistLive"
            labelInput={t("Serveur.streamplaylistlive")}
            placeholder={t("Serveur.streamplaylistlive")}
            value={values?.StreamPlaylistLive}
            handleChange={onchangeForm}
            required
          />
          <InputText 
          className="input-border"
            style={{marginBottom: "15px"}}
            fullWidth
            name="Notes"
            labelInput={t("Serveur.notes")}
            placeholder={t("Serveur.notes")}
            value={values?.Notes}
            handleChange={onchangeForm}
            required
          />
        </Card>
      </div>
      
    </>
  );
  const [value, setValue] = useState("1");
  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box>
        <TabContext value={value}>
          <Box
            sx={{

              marginTop: "10px",
              marginLeft: "2%",
            }}
          >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t("Serveur.general")} value="1" />
              <Tab label={t("Serveur.configuration")} value="2" />
            </TabList>
          </Box>
          <div className="containe-config-server">
              <div className="edit-containe-config-server">
                <TabPanel value="1">{contentgeneral}</TabPanel>
                <TabPanel value="2">{contentconfiguration}</TabPanel>
              </div>
          </div>
        </TabContext>
      </Box>
    </div>
  );
};
export default AddEditServeur;
