import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useAppSelector } from "../../../../Redux/hooks";
import UseActionMenu from "./actionUserTable";
import moment from "moment";
import "moment/locale/zh-cn";
import Hooks from "../../utils/hooks";
moment.locale("en");

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_NUMBER = 0;

function useDataTableMedia({ columns, dataSource, updateEntityPath }: any) {
  const { handelSearch } = Hooks();
  const [selectedRow] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<any>(DEFAULT_PAGE_NUMBER);
  const [pageSize] = useState<any>(DEFAULT_PAGE_SIZE);

  const values = useAppSelector(
    (state) => state.VideoplayerReducer.filterVideoplayer
  );

  const updatedColumns = [
    ...columns,
    {
      headerName: "Actions",
      key: "action",
      renderCell: (record: any, action: any) => {
        return <UseActionMenu record={record} />;
      },
      width: 172,
    },
  ];
  const filteredevents = handelSearch(values.searchFake, dataSource);
  useEffect(() => {});

  const resetPagination = () => {
    setCurrentPage(DEFAULT_PAGE_NUMBER);
  };

  const DataTable = () => (
    <div className="DataTable">
      <div
        style={{
          height: 700,
          width: "98%",
          marginTop: "1%",
          border: "none",
          marginLeft: "1%",
        }}
      >
        <DataGrid
          rows={dataSource && filteredevents}
          columns={updatedColumns}
          checkboxSelection
        />
      </div>
    </div>
  );

  return {
    DataTable,
    selectedRow,
    currentPage,
    pageSize,
    resetPagination,
  };
}

export default useDataTableMedia;
