

export function setPaginationPropsActuality(e:any){
    return{
        type:"SET_PaginationPropsActuality",
        payload:e,
    }
}

export function setshowDivsConditionsActuality(e:any){
    return {
        type : "SET_showDivsConditionsActuality",
        payload:e
    }
}

export function setAddActualityActions(e:any){
    return{
        type:"SET_AddActuality",
        payload:e,
    }
}

export function setFilterActualityActions(e:any){

    return{
        type:"SET_FilterActuality",
        payload:e,
    }

}