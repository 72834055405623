import React, { useEffect } from "react";
import moment from "moment";

import ActualityTable from "./components/List/ActualityTable";
import AddEditActuality from "./components/AddEdit/addEdit";
import ActualityHeader from "./components/ActualityHeader";
import { useAppSelector } from "../../Redux/hooks";
import { Hooks } from "./utils/hooks";
import { Grid } from "@material-ui/core";
import "./Actuality.scss";

const Actuality = () => {
  const {
    formAddActuality,
    onFinishAddActuality,
    onFinishFailedAddActuality,
    onValueFormUserchangeActuality,
    values,
  } = Hooks();
  const showDivCondition = useAppSelector(
    (state) => state.ActualityReducer.showDivsConditionsActuality
  );
  useEffect(() => {
    if (showDivCondition.addActuality) {
      formAddActuality.setFieldsValue({
        content: values.content,
        date: moment(values.date, "DD/MM/YYYY"),
      });
    }
  }, [showDivCondition.addActuality]);
  return (
    <div className={"divGlobalTable"}>
      {showDivCondition.addActuality ? (
        <div>
          <Grid
            onSubmit={onFinishAddActuality}
            onChange={onValueFormUserchangeActuality}
          >
            <ActualityHeader />
            <AddEditActuality />
          </Grid>
        </div>
      ) : (
        <div>
          <ActualityHeader />
          <ActualityTable />
        </div>
      )}
    </div>
  );
};

export default Actuality;
