import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../Redux/hooks";
import { setErrorrMessageConfigurationActions } from "../store/configurationActions"
import { useDispatch } from "react-redux";
const DisplayAlert = (props: any) => {
    const dispatch = useDispatch()
    const [showalert, setShowalert] = useState(false)
    const errormessage = useAppSelector((state) => state.ConfigurationReducer.ErrorMessage)

    useEffect(() => {
        if (errormessage !== "") {
            setShowalert(true)
        }
        setTimeout(function () {
            setShowalert(false)
            dispatch(setErrorrMessageConfigurationActions(""));

        }, 2000);
    }, [errormessage]);
    return (
        <div>
           
        </div>
    )
}

export default DisplayAlert
