import { PresetList } from "./preset";
const {
  showDivsConditionsPreset,
  AddPresetConstraintsData,
  paginationPropsPreset,
  FilterPreset,
  PresetTypes,
} = PresetList();

const PresetListINITIALSTATE = {
  showDivsConditionsPreset: showDivsConditionsPreset(),
  paginationPropsPreset: paginationPropsPreset(),
  addPresetConstraintsData: AddPresetConstraintsData(),
  filterPreset: FilterPreset(),
  List: [],
  loading: false,
  msg: "",
  error: false,
  preset: {},
};

export const PresetReducer = (state = PresetListINITIALSTATE, action: any) => {
  switch (action.type) {
    case "SET_FilterPreset":
      const { FilterPresetNameChange, FilterPresetValueChange } =
        action.payload;
      return {
        ...state,
        filterPreset: {
          ...state.filterPreset,
          [FilterPresetNameChange]: FilterPresetValueChange,
        },
      };
      case "SET_Restar":
        return {
          ...state,
          showDivsConditionsPreset: {
            ...state.showDivsConditionsPreset,
              "action":!state.showDivsConditionsPreset.action,
          },
        };
    case "SET_PaginationPropsPreset":
      const { PaginationPropsNameChange, PaginationPropsValueChange } =
        action.payload;
      return {
        ...state,
        paginationPropsPreset: {
          ...state.paginationPropsPreset,
          [PaginationPropsNameChange]: PaginationPropsValueChange,
        },
      };
    case "SET_showDivsConditionsPreset":
      const { showDivsConditionsName, showDivsConditionsValue } =
        action.payload;
      const showDivsConditionsObj = {
        ...state.showDivsConditionsPreset,
        [showDivsConditionsName]: showDivsConditionsValue,
      };
      return {
        ...state,
        showDivsConditionsPreset: showDivsConditionsObj,
      };
    case "SET_AddPresetActions":
      const {
        addPresetConstraintsDataNameChange,
        addPresetConstraintsDataValueChange,
      } = action.payload;
      return {
        ...state,
        addPresetConstraintsData: {
          ...state.addPresetConstraintsData,
          [addPresetConstraintsDataNameChange]:
            addPresetConstraintsDataValueChange,
        },
      };
    case "SET_DetailServeurActions":
      return {
        ...state,
        preset: action.payload,
      };
    case PresetTypes.LOADING:
      return {
        ...state,
        loading: true,
      };
    case PresetTypes.SET_PRESET_DATA:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    default: {
      return state;
    }
  }
};
