import React, { useEffect,useRef,useState,useCallback } from "react";
import { useAppSelector } from "../../../Redux/hooks";
import { GraphQLFetchData } from "../utils/graghFetchData";
// import { setTypeSkin } from "../store/skinActions";
const PlayerCard = () => {
	const containerRef = useRef(null);
	const {GetLatestVideo} = GraphQLFetchData();
	const linkUrl = useAppSelector((state)=>state.skinReducer.link)
	const defaultSkin = useAppSelector((state)=>state.skinReducer.selectedSkinObject)
	const skinId = useAppSelector((state)=>state.skinReducer.skinId)
	const iframeRef = useRef(null)
	const editMode = useAppSelector((state) => state.skinReducer.editMode)
	const addMode = useAppSelector((state)=>state.skinReducer.addMode)
	const addSkin = useAppSelector((state)=>state.skinReducer.addSkin)
    const [iframeLoaded, setIframeLoaded] = useState(false);
	const showSkinCondition = useAppSelector((state)=>state.skinReducer.AddSkinContraints)
	const skinType = useAppSelector((state)=>state.skinReducer.skinType)
	const resetSkinPage = useAppSelector((state)=>state.skinReducer.resetSkinPage)

	// useEffect(()=>{
	// 	if(skinType ==="skin"){
	// 		setTimeout(() => {
	// 			postSkinMessage();
	// 			dispatch(setTypeSkin(false))
	// 		}, 1000);
	// 	}
		
	// 	},[skinType])
	useEffect(()=>{
		GetLatestVideo()
	},[])
	
	function handleIframeLoad() {
		let iframe = document.querySelector("#tesSkin");
		setIframeLoaded(true);
		iframe?.contentWindow?.postMessage({ info: 'skin', value: iframeLoaded }, '*');

	}
	
	const postSkinMessage = useCallback(() => {
		let iframe = document.querySelector("#tesSkin");
		iframe?.contentWindow?.postMessage({ info: 'SkinMessage', value: showSkinCondition.isAdd  ? addSkin: defaultSkin }, '*');
	}, [defaultSkin]);

	useEffect(() => {
		postSkinMessage();
}, [editMode, defaultSkin]);

	return (
		<div className="player-Card" ref={containerRef}>
						<iframe id='tesSkin'
						ref={iframeRef}
						title={linkUrl}
						width={'100%'}
						height={'100%'}
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen={true}
						src={`https:${linkUrl}`}
						// src='http://localhost:8080/player/l4sK8eMRoN3y67SJrLKz48xM2Svuvq9SHjWEj-xZExo?plugin=navigation'
						onLoad={handleIframeLoad}

						></iframe>
			
				</div>
	);
};

export default PlayerCard;
