import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { Form } from "antd";

import { useAppSelector } from "../../../Redux/hooks";
import {
  setshowDivsConditionsActuality,
  setFilterActualityActions,
  setAddActualityActions,
} from "../store/ActualityActions";

export const Hooks = () => {
  const dispatch = useDispatch();
  const [formAddActuality] = Form.useForm();

  // use Selector redux
  const values = useAppSelector(
    (state) => state.ActualityReducer.addActualityConstraintsData
  );
  const valuesSearch = useAppSelector(
    (state) => state.ActualityReducer.filterActuality
  );

  useEffect(() => {
    formAddActuality.setFieldsValue({
      content: values.content,
      date: values.date ? values.date :""
    });
  }, []);

  //************* Filter **********///
  const handelSearch = (word: string, tab: any) => {
    if (word.trim() === "") return tab;
    const res = tab.filter((obj: any) => {
      if (obj.Actualite.toLowerCase().includes(word.toLowerCase())) return obj;
    });
    return res;
  };

  ///////***************** Form functions *************////////
  const handleAddActuality = () => {
    dispatch(
      setshowDivsConditionsActuality({
        showDivsConditionsName: "addActuality",
        showDivsConditionsValue: true,
      })
    );
  };

  const onResetActuality = async () => {
    dispatch(
      setshowDivsConditionsActuality({
        showDivsConditionsName: "addActuality",
        showDivsConditionsValue: false,
      })
    );
    await dispatch(
      setAddActualityActions({
        addActualityConstraintsDataNameChange: "content",
        addActualityConstraintsDataValueChange: "",
      })
    );
    await dispatch(
      setAddActualityActions({
        addActualityConstraintsDataNameChange: "date",
        addActualityConstraintsDataValueChange: "",
      })
    );
  };
  const onFinishAddActuality = (val: any) => {
    const {content,date=values.date}=val
  };
  const onFinishFailedAddActuality = (errorInfo: any) => {
  };
  const onValueFormUserchangeActuality = async (val: any) => {
    if (val.content) {
      await dispatch(
        setAddActualityActions({
          addActualityConstraintsDataNameChange: "content",
          addActualityConstraintsDataValueChange: val.content,
        })
      );
    } else if (val.date) {
      await dispatch(
        setAddActualityActions({
          addActualityConstraintsDataNameChange: "date",
          addActualityConstraintsDataValueChange: val.date,
        })
      );
    } 
  };

  ////////************** Edit Actuality functions *****************/////////
  const handleEditActuality = async(record: any) => {
    if(record){
        await dispatch(
          setAddActualityActions({
            addActualityConstraintsDataNameChange: "content",
            addActualityConstraintsDataValueChange: record.content,
          })
        );
        await dispatch(
          setAddActualityActions({
            addActualityConstraintsDataNameChange: "date",
            addActualityConstraintsDataValueChange: record.date,
          })
        );
      } 
    
    dispatch(
      setshowDivsConditionsActuality({
        showDivsConditionsName: "addActuality",
        showDivsConditionsValue: true,
        record:record.id
      })
    );
  };
  ///**************** show modal export functions *************/////


  const handleSearchRow = async (e: any) => {
      if(e.key==="cliecked"){
        await dispatch(
            setFilterActualityActions({
              FilterActualityNameChange: "search",
              FilterActualityValueChange: e.value,
            })
          );
      }else if (e.key === "Enter") {
      await dispatch(
        setFilterActualityActions({
          FilterActualityNameChange: "search",
          FilterActualityValueChange: e.target.value,
        })
      );
    }
  };

  return {
    handleAddActuality,
    onResetActuality,
    formAddActuality,
    onFinishAddActuality,
    onFinishFailedAddActuality,
    onValueFormUserchangeActuality,
    handleEditActuality,
    values,
    handleSearchRow,
    valuesSearch,
    handelSearch,
  };
};
