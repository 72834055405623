import useDataTableMedia from "./DataTable";
import { useTranslation } from "react-i18next";
import { MetadonnesData } from "../../utils/metadonneData";
import { FormControl, InputAdornment, TextField } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const MetadonnesTable = () => {
  const { t } = useTranslation();

  const { columns } = MetadonnesData();
  const { DataTable } = useDataTableMedia({
    columns: columns,
    dataSource: "",
    updateEntityPath: "update-product",
  });

  return (
    <div>
      <div className="Filter">
        <div className={"div_rect"}>
          <div className="rect_filter">
            <FormControl
              className="search"
              style={{ width: "96.5%", marginLeft: "1%" }}
            >
              <TextField
                variant="outlined"
                placeholder={t("Profile.search")}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        style={{ color: "grey", marginLeft: "10px" }}
                      />
                    </InputAdornment>
                  ),
                }}
                name={"search"}
              />
            </FormControl>
          </div>
        </div>
      </div>
      <div className={"dataTable"}>
        <DataTable />
      </div>
    </div>
  );
};

export default MetadonnesTable;
